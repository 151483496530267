import React, {useEffect, useState} from 'react'
import {Button, Descriptions, Form, message} from "antd";
import styled from "styled-components";
import {onConfirm} from "../../Helper/Helper";
import {useStore} from "../../Hooks";
import {REPO} from "../../Repository/repo";
import dayjs from "dayjs";
import {useQuery} from "../../Hooks/useQuery";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import {
    MonthlySalesTable,
    MonthlyManTable, MonthlyCostTable,
    MonthlyPurchaseTable,
    MonthlySumTable
} from "./MonthlyTables";
import {ResponseMonthlyModel} from "../../Model/MonthlyModel";
import MonthSelect from "../../Components/MonthSelect/MonthSelect";
import YearSelect from "../../Components/YearSelect/YearSelect";
import useUser from "../../Hooks/useUser";
import {theme} from "../../styles/GlobalStyle";

dayjs.extend(weekday)
dayjs.extend(localeData)

const { Item } = Descriptions

const Wrap = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-table-row:nth-child(2n){
    background-color: ${theme.backgroundLightGray};
  }
`


const Title = styled.h3`
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

const BottomWrap = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  label{
    :first-child{
      margin-left: 0;
    }
    margin: 0 10px;
  }
`

const MonthlyDetail:React.FC<{id?: number | string | null, date?: string}> = ({id, date}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<ResponseMonthlyModel>()
    const [selectDate, setSelectDate] = useState(dayjs())
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const { userData } = useUser()
    const [env, setEnv] = useState({});
    const [modify, setModify] = useState(true);
    const query = useQuery()

    const { UIStore } = useStore()

    const [form] = Form.useForm()

    const fetchMethod = async () => {
        if(!date) { return }
        if(!id) { return }

        try {
            setIsLoading(true)
            const result = await REPO.Closing.GetClosingDetail(UIStore.getTarget(), date, String(id) || "")
            setData(result.data.closing)
            setModify(false)
            setIsLoading(false)
        } catch (e) {

        }
    }

    useEffect(() => {
        if(id){
            fetchMethod()
        }
        setYear(`${selectDate.format("YYYY")}`)
        setMonth(`${selectDate.format("MM")}`)
    },[])

    useEffect(() => {
        setSelectDate(dayjs(`${year}-${month}-01`))
    },[month, year])

    const createAction = async () => {
        const result = await REPO.Closing.CreateClosing(UIStore.getTarget(), `${year}-${month}-01`, UIStore.branch?.seq)
        setData({
            ...result.data.closing,
            closing_month: result.data.date
        })
        setEnv(result.data.system_env)
    }

    const onItemRemove = (target: keyof ResponseMonthlyModel, key: number) => {
        if(!data){ return }
        const newValue = data[target].filter((e:any, i:number) => i !== key)
        setData({
            ...data,
            [target]: newValue
        })
    }

    const onInputChange = (target: keyof ResponseMonthlyModel, targetValue: string, value: any, key: number) => {
        if(!data){ return }
        const newValue = [...data[target]].map((info, k) => {
            if(k === key){
                info[targetValue] = value
                info.error = false
                return info
            }
            return info
        })
        setData({
            ...data,
            [target]: newValue
        })
    }

    const onSubmitAction = () => {
        if(!data){
            return
        }
        let error = false
        let error_message = ""

        const sale = data.sale?.map((info, key) => {
            if(error){ return info }

            if(!info.item_name){
                error = true
                error_message = "매출내역 항목을 입력해주세요"
                info.error = 0
                // areaRef1.current?.scrollIntoView()
            } else if(info.item_price !== 0 && !info.item_price){
                error = true
                error_message = "매출내역 금액을 입력해주세요"
                info.error = 3
                // areaRef1.current?.scrollIntoView()
            } else if(info.item_vat !== 0 && !info.item_vat){
                error = true
                error_message = "매출내역 VAT를 입력해주세요"
                info.error = 4
                // areaRef1.current?.scrollIntoView()
            } else {
                info.error = false
            }

            return info
        })

        const purchase = data.purchase?.map((info, key) => {
            if(error){ return info }

            if(!info.item_name){
                error = true
                error_message = "매입내역 항목을 입력해주세요"
                info.error = 0
            } else if (info.item_price !== 0 && !info.item_price) {
                error = true
                error_message = "매입내역 금액을 확인해주세요"
                info.error = 2
            } else if(info.item_vat !== 0 && !info.item_vat){
                error = true
                error_message = "매입내역 VAT를 입력해주세요"
                info.error = 3
            } else {
                info.error = false
            }

            return info
        })

        const params = {
            ...data,
            sale: sale,
            purchase: purchase
        }

        setData(params)

        if (error) {
            return message.error(error_message)
        }

        onConfirm("확인", "결산자료를 등록하시겠습니까?", async () => {
            try {
                const result = await REPO.Closing.SaveClosing(UIStore.getTarget(), `${year}-${month}-01`, params, UIStore.branch?.seq)
                message.success("월별결산 작성이 완료되었습니다.")
                setData(undefined)
            } catch (e) {

            }
        })
    }

    const deleteAction = () => {
        if(!date){ return }
        onConfirm("확인", "결산 자료를 삭제하시겠습니까?", async ()=>{
            const result = await REPO.Closing.DeleteClosing(UIStore.getTarget(), date, String(id) || "")
            message.success("결산자료가 삭제되었습니다.")
            UIStore.closeMdPg()
        })
    }

    const addItem = (target: keyof Omit<ResponseMonthlyModel, 'pay'>, initValue?:any) => {
        if (! data) return
        const newValue = [...data[target]].concat(initValue ? initValue : {})
        setData({
            ...data,
            [target]: newValue
        })
    }



    return(
        <Wrap form={form} >
            <Descriptions column={2} bordered>
                <Item label={"지점"}>
                    {!modify? data?.group_name : UIStore.branch?.group_name}
                </Item>
                <Item label={"작성자"}>
                    {!modify? data?.employee_name : userData.data?.employee_name}
                </Item>
                <Item label={"결산월"}>
                    {data?.date}
                    {!data? <>
                        <YearSelect value={year} onChange={e => setYear(e)}/>
                        <MonthSelect value={month} onChange={e => setMonth(e)}/>
                    </> : dayjs(data?.closing_month ?? false).format("YYYY년 MM월")}
                </Item>
                <Item label={"기간"}>
                    {(modify && data) ? <>{data?.date_begin} ~ {data?.date_end}</> : null}
                    {/*{selectDate.startOf("month").format("YYYY/MM/DD")} ~ {selectDate.endOf("month").format("YYYY/MM/DD")}*/}
                </Item>
            </Descriptions>
            {!data && <BottomWrap>
                <div>
                </div>
                <div>
                    <Button type={"primary"} style={{marginRight: "5px"}} onClick={createAction}>결산자료 생성하기</Button>
                </div>
            </BottomWrap>}
            {data && <>
                <MonthlySumTable data={data} env={env} modify={modify}/>
                <TitleWrap>
                    <Title>1.매출내역</Title>
                </TitleWrap>
                <Form.Item>
                    <MonthlySalesTable data={data.sale || []} onChange={onInputChange} modify={modify} env={env}/>
                </Form.Item>
                <TitleWrap>
                    <Title>2.매입내역</Title>
                </TitleWrap>
                <MonthlyPurchaseTable data={data.purchase || []} onChange={onInputChange} modify={modify} env={env}/>
                <TitleWrap>
                    <Title>3.인건비</Title>
                    {modify && <Button onClick={() => addItem('pay', {item_overtime_pay: 0})}>추가</Button>}
                </TitleWrap>
                <MonthlyManTable data={data.pay || []} onChange={onInputChange} onItemRemove={onItemRemove} modify={modify} env={env}/>
                <TitleWrap>
                    <Title>4.경비</Title>
                </TitleWrap>
                <MonthlyCostTable data={data.expense || []} onChange={onInputChange} modify={modify} env={env}/>
                <BottomWrap>
                    <div>
                        {!modify && <Button type={"primary"} onClick={deleteAction} danger>삭제</Button>}
                    </div>
                    <div>
                        {modify && <Button type={"primary"} style={{marginRight: "5px"}} onClick={onSubmitAction}>저장</Button>}
                        <Button onClick={modify ? ()=>setData(undefined) : ()=>UIStore.closeMdPg()}>취소</Button>
                    </div>
                </BottomWrap>
            </>}
        </Wrap>
    )

}

export default MonthlyDetail