import React, {ReactNode, useEffect} from 'react'
import styled from "styled-components";
import {Button, DatePicker, Form, Input, Select, Space, Table} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {ListHeader, SearchRow, SearchWrap} from "../../Components";
import {mdPg, onConfirm, queryAdd, toLocale} from "../../Helper/Helper";
import {useStore} from "../../Hooks";
import {ListProps} from "../../Model/Common";
import BranchSelect from "../../Components/BranchSelect/BranchSelect";
import {useQuery} from "../../Hooks/useQuery";
import dayjs from "dayjs";
import {DocumentListModel} from "../../Model/DocumentModel";
import {DOCUMENT_ITEM_TYPE_ARRAY, LIST_CHANGE_ITEM} from "../../Constant/Common";

const ListWrap = styled.div`
  box-sizing: border-box;
`

type ListModel = ColumnsType<DocumentListModel>

interface Props extends ListProps {
    type: "detail" | "list"
    data?: DocumentListModel[]
    deleteHandler?: (id: number) => void
}
const DocumentList: React.FC<Props> = ({type, data, limit, setLimit, total, deleteHandler,  onChange}) => {
    const location = useLocation()
    const {UIStore} = useStore()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const query = useQuery()

    useEffect(()=>{
        const data: any = {}
        query.forEach((q, k) => data[k] = String(q))
        data.date_begin = data.date_begin ? dayjs(data.date_begin) : null
        data.date_end = data.date_end ? dayjs(data.date_end) : null
        form.setFieldsValue(data)
    },[location])

    const onSearch = (values: any) => {
        const params = values
        params.date_begin = params.date_begin ? params.date_begin.format("YYYY-MM-DD") : ""
        params.date_end = params.date_end ? params.date_end.format("YYYY-MM-DD") : ""
        params.offset = 0
        for (const [key, value] of Object.entries(params)) {
            query.set(key, value as string || "")
        }
        const newQuery = queryAdd(query)
        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }

    let columns: ListModel = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            render: (t) => (<Link to={{
                pathname: location.pathname,
                search: location.search + mdPg({
                    active: true,
                    content: "document",
                    id: t,
                }),
            }}>{t}</Link>)
        },
        {
            title: '지점',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: '유형',
            dataIndex: 'document_type',
            key: 'document_type',
        },
        {
            title: '픔목',
            dataIndex: 'item_type',
            key: 'item_type',
        },
        {
            title: '제목',
            dataIndex: 'document_subject',
            key: 'document_subject',
        },
        {
            title: '금액 (과세/면세/VAT)',
            dataIndex: 'item_price',
            key: 'item_price',
            render: (t,r) => {
                const priceList = []
                if (r.item_price) priceList.push("과세: " + Intl.NumberFormat().format(r.item_price))
                if (r.item_taxfree) priceList.push("면세: " + Intl.NumberFormat().format(r.item_taxfree))
                if (r.item_vat) priceList.push("VAT: " + Intl.NumberFormat().format(r.item_vat))
                return priceList.join(" / \n")
            }
        },
        {
            title: '합계',
            dataIndex: 'item_total',
            key: 'item_total',
            render: (t,r) => Intl.NumberFormat().format(r.item_price + r.item_taxfree + r.item_vat)
        },
        {
            title: '사용날짜',
            dataIndex: 'item_usedate',
            key: 'item_usedate'
        },
        {
            title: '기안자',
            dataIndex: 'employee_name',
            key: 'employee_name'
        },
        {
            title: '승인',
            dataIndex: 'document_approval',
            key: 'document_approval',
            render: (t,r) => {
                const persons = r.persons.map((item) => {
                    return item.person_type.replace("결재자", "") + " " + item.employee_name + ": " + (item.person_action ?? '없음')
                })
                return <>
                    <b>{ r.document_approval }</b>
                    <br />
                    <small>{ persons.map((row) => <>{row}<br /></>) }</small>
                </>
            }
        }

    ];

    return (
        <ListWrap>
            {type === "list" && <>
                <SearchWrap form={form} onFinish={onSearch}>
                    <SearchRow>
                        <Form.Item name={"seq"} label={"번호"}>
                            <Input style={{width: "100px"}}/>
                        </Form.Item>
                        <Form.Item name={"branch_seq"} label={"지점"}>
                            <BranchSelect/>
                        </Form.Item>
                        <Form.Item name={"document_approval"} initialValue={""} label={"승인"}>
                            <Select defaultValue={""}>
                                <Select.Option value={""}>전체</Select.Option>
                                <Select.Option value={"요청중"}>요청중</Select.Option>
                                <Select.Option value={"승인됨"}>승인됨</Select.Option>
                                <Select.Option value={"반려됨"}>반려됨</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={"document_type"} initialValue={""} label={"유형"}>
                            <Select defaultValue={""}>
                                <Select.Option value={""}>전체</Select.Option>
                                <Select.Option value={"송금신청서"}>송금신청서</Select.Option>
                                <Select.Option value={"경비지출"}>경비지출</Select.Option>
                                <Select.Option value={"기안서"}>기안서</Select.Option>
                                <Select.Option value={"공문"}>공문</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={"item_type"} initialValue={""} label={"품목"}>
                            <Select defaultValue={""}>
                                <Select.Option value={""}>전체</Select.Option>
                                {DOCUMENT_ITEM_TYPE_ARRAY.map((info, key) => (
                                    <Select.Option key={key} value={info}>{info}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name={"my"} initialValue={""} label={"내가"}>
                            <Select>
                                <Select.Option value={""}>전체</Select.Option>
                                <Select.Option value={"drafter"}>기안자</Select.Option>
                                <Select.Option value={"approval"}>결재자</Select.Option>
                                <Select.Option value={"referer"}>참조자</Select.Option>
                                <Select.Option value={"confirm"}>확인필요</Select.Option>
                            </Select>
                        </Form.Item>
                    </SearchRow>
                    <SearchRow>
                        <Form.Item name={"date_begin"} label={"사용(예정)일"}>
                            <DatePicker format={"YYYY-MM-DD"}/>
                        </Form.Item>
                        <label>~</label>
                        <Form.Item name={"date_end"}>
                            <DatePicker format={"YYYY-MM-DD"}/>
                        </Form.Item>
                        <Form.Item name={"search_type"} initialValue={"employee_name"}>
                            <Select defaultValue={""}>
                                <Select.Option value={""}>전체</Select.Option>
                                <Select.Option value={"document_subject"}>제목</Select.Option>
                                <Select.Option value={"employee_name"}>기안자</Select.Option>
                                <Select.Option value={"approval_name"}>결재자</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={"search_query"}>
                            <Input/>
                        </Form.Item>
                        <Button htmlType={"submit"} type={"primary"}>검색</Button>
                    </SearchRow>
                </SearchWrap>
            </>}
            <Table
                dataSource={data}
                columns={columns}
                pagination={{
                    pageSize: limit,
                    pageSizeOptions: LIST_CHANGE_ITEM,
                    showSizeChanger: true,
                    onShowSizeChange: (e) => setLimit && setLimit(e),
                    showTotal: (total) => `검색결과 ${toLocale(total)}건`,
                    total: total,
                }}
                onChange={onChange}
                onRow={(r) => {
                    return{
                        onClick: () => {
                            UIStore.onMdPg({
                                active: true,
                                content: "document",
                                id: String(r.seq)
                            })
                        }
                    }
                }}
            />
        </ListWrap>
    )
}

export default DocumentList