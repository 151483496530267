import React, {forwardRef, useEffect, useState} from "react";
import {GroupListModel} from "../../Model/BranchModel";
import {REPO} from "../../Repository/repo";
import {Select} from "antd";
import {phoneWithHyphen} from "../../Helper/Helper";
import useGroup from "../../Hooks/useGroup";

interface Props {
    onChange?: (value: number) => void,
    value?: number,
}



const GroupSelect = forwardRef<any, Props>( ({onChange, value}, ref) => {

    const { groupData } = useGroup()
    // const [groupList, setGroupList] = useState<GroupListModel[]>([])
    const [inputValue, setInputValue] = useState<number>()

    useEffect(()=>{
        setInputValue(value)
    },[value])

    const inputValueChange = (e: number) => {
        const value = Number(e)
        setInputValue(value)
        if(onChange){
            onChange(value)
        }
    }

    return(
        <Select ref={ref} onChange={inputValueChange} value={inputValue}  placeholder={"소속그룹을 선택해주세요"}>
            {groupData?.data?.list.map((item, key) => (
                <Select.Option key={key} value={item.seq}>{item.group_name}</Select.Option>
            ))}
        </Select>
    )
})

export default GroupSelect