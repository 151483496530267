import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, Modal, Row, Select, Table} from "antd";
import {SearchRow, SearchWrap} from "../../Components";
import {REPO} from "../../Repository/repo";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {useStore} from "../../Hooks";
import styled from "styled-components";
import {MessageListRequestModel} from "../../Model/MessageModel";

const FooterWrap = styled.div`
    text-align: right;
    width: 100%;
    bottom: 0;
    margin: auto 0 0 0px;
    button {
        margin: 0 5px;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    margin: auto 0 0 5px;

    button {
        margin: 0 5px;
    }
`

const StickyWrap = styled.div`
    position: sticky;
    top: 0px;
    z-index: 3;
`

const MultipleEmployeeSearch: React.FC<{
            active: boolean,
            close: ()=> void,
            onSelect: (employee: EmployeesListModel[])=>void,
            filter: any
        }> = ({active, close, onSelect, filter}) => {
    const [data, setData] = useState<EmployeesListModel[]>([]);
    const { UIStore } = useStore()
    const [form] = Form.useForm()
    const [selectedEmployees, setSelectedEmployees] = useState<EmployeesListModel[]>([]);


    const fetchMethod = async (values: any) => {
        try{
            const result = await REPO.Employee.GetEmployeeList(UIStore.getTarget(),{
                ...filter,
                offset: 30,
                limit: 100,
                search_query: values.search_query,
                search_type: values.search_type,
            })
            setData(result.data.list.map(info => ({...info, key: info.seq})))
        } catch (e) {

        }
    }

    const handleSelect = (employee: EmployeesListModel) => {
            const selected = selectedEmployees.filter(e => e.seq === employee.seq).length > 0;
            const updateSelect = selected
                ? selectedEmployees.filter(e => e.seq !== employee.seq)
                : [...selectedEmployees, employee];
            setSelectedEmployees(updateSelect);
    };

    const handleSelectAll = (checked: boolean) => {
        setSelectedEmployees(checked ? [...data] : []);
    };

    const submitSelected = () => {
        onSelect(selectedEmployees);
        close();
    };

    const handleClose = () => {
        close();
    };

    useEffect(() => {
        fetchMethod('')
    }, []);


    const columns = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
        },
        {
            title: '이름',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: '소속지점',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: '연락처',
            dataIndex: 'employee_phone',
            key: 'employee_phone',
        },
        {
            title: (
                <div>
                    <Checkbox
                        checked={data.length > 0 && selectedEmployees.length === data.length}
                        indeterminate={selectedEmployees.length > 0 && selectedEmployees.length < data.length}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                    /> 전체
                </div>
            ),
            dataIndex: 'seq',
            key: 'seq',
            render: (t:any,r:EmployeesListModel) =>
                <Checkbox
                    checked={selectedEmployees.filter(e => e.seq === r.seq).length > 0}
                    onChange={() => handleSelect(r)}
                />

        },
    ];

    return (
        <Modal
            open={active}
            onCancel={handleClose}
            title={"직원선택"}
            width={"740px"}
            bodyStyle={{height: '500px', overflowY: 'auto'}}
            footer={
                    <FooterWrap>
                        <Button onClick={submitSelected}>선택완료</Button>
                        <Button onClick={handleClose}>취소</Button>
                    </FooterWrap>
            }
        >
            <StickyWrap>
                <SearchWrap form={form} onFinish={fetchMethod}>
                    <SearchRow>
                        <SearchWrap.Item label={"검색유형"} name={"search_type"} initialValue={"employee_name"}>
                            <Select>
                                <Select.Option value={"employee_name"}>이름</Select.Option>
                                <Select.Option value={"employee_position"}>직책</Select.Option>
                                <Select.Option value={"branch_name"}>소속지점</Select.Option>
                            </Select>
                        </SearchWrap.Item>
                        <SearchWrap.Item label={"검색어"} name={"search_query"}>
                            <Input/>
                        </SearchWrap.Item>
                        <ButtonWrapper>
                            <Button type={"primary"} htmlType={"submit"}>조회</Button>
                            <Button onClick={handleClose}>취소</Button>
                        </ButtonWrapper>

                    </SearchRow>
                </SearchWrap>
            </StickyWrap>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </Modal>
    )
}

export default MultipleEmployeeSearch