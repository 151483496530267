import React, {ReactNode, useEffect, useState} from 'react'
import styled from "styled-components";
import {Button, DatePicker, Form, Input, Select, Table} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {GroupListModel} from "../../Model/BranchModel";
import {BranchInput, ListHeader, SearchRow, SearchWrap} from "../../Components";
import {mdPg, onConfirm, queryAdd, toLocale} from "../../Helper/Helper";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {useDepartment, useStore} from "../../Hooks";
import {ListProps} from "../../Model/Common";
import BranchSelect from "../../Components/BranchSelect/BranchSelect";
import {useQuery} from "../../Hooks/useQuery";
import dayjs from "dayjs";
import {DepartmentItemModel} from "../../Model/DepartmentModel";
import {LIST_CHANGE_ITEM} from "../../Constant/Common";

const ListWrap = styled.div`
  box-sizing: border-box;
`

type ListModel = ColumnsType<EmployeesListModel>

interface Props extends ListProps {
    data?: any[]
}
const MonthlyList: React.FC<Props> = ({ data, limit, setLimit, total, onChange, }) => {
    const location = useLocation()
    const {UIStore} = useStore()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const query = useQuery()
    const [target, setTarget] = useState<DepartmentItemModel>();

    const {departmentData} = useDepartment()

    useEffect(()=>{
        const data: any = {}
        query.forEach((q, k) => data[k] = String(q))
        data.date_begin = data.date_begin ? dayjs(data.date_begin) : null
        data.date_end = data.date_end ? dayjs((data.date_end)) : null

        if(data.target){
            let temp = departmentData.data?.find(info => info.seq === Number(data.target || UIStore.branch?.seq))
            setTarget(temp)
        }

        form.setFieldsValue(data)
    },[location])


    const onSearch = (values: any) => {
        const params = values
        params.target = target?.seq || ""
        params.date_begin = params.date_begin ? params.date_begin.format("YYYY-MM-DD") : null
        params.date_end = params.date_end ? params.date_end.format("YYYY-MM-DD") : null
        params.offset = 0

        for (const [key, value] of Object.entries(params)) {
            query.set(key, value as string || "")
        }
        const newQuery = queryAdd(query)
        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }

    let columns = [
        {
            title: '지점',
            dataIndex: 'group_name',
            key: 'group_name',
        },
        {
            title: '결산월',
            dataIndex: 'closing_month',
            key: 'closing_month',
            render: (t: string) => (<a>{t}</a>)
        },
        {
            title: '매출',
            dataIndex: 'closing_sale',
            key: 'closing_sale',
            render: (t: number) => toLocale(t)
        },
        {
            title: '매입',
            dataIndex: 'closing_purchase',
            key: 'closing_purchase',
            render: (t: number) => toLocale(t)
        },
        {
            title: '인건비',
            dataIndex: 'closing_pay',
            key: 'closing_pay',
            render: (t: number) => toLocale(t)
        },
        {
            title: '경비',
            dataIndex: 'closing_expense',
            key: 'closing_expense',
            render: (t: number) => toLocale(t)
        },
        {
            title: '관리비',
            dataIndex: 'closing_admin',
            key: 'closing_admin',
            render: (t: number) => toLocale(t)
        },
        {
            title: '총식수',
            dataIndex: 'closing_total_count',
            key: 'closing_total_count',
            render: (t: number) => toLocale(t)
        },
    ];

    return (
        <ListWrap>
            <SearchWrap form={form} onFinish={onSearch}>
                <SearchRow>
                    <BranchInput value={target?.gbcode || ""} onChange={e => setTarget(e)} data={departmentData.data} nullAble/>
                    <Form.Item name={"date_begin"} label={"기간"}>
                        <DatePicker format={"YYYY-MM-DD"}/>
                    </Form.Item>
                    <label>~</label>
                    <Form.Item name={"date_end"}>
                        <DatePicker format={"YYYY-MM-DD"}/>
                    </Form.Item>
                    <Button htmlType={"submit"} type={"primary"}>검색</Button>
                </SearchRow>
            </SearchWrap>
            <Table
                dataSource={data}
                columns={columns}
                pagination={{
                    pageSize: limit,
                    pageSizeOptions: LIST_CHANGE_ITEM,
                    showSizeChanger: true,
                    onShowSizeChange: (e) => setLimit && setLimit(e),
                    showTotal: (total) => `검색결과 ${toLocale(total)}건`,
                    total: total,
                }}
                onChange={onChange}
                onRow={(r)=>{
                    return {
                        onClick: ()=>{
                            UIStore.onMdPg({
                                active: true,
                                subdata: r.closing_month,
                                id: String(r.group_seq),
                                content: "monthly",
                            })
                        }
                    }
                }}
            />
        </ListWrap>
    )
}

export default MonthlyList