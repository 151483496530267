import React, {useEffect, useRef, useState} from 'react'
import {Form, Input, InputRef, Select} from "antd";
import {InputProps} from "antd";
import {onlyNumber, toLocale} from "../../Helper/Helper";

interface Props extends Omit<InputProps, "value" | "onChange" | "onInput"> {
    value?: any
    onChange?: (value:React.ChangeEvent<HTMLInputElement>) => void
    state?: boolean
}

const StateInput = React.forwardRef<any, Props>((props, ref) => {

    const inRef = useRef<InputRef>(null)

    useEffect(()=>{
        if(props.state){
            inRef.current?.focus()
        }
    },[props.state])

    return (
        <Input
            {...props}
            ref={ref ? ref : inRef}
        />
    )
})

export default StateInput;