import React from 'react'
import dayjs from "dayjs";
import {Button, Calendar, Col, DatePickerProps, Radio, Row, Select, Typography} from "antd";
import dayLocaleData from 'dayjs/plugin/localeData';
import styled, {css} from "styled-components";
import {CloseOutlined} from "@ant-design/icons";

// dayjs.extend(dayLocaleData);

const CalenderWrap = styled.div<{ noSelect?: boolean, activeSelect?:boolean }>`
  max-width: 300px;
  width: auto;
  .ant-picker-date-panel{
    margin-right: 0 !important;
  }
  
  ${({noSelect}) => noSelect && css`
    .ant-picker-cell-selected{
      .ant-picker-cell-inner{
        background: none !important;
        border: none !important;
        color: #000 !important;
      }
    }
  `}

  ${({activeSelect}) => activeSelect && css`
    .ant-picker-cell-selected{
      .ant-picker-cell-inner{
        background: none !important;
        border: none !important;
        background-color: #ffa94d !important;
        color: #fff
      }
    }
  `}
  
  
`

const FillColor = styled.div`
  position: absolute;
  background-color: #ffa94d;
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 3px;
  z-index: 10;
  color: #fff;
`

interface Props {
    now?: DatePickerProps['value'],
    activeArr?: string[],
    onSelect?: (value: DatePickerProps['value']) => void,
    dateChange?: (value: DatePickerProps['value']) => void,
    noSelect?: boolean
    activeSelect?: boolean
}

const CustomCalendar:React.FC<Props> = ({now, dateChange, onSelect, activeArr = [], noSelect, activeSelect}) => {
    return(
        <CalenderWrap noSelect={noSelect} activeSelect={activeSelect}>
            <Calendar
                fullscreen={false}
                dateCellRender={(value) => activeArr?.indexOf(value.format("YYYY-MM-DD")) > -1 && now?.format("YYYY-MM-DD") !== value.format("YYYY-MM-DD") && (
                    <>
                        <FillColor>
                            {value.format("DD")}
                        </FillColor>
                    </>
                )}
                value={now || dayjs()}
                onChange={dateChange}
                onSelect={onSelect}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                    return (
                        <div style={{ padding: 8, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Button size={"small"} onClick={()=>dateChange && dateChange(now?.clone().subtract(1, "month"))}>이전</Button>
                            <Typography.Title style={{textAlign: "center", margin: "10px 0px"}} level={5}><b>{value.year()}</b>년 <b>{value.month() + 1}</b>월</Typography.Title>
                            <Button size={"small"} onClick={()=>dateChange && dateChange(now?.clone().add(1, "month"))}>다음</Button>
                        </div>
                    );
                }}
            />
        </CalenderWrap>
    )
}

export default CustomCalendar