import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import {useDepartment, useStore} from "../../Hooks";
import {Alert, Button, DatePicker, Form, message} from "antd";
import {useQuery} from "../../Hooks/useQuery";
import styled from "styled-components";
import {BranchInput, SearchRow, SearchWrap} from "../../Components";
import {queryAdd} from "../../Helper/Helper";
import {AttendanceTable, FoodCountTable, SalesTable} from "./DataInquiryTables";
import dayjs from "dayjs";
import {DepartmentItemModel} from "../../Model/DepartmentModel";
import {REPO} from "../../Repository/repo";
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import {DataInquiryModel, InquiryPurchaseModel, InquiryWorkModel} from "../../Model/OperateModel";

const ListWrap = styled.div`
  box-sizing: border-box;
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  button{
    margin-right: 10px;
    :last-child{
      margin-right: 0;
    }
  }
`

const Title = styled.h3`
`

type DataType = "sales-count" | "purchase-and-sales" | "work"

const DataInquiry: React.FC<{}> = () => {
    const location = useLocation()
    const {UIStore} = useStore()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const { departmentData } = useDepartment()
    const query = useQuery()
    // const [target, setTarget] = useState<DepartmentItemModel>()
    const [type, setType] = useState<DataType>()
    const [salesData, setSalesData] = useState<DataInquiryModel<any>>()
    const [purchaseData, setPurchaseData] = useState<DataInquiryModel<InquiryPurchaseModel>>()
    const [workData, setWorkData] = useState<DataInquiryModel<InquiryWorkModel>>()

    const onSearch = (trgetType?: DataType) => {
        const params = form.getFieldsValue()
        params.date_begin = params.date_begin ? params.date_begin.format("YYYY-MM-DD") : ""
        params.date_end = params.date_end ? params.date_end.format("YYYY-MM-DD") : ""
        if(!trgetType) {
            if(!type){
                message.error("조회하실 자료를 선택해 주세요.")
                return
            } else {
                params.type = type
            }
        } else {
            params.type = trgetType
        }

        if(!params.date_begin || !params.date_end){
            message.error("날짜를 입력해 주세요")
            return
        }
        // params.target_branch = target?.seq
        for (const [key, value] of Object.entries(params)) {
            query.set(key, value as string || "")
        }
        const newQuery = queryAdd(query)
        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }

    const fetchMethod = async () => {
        let type = query.get("type") as DataType
        if(!UIStore.branch || !type) { return }

        try {
            const result = await REPO.Operate.GetData(UIStore.getTarget(), type, {
                begin: query.get("date_begin") || "",
                end: query.get("date_end") || "",
                branch_seq: UIStore.branch.seq
            })

            switch (type){
                case "sales-count":
                    setSalesData(result.data)
                    break
                case "work":
                    setWorkData(result.data)
                    break
                case "purchase-and-sales":
                    setPurchaseData(result.data)
                    break
            }
        } catch (e) {

        }
    }

    useEffect(()=>{
        // let target = query.get("target_branch")
        // if(!target){
        //     query.set("target_branch", String(UIStore.branch?.seq))
        //     const newQuery = queryAdd(query)
        //     navigate({
        //         pathname: location.pathname,
        //         search: newQuery
        //     }, {
        //         replace: true
        //     })
        // }
        const data: any = {}
        query.forEach((q, k) => data[k] = String(q))
        data.date_begin = data.date_begin ? dayjs(data.date_begin) : dayjs().startOf('M')
        data.date_end = data.date_end ? dayjs(data.date_end) : dayjs().endOf("M")
        // setTarget(departmentData.data?.find(info => info.seq === Number(target)))
        setType(query.get("type") as DataType)
        form.setFieldsValue(data)
        fetchMethod()
    },[location])


    return (
        <ListWrap>
            <SearchWrap form={form} >
                <SearchRow>
                    {/*<BranchInput data={departmentData.data} value={target?.gbcode || ""} onChange={(value)=> {*/}
                    {/*    setTarget(value)*/}
                    {/*}}/>*/}
                    <Form.Item name={"date_begin"} label={"기간"}>
                        <DatePicker locale={locale} format={"YYYY-MM-DD"}/>
                    </Form.Item>
                    <label>~</label>
                    <Form.Item name={"date_end"}>
                        <DatePicker format={"YYYY-MM-DD"}/>
                    </Form.Item>
                    <Button onClick={()=>onSearch()} type={"primary"} style={{marginRight: "10px"}}>검색</Button>
                    <Button onClick={()=>onSearch("sales-count")} type={type === "sales-count" ? "primary" : undefined} style={{marginRight: "10px"}}>식수표</Button>
                    <Button onClick={()=>onSearch("purchase-and-sales")} type={type === "purchase-and-sales" ? "primary" : undefined} style={{marginRight: "10px"}}>매입매출표</Button>
                    <Button onClick={()=>onSearch("work")} type={type === "work" ? "primary" : undefined} >근태내역</Button>
                </SearchRow>
            </SearchWrap>
            {!type && <Alert message={"조회 할 자료를 선택해 주세요."} type={"info"} style={{marginBottom: "20px"}}/>}
            {type === "sales-count" && <FoodCountTable branch={UIStore.branch?.group_name} data={salesData}/>}
            {type === "purchase-and-sales" && <SalesTable branch={UIStore.branch?.group_name} data={purchaseData}/>}
            {type === "work" && <AttendanceTable branch={UIStore.branch?.group_name} data={workData}/>}
        </ListWrap>
    )
}

export default DataInquiry