import Recat, {useEffect, useRef, useState} from 'react'
import styled from "styled-components";
import {REPO} from "../../Repository/repo";
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React from 'react';
const Wrap = styled.div`
  .ck-editor__editable_inline{
    min-height: 350px
  }
`

const HtmlEditor: Recat.FC<{onChange?: (data: any)=>void, value?: string}> = ({onChange, value}) => {
    const [editorLoad, setEditorLoad] = useState(false)
    // const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setEditorLoad(true)
    }, []);

    // useEffect(()=>{
    //     if(value){
    //         setInputValue(value)
    //     }
    // },[value])

    function uploadAdapter(loader: any, editor: any) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: any) => {
                        body.append(`userfile`, file);
                        // console.log(file, 'file')
                        // console.log(body, 'res', file, 'res')
                        REPO.System.Upload(body)
                            .then((res) => {
                                    return resolve({
                                        default: res.data.url
                                    })
                                }
                            )
                            .then((res)=>{
                                return res
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        }
    }

    function uploadPlugin(editor: any) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
            return uploadAdapter(loader, editor);
        };
    }


    if(!editorLoad){
        return null
    }

    return(
        <Wrap>
            <CKEditor
                height={350}
                editor={ClassicEditor}
                data={value}
                onChange={(event: any, editor: any) => {
                    const data = editor.getData();
                    if(data){
                        onChange && onChange(data);
                    }
                }}
                onReady={(editor: any) => {
                    editor?.ui?.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                } }
                config={{
                    extraPlugins: [uploadPlugin],
                }}
            />
        </Wrap>
    )
}

export default HtmlEditor