import React from 'react'
import {Select} from "antd";

const MonthSelect: React.FC<{value: string, onChange: (value: string) => void}> = (props) => {
    return (
        <Select value={props.value} onChange={(v) => props.onChange(v)}>
            <Select.Option value={"01"}>01월</Select.Option>
            <Select.Option value={"02"}>02월</Select.Option>
            <Select.Option value={"03"}>03월</Select.Option>
            <Select.Option value={"04"}>04월</Select.Option>
            <Select.Option value={"05"}>05월</Select.Option>
            <Select.Option value={"06"}>06월</Select.Option>
            <Select.Option value={"07"}>07월</Select.Option>
            <Select.Option value={"08"}>08월</Select.Option>
            <Select.Option value={"09"}>09월</Select.Option>
            <Select.Option value={"10"}>10월</Select.Option>
            <Select.Option value={"11"}>11월</Select.Option>
            <Select.Option value={"12"}>12월</Select.Option>
        </Select>
    )
}

export default MonthSelect