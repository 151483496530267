import React, {useEffect, useRef, useState} from 'react'
import {Form, Input, InputRef, Select} from "antd";
import {InputProps} from "antd";
import {onlyNumber, toLocale} from "../../Helper/Helper";

interface Props extends Omit<InputProps, "value" | "onChange" | "onInput"> {
    value?: number
    onChange?: (value?: number | null) => void
    onInput?: (value?: number | null) => void
    state?: boolean
}

const LocaleInput = React.forwardRef<any, Props>((props, ref) => {
    const {onChange, value, required, onInput} = props
    const [inputValue, setInputValue] = useState("");

    const inRef = useRef<InputRef>(null)

    useEffect(()=>{
        if(props.state){
            inRef.current?.focus()
        }
    },[props.state])

    const inputValueChange = (e:React.ChangeEvent<HTMLInputElement>) => {   //onChange
        const value = onlyNumber(e.target.value);
        // setInputValue(toLocale(value));
        // onChange && onChange(value === 0 || value ? Number(value) : null)
        if(Number(value)){
            onChange && onChange(value === 0 || value ? Number(value) : null)
        } else {
            onChange && onChange(value === 0 || value ? value : null)
        }

        // if(value === "-" || value === "."){
        //     // onChange && onChange(value)
        //     setInputValue(value);
        // } else {
        //     setInputValue(toLocale(value));
        //     onChange && onChange(value === 0 || value ? Number(value) : null)
        // }

    }

    const inputChange = (e:React.FormEvent<HTMLInputElement>) => {     //onInput
        // @ts-ignore
        const value = onlyNumber(e.target.value || "");
        setInputValue(toLocale(value));

        if(Number(value)){
            onChange && onChange(value === 0 || value ? Number(value) : null)
        } else {
            onChange && onChange(value === 0 || value ? value : null)
        }


        // if(value === "-" || value === "."){
        //     setInputValue(value);
        //     // onChange && onChange(value || null)
        // } else {
        //     setInputValue(toLocale(value));
        //     onChange && onChange(value === 0 || value ? Number(value) : null)
        // }
        // onInput && onInput(value === 0 || value ? Number(value) : null)
    }
    // console.log(value)

    useEffect(()=>{
        setInputValue(toLocale(value))
    },[props.value])

    return (
        <Input
            {...props}
            value={inputValue}
            onChange={(e)=>inputValueChange(e)}
            onInput={(e)=>inputChange(e)}
            onBlur={(e)=>{
                if(Number(value) !== 0 && !Number(value)){
                    setInputValue("")
                    onChange && onChange(null)
                }
            }}
            ref={ref ? ref : inRef}
        />
    )
})

export default LocaleInput;