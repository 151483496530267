import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import {useStore} from "../../Hooks";
import {REPO} from "../../Repository/repo";
import {BoardItemModel} from "../../Model/BoardModel";
import {DocumentListModel} from "../../Model/DocumentModel";
import {DocumentList, NoticeList} from "../../Containers/MainTable/MainTable";

const MainPage = () => {
    const { UIStore } = useStore()
    const [notice, setNotice] = useState<BoardItemModel[]>([])
    const [document, setDocument] = useState<DocumentListModel[]>([])

    useEffect(()=>{
        // UIStore.setLoading(false)
        fetchMethod()
    },[])

    const fetchMethod = async () => {
        try {
            const result = await REPO.System.Main()
            setNotice(result.data.notice)
            setDocument(result.data.document)
        } catch (e) {

        }
    }

    return(
        <PageWrap>
            <h3>공지사항</h3>
            <NoticeList data={notice}/>
            <h3>전자결재 목록</h3>
            <DocumentList data={document}/>
        </PageWrap>
    )
}

export default MainPage