import React, {ReactNode, useCallback, useEffect, useState} from 'react'
import {Drawer, Layout, Spin, Typography} from "antd";
import SideMenu from "./SideMenu";
import styled from "styled-components";
import SiteHeader from "./SiteHeader";
import {useLocation, useMatches, useNavigate} from "react-router-dom";
import {Breadcrumb} from "../../Components";
import {useDepartment, useStore} from "../../Hooks";
import {useQuery} from "../../Hooks/useQuery";
import {ModalPage} from "../../Model/UIModel";
import {DrawerPage} from "../index";
import useUser from "../../Hooks/useUser";
import {queryAdd} from "../../Helper/Helper";

const { Sider, Header, Content, Footer } = Layout

const LayoutWrap = styled(Layout)`
  height: 100%;
  
  .ant-layout-header{
    background-color: #fff;
  }
`

const ContentWrap = styled.div`
  background-color: #fff;
  min-height: 100%;
  height: auto;
  margin: 0 20px;
  border: 1px solid #dee2e6;
  padding: 20px;
`

const CustomLayout: React.FC<{children: ReactNode}> = ({ children}) => {
    const match = useMatches() as Matches[]
    const query = useQuery()
    const { userData, userMessage } = useUser()
    const { departmentData } = useDepartment()
    const { UIStore } = useStore()
    const location = useLocation()
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(false)

    // if(!UIStore.branch){
    //     return null
    // }
    //

    // if(!isLoad){
    //     return null
    // }

    return(
        <LayoutWrap>
            <DrawerPage/>
            <Sider style={{
                position: "fixed",
                top:0,
                bottom:0,
                width: "200px",
                zIndex: 10,
            }}>
                <SideMenu/>
            </Sider>
            <Layout style={{paddingLeft: "200px"}}>
                <Header style={{padding: 0, top:0, position: "relative", }}>
                    <SiteHeader userData={userData?.data} />
                </Header>
                <Breadcrumb matches={match}/>
                {/*<BreadcrumbWrap>*/}
                {/*    <Breadcrumb.Item>홈</Breadcrumb.Item>*/}
                {/*    <Breadcrumb.Item>메인화면</Breadcrumb.Item>*/}
                {/*</BreadcrumbWrap>*/}
                <Spin spinning={ UIStore.loading }>
                    <Content>
                        <ContentWrap>
                            {children}
                        </ContentWrap>
                    </Content>
                </Spin>
            </Layout>
        </LayoutWrap>
    )
}

export default CustomLayout