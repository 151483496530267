import React from 'react'
import {Breadcrumb as AntdBreadCrumb} from "antd";
import styled from "styled-components";
import {IndexRouteObject, Link, useLocation} from "react-router-dom";
import {router} from "../../router";

const BreadcrumbWrap = styled(AntdBreadCrumb)`
  padding: 0 50px;
  margin: 5px 0;
  ol{
    justify-content: flex-end;  
  }
  
`

const { Item } = AntdBreadCrumb

const Breadcrumb: React.FC<{matches: Matches[]}> = ({ matches }) => {
    const itemsArray: {path?: string, name?: string}[] = []
    const match = matches[matches.length-1]
    const matchUrlArr = match.pathname.split("/")
    let items = router.routes[0].children as IndexRouteObject[] | undefined
    let path = "/"
    const findUrl = (router:IndexRouteObject[] | undefined, target: string) => {
        if(!router){ return }

        const temp = router.find((el) => el.path === target)

        if(!temp){
            const temp2 = router.find((el) => el.path === ":id")
            if (temp2){
                items = temp2.children
                itemsArray.push({
                    path: target,
                    name: temp2?.label
                })
                return
            }

            return
        }

        items = temp.children
        itemsArray.push({
            path: temp?.path,
            name: temp?.label
        })
    }

    matchUrlArr.map((url, key) => {
        if(!url){ return }
        findUrl(items, url)
    })

    return(
        <BreadcrumbWrap>
            <Item>
                <Link to={'/Main'}>홈</Link>
            </Item>
            {itemsArray.map((route, key) => {
                if(!route.path){
                     return null
                }
                let toPath = path + `${route.path}/`
                path = toPath
                return (
                    <Item key={key}>
                        {route.name}
                    </Item>
                )
            })}
        </BreadcrumbWrap>
    )
}

export default Breadcrumb