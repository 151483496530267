import React, {useEffect, useRef, useState} from 'react'
import {Button, Descriptions, Form, Input, InputRef, message, Select, Spin} from "antd";
import {EmployeeSearch, EmployeesList} from "../index";
import styled from "styled-components";
import {onConfirm, onError, onFocus, onSubmit} from "../../Helper/Helper";
import {useStore} from "../../Hooks";
import {GroupSelect, Uploader} from "../../Components";
import {do_branch} from "../../Model/DBModel";
import {Simulate} from "react-dom/test-utils";
import {REPO} from "../../Repository/repo";
import {useQuery} from "../../Hooks/useQuery";
import {BranchDetailModel} from "../../Model/BranchModel";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {FileModel} from "../../Model/Common";

const { Item } = Descriptions

const Wrap = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  label{
    :first-child{
      margin-left: 0;
    }
    margin: 0 10px;
  }
`

const BranchDetail:React.FC<{id?: number | string | null}> = ({id}) => {
    const [mode, setMode] = useState<"modify" | "add">("add");
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const query = useQuery()
    const [data, setData] = useState<BranchDetailModel>()

    const { UIStore } = useStore()
    const [form] = Form.useForm()

    const inputRef1 = useRef<InputRef>(null)
    const inputRef2 = useRef<InputRef>(null)
    const inputRef3 = useRef<InputRef>(null)

    useEffect(()=>{
        if(id){
            setMode("modify")
            fetchMethod()
        }
    },[])

    const fetchMethod = async () => {
        try {
            setIsLoading(true)
            const mdInfo = JSON.parse(query.get("mdPg") || "{}")
            // const groupList = await REPO.Branch.GetGroupList({
            //     offset: 0,
            //     limit: 100,
            // })
            const result = await REPO.Branch.BranchDetail(mdInfo.id)
            form.setFieldsValue(result?.data?.branch)
            // setGroupList(groupList.data?.list)
            setData(result.data)
            setIsLoading(false)
        } catch (e) {

        }
    }

    const deleteAction = () => {
        onConfirm("확인", "해당 지점을 삭제하시겠습니까?", async ()=>{
            try {
                if(!data){ return }
                await REPO.Branch.BranchRemove(id as number)
                UIStore.closeMdPg()
            } catch (e) {

            }
        })
    }

    const setManagerHandler = (seq: number | null) => {
        if(!seq){
            onError("확인", "해당 직원 데이터를 확인할 수 없습니다.")
            return
        }
        onSubmit("확인", "해당 직원을 책임자로 설정하시겠습니까?", async ()=>{
            try{
                // const permisson = new Set<number>(data?.permission_employee.filter(info => info.branch_seq === data?.branch.seq).map(info => info.employee_seq))
                const permisson = new Set<number>(data?.permission_employee.filter(info => info.group_seq === Number(id)).map(info => info.employee_seq))
                const result = await REPO.Branch.BranchModify(Number(id), {
                    branch: {
                        ...data?.branch,
                        employee_seq: seq,
                    },
                    permission_employee_seq: Array.from<number>(permisson) || []
                })
                message.success("책임자 설정이 완료되었습니다.")
            } catch (e) {

            }
        })
    }

    const onSubmitAction = (valuse: any) => {
        const params = valuse as do_branch

        if(!params.branch_name){
            return onError("확인", "지점명을 입력해주세요", ()=>onFocus(inputRef1))
        }

        if(!params.parent_group_seq){
            return onError("확인", "소속그룹을 선택해주세요", ()=>onFocus(inputRef2))
        }

        if(mode === "add" && !params.branch_closing_day){
            return onError("확인", "결산기준일을 입력해주세요", ()=>onFocus(inputRef3))
        }

        params.branch_extra_attach_file = params.branch_extra_attach_file?.map(info => {
            let temp = info as FileModel
            return temp.url || ""
        })

        if(mode === "modify") {
            onSubmit("확인", "지점 내용을 수정하시겠습니까?", async ()=>{
                try{
                    const permisson = new Set<number>(data?.permission_employee.filter(info => info.group_seq === Number(id)).map(info => info.employee_seq))
                    // console.log(permisson, data?.permission_employee)
                    const result = await REPO.Branch.BranchModify(Number(id), {
                        branch: params,
                        permission_employee_seq: Array.from<number>(permisson) || []
                    })
                    UIStore.closeMdPg()
                } catch (e) {

                }
            })
        } else {
            onSubmit("확인", "지점 내용을 등록하시겠습니까?", async ()=>{
                try{
                    const result = await REPO.Branch.BranchAdd(params)
                    UIStore.closeMdPg()
                } catch (e) {

                }

            })
        }
    }

    const userSelect = (user: EmployeesListModel) => {
        if(!data){ return }
        setData({
            ...data,
            permission_employee: [...data?.permission_employee, {
                ...user,
                employee_seq: user.seq,
                group_seq: Number(id)
            }]
        })
        setModalOpen(false)
    }

    return(
        <Wrap form={form} onFinish={onSubmitAction}>
            <Spin spinning={isLoading}>
                <EmployeeSearch active={modalOpen} close={()=>setModalOpen(false)} onSelect={userSelect}/>
                <Descriptions title={"기본정보"} column={2} bordered>
                    <Item label={"지점명*"}>
                        <Form.Item name={"branch_name"}>
                            <Input ref={inputRef1}/>
                        </Form.Item>
                    </Item>
                    <Item label={"소속그룹*"}>
                        <Form.Item name={"parent_group_seq"}>
                            <GroupSelect ref={inputRef2}/>
                        </Form.Item>
                    </Item>
                    <Item label={"지점주소"}>
                        <Form.Item name={"branch_address"}>
                            <Input/>
                        </Form.Item>
                    </Item>
                    <Item label={"지점연락처"}>
                        <Form.Item name={"branch_contact"}>
                            <Input/>
                        </Form.Item>
                    </Item>
                    {mode === 'modify'
                        ? <Item label={"지점 결산기준일"}>매월 {data?.branch?.branch_closing_day}일</Item>
                        : <Item label={"지점 결산기준일*"}>
                            <Row>
                                <label>매월</label>
                                <Form.Item name={"branch_closing_day"} style={{width: "150px"}}>
                                    <Select defaultValue={"1"}>
                                        <Select.Option value={"1"}>1일</Select.Option>
                                        <Select.Option value={"20"}>20일</Select.Option>
                                        <Select.Option value={"25"}>25일</Select.Option>
                                    </Select>
                                </Form.Item>
                                {/*<label>일</label>*/}
                            </Row>
                        </Item>
                    }
                    {mode === 'modify'
                        ? <Item label={"지점 책임자"}>{data?.branch?.employee_name}</Item>
                        : null
                    }
                </Descriptions>
                {mode === "modify" && <>
                <Descriptions title={"운영정보"} column={2} bordered>
                    <Item label={"식수단가"}>
                        <Form.Item name={"branch_extra_price"}>
                            <Input/>
                        </Form.Item>
                    </Item>
                    <Item label={"감가상각비(月)"}>
                        <Form.Item name={"branch_monthly_depreciation"}>
                            <Input/>
                        </Form.Item>
                    </Item>
                    <Item label={"목표구성비"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Form.Item name={"branch_target_ratio"} style={{marginRight: "10px"}}>
                                <Input/>
                            </Form.Item>
                            <label>%</label>
                        </div>
                    </Item>
                    <Item label={"지급이자(月)"}>
                        <Form.Item name={"branch_monthly_interest"}>
                            <Input/>
                        </Form.Item>
                    </Item>
                </Descriptions>
                <TitleWrap>
                    <h3>접근가능 직원</h3>
                    <Button onClick={()=>setModalOpen(true)}>추가</Button>
                </TitleWrap>
                <EmployeesList
                    type={"detail"}
                    parentSeq={Number(id)}
                    data={data?.permission_employee}
                    setManage={setManagerHandler}
                    isBranch
                    deleteHandler={(id) => {
                        if(!data){ return }
                        setData({
                            ...data,
                            permission_employee: data.permission_employee.filter((info, k) => id !== k)
                        })
                    }}
                />
                <Descriptions title={"추가정보"} column={1} bordered>
                    <Item label={"메모"}>
                        <Form.Item name={"branch_extra_memo"}>
                            <Input.TextArea rows={3} style={{resize: "none"}}/>
                        </Form.Item>
                    </Item>
                    <Item label={"첨부파일"}>
                        <Form.Item name={"branch_extra_attach_file"}>
                            <Uploader/>
                        </Form.Item>
                    </Item>
                </Descriptions>
                </>}
            </Spin>
            <BottomWrap>
                <div>
                    {mode === "modify" && <Button type={"primary"} onClick={deleteAction} danger>지점삭제</Button>}
                </div>
                <div>
                    <Button type={"primary"} style={{marginRight: "5px"}} htmlType={"submit"}>저장</Button>
                    <Button onClick={UIStore.closeMdPg}>목록으로</Button>
                </div>
            </BottomWrap>

        </Wrap>
    )


}

export default BranchDetail