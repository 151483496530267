import {Button, Input} from "antd";
import React, {useEffect, useState} from 'react'
import {TableComment} from "../../Model/CommentModel";
import {REPO} from "../../Repository/repo";
import dayjs from "dayjs";
import {Uploader} from "../index";
import {FileOutlined} from "@ant-design/icons";

interface Props{
    tableRow: string,
}

const CommentBox:React.FC<Props> = (props) => {
    const [dataList, setDataList] = useState<TableComment[] | null>(null);
    const [commentText, setCommentText] = useState("")
    const [commentFile, setCommentFile] = useState<any>(null)

    const CommentList = async() => {
        const result = await REPO.Comment.Get(props.tableRow)
        setDataList(result.data.list)
    }

    const CommentWrite = async() => {
        const result = await REPO.Comment.Add({
            table_row: props.tableRow,
            comment_text: commentText,
            comment_file: commentFile ? commentFile[0].url : null
        })
        if (result.status === 204) {
            setCommentText("")
            setCommentFile(null)
            CommentList()
        }
    }

    const drawCommentList = () => {
        if (dataList === null || dataList.length === 0)
            return '';

        return dataList.map(comment => {
            return (<li style={{marginBottom: '8px'}}>
                <b>{comment.employee_name} ({dayjs(comment.comment_date).format('YYYY-MM-DD')})</b>: {comment.comment_text}
                {comment.comment_file &&
                    <><br /><a href={comment.comment_file[0].url} target="_blank"><FileOutlined />{comment.comment_file[0].name}</a></>
                }
            </li>)
        })
    }

    useEffect(() => {
        CommentList()
    }, [])

    return (<div>
        <Uploader maxCount={1} value={commentFile} onChange={(list) => setCommentFile(list)} />
        <Input.Group compact>
            <Input value={commentText} onChange={e => setCommentText(e.target.value)} onPressEnter={CommentWrite} style={{ width: '90%' }} placeholder="남기실 댓글을 입력하세요." />
            <Button onClick={CommentWrite} style={{ width: '10%' }} type="primary">추가</Button>
        </Input.Group>
        <ul style={{padding: 0}}>{ drawCommentList() }</ul>
    </div>)
}

export default CommentBox