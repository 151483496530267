import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Typography} from "antd";
import styled from "styled-components";
import {BranchInput} from "../../Components";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {UserInfoModel} from "../../Model/UserModel";
import useAuth from "../../Hooks/useAuth";
import {useDepartment, useStore} from "../../Hooks";
import {DepartmentItemModel} from "../../Model/DepartmentModel";
import {ListModel, SelectModel} from "../../Model/Common";
import {useQuery} from "../../Hooks/useQuery";
import {getRouterPath, onConfirm, queryAdd} from "../../Helper/Helper";
import useUser from "../../Hooks/useUser";
import {router} from "../../router";
import {MessageListModel} from "../../Model/MessageModel";

const { Title } = Typography

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-bottom: 1px solid #adb5bd;
  padding: 0 50px;
  box-shadow: 0 0 4px 0 rgba(0,0,0, 0.2);
  h2, h4{
    line-height: 1.3em;
    margin: 0;
  }
  h4{
    color: ${({theme}) => theme.textGray};
  }
  
  div:last-child{
    &>span{
      margin-right: 10px;
    }
  }

`


const SiteHeader: React.FC<{userData?: UserInfoModel, messageCount?: ListModel<MessageListModel>}> = ({userData}) => {
    const [selectItem, setSelectItem] = useState<SelectModel[]>([])
    const { UIStore } = useStore()
    const { departmentData, clearDepartmentData } = useDepartment()
    const { clearUser } = useUser()
    const { logOut } = useAuth()
    const query = useQuery()
    const location = useLocation()
    const navigate = useNavigate()
    const [parent, setParent] = useState<DepartmentItemModel>();

    useEffect(()=>{
        setParent(departmentData.data?.filter(info => info.seq === UIStore.branch?.parent_group_seq)[0])
    },[UIStore.branch])
    // let group = departmentData?.data?.find(info => info.seq === Number(branchID[0]))

    // const getTreeData = (array?: DepartmentItemModel[]) => {
    //     if(!array || array.length === 0){
    //         return [{children:[]}]
    //     }
    //
    //     const map: {
    //         [index: number | string]: SelectModel | Pick<SelectModel, 'children'>
    //     } = {};
    //
    //     for(var i = 0; i < array.length; i++){
    //         let temp = {...array[i]}
    //
    //         let selectItem: SelectModel = {}
    //         const idKey = "seq"
    //         const id = temp[idKey]
    //         selectItem.title = temp.group_name
    //         selectItem.value = `${temp.group_name} ${i} ${idKey} ${temp.parent_group_seq}-${temp[idKey]}`
    //         selectItem.children = [];
    //         map[id] = selectItem;
    //         let parent = array[i]['parent_group_seq'] || '-';
    //         if(!map[parent]){
    //             map[parent] = {
    //                 children: []
    //             };
    //         }
    //         map[parent]?.children?.push({
    //             ...selectItem,
    //         });
    //     }
    //     return map[1].children
    // }
    //

    // useEffect(()=>{
    //     if(location.pathname === "/Login"){
    //         return
    //     }
    //
    //     if(UIStore.branch?.gbcode && !query.get("gbcode")){
    //         query.set('gbcode', UIStore.branch.gbcode)
    //         navigate({
    //             ...location,
    //             search: queryAdd(query)
    //         }, {
    //             replace: true
    //         })
    //         return
    //     }
    //
    //     const gbcode = query.get("gbcode")
    //     const data = departmentData.data || []
    //     if(!gbcode){
    //         query.set('gbcode', data[0]?.gbcode || "")
    //         navigate({
    //             ...location,
    //             search: queryAdd(query)
    //         }, {
    //             replace: true
    //         })
    //         return
    //     }
    //     const newBranch = departmentData.data?.filter(info => info.gbcode === gbcode)[0]
    //     if(newBranch){
    //         UIStore.setBranch(newBranch)
    //     }
    // },[query, departmentData])

    const queryChagneHandler = (value?: DepartmentItemModel) => {
        if(!value){
            return
        }

        const pathArr = getRouterPath(location.pathname)

        // console.log(value, pathArr[0])
        query.set("gbcode", value.gbcode)
        UIStore.setBranch(value)

        if(pathArr[0].path === "Branch"){ //지점관리 다온푸드 권한만 표시
            if(value.gbcode !== "G1"){
                // console.log(query.get("gbcode"), 'query')
                navigate({
                    pathname: "/Main",
                    search: queryAdd(query)
                })
                return
            }
        }

        if(value.group_type === "지점"){
            if(pathArr[1]?.branchType === "group"){
                navigate({
                    pathname: "/Main",
                    search: queryAdd(query)
                })
                return
            }
        } else {
            if(pathArr[1]?.branchType === "branch"){
                navigate({
                    pathname: "/Main",
                    search: queryAdd(query)
                })
                return
            }
        }


        navigate({
            pathname: location.pathname,
            search: queryAdd(query)
        })
    }
    // @ts-ignore
    return(
        <HeaderWrap>
            <div>
                <h4>{parent?.group_name || "다온푸드"}</h4>
                <h2>{UIStore.branch?.group_name || "운영관리 시스템"}</h2>
            </div>
            <div>
                <div>
                    <BranchInput allSelect data={departmentData?.data} value={UIStore.branch?.gbcode} onChange={queryChagneHandler}/>
                    <span>
                        <b>{userData?.employee_name}</b>님 환영합니다. (읽지않은 쪽지 <Link to={"/MyPage/Message/Reception"}><>{userData?.notification_count ?? 0}개</></Link>)
                    </span>
                    <Button size={"middle"} onClick={()=> {
                        logOut()
                        clearUser()
                        clearDepartmentData()
                    }}>로그아웃</Button>
                </div>
            </div>
        </HeaderWrap>
    )
}

export default SiteHeader