import React, {ReactNode, useEffect} from "react";
import {Outlet, useHref, useLocation, useMatch, useMatches, useNavigate, useNavigation} from "react-router-dom";
import styled from "styled-components";
import {CustomLayout} from "../Containers";
import {useStore} from "../Hooks";
import {GlobalAppContextProvider} from "../Store";
import {Spin} from "antd";
import {QueryClient, QueryClientProvider} from "react-query";

const IntroWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity
        }
    }
})


const Root: React.FC<{}> = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { UIStore } = useStore()


    if(location.pathname === "/Login"){
        return (
            <QueryClientProvider client={queryClient}>
                <Outlet/>
            </QueryClientProvider>
        )
    } else if (location.pathname ==="/Print") {
        return (
            <QueryClientProvider client={queryClient}>
                <GlobalAppContextProvider>
                    <IntroWrap>
                        <Outlet/>
                    </IntroWrap>
                </GlobalAppContextProvider>
            </QueryClientProvider>
        )
    } else {
        return (
            <QueryClientProvider client={queryClient}>
                <GlobalAppContextProvider>
                        <IntroWrap>
                            <CustomLayout>
                                <Spin spinning={UIStore.loading}>
                                    <Outlet/>
                                </Spin>
                            </CustomLayout>
                        </IntroWrap>
                </GlobalAppContextProvider>
            </QueryClientProvider>
        )
    }


}


export default Root