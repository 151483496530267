import styled from "styled-components";
import {Form} from "antd";

const SearchWrap = styled(Form)`
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  label{
    font-weight: bold;
    font-weight: bold;
  }
  input{
    width: 250px;
  }
  .ant-select{
    min-width: 130px;
    
  }
  
  .ant-form-item{
    margin: 0 5px;
    :first-child{
      maring-left: 0;
    }
  }
`

const SearchRow = styled.div`
  display: flex;
  :first-child{
    margin-bottom: 10px;  
  }
`


export {
    SearchWrap,
    SearchRow
}