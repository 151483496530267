import React, {ReactNode, useEffect, useState} from 'react'
import {Select, TreeSelect} from 'antd';
import {useDepartment, useStore} from "../../Hooks";
import {DepartmentItemModel} from "../../Model/DepartmentModel";
import {SelectModel} from "../../Model/Common";
import {GroupOutlined, LineOutlined, PartitionOutlined} from "@ant-design/icons";

const { Option, OptGroup } = Select


function getChildrenGroups(list:DepartmentItemModel[], groupSeq: number): DepartmentItemModel[] {
    const childrenSEQs: any[] = [];
    childrenSEQs.push(groupSeq)
    const checkChildren = (seq:number) => (row:DepartmentItemModel) => {
        if (row.parent_group_seq == seq) {
            childrenSEQs.push(row.seq)
            if (row.group_type == "그룹") {
                list.forEach(checkChildren(row.seq))
            }
        }
    }

    list.forEach(checkChildren(groupSeq))
    return list.filter(row => childrenSEQs.indexOf(row.seq) !== -1)
}

interface Props {
    onChange?: (value: DepartmentItemModel | undefined) => void,
    data?: DepartmentItemModel[],
    type?: "branch" | "group" | null,
    value?: string,
    allSelect?: boolean,
    nullAble?: boolean
}

const BranchInput: React.FC<Props> = ({onChange, data, type, value, allSelect, nullAble}) => {
    const { UIStore } = useStore()
    const [sortSelect, setSortSelect] = useState<DepartmentItemModel[]>();
    const [inputValue, setInputValue] = useState("");

    useEffect(()=>{
        if(!data || !UIStore.branch){
            return
        }

        if(allSelect){
            setSortSelect(data)
        } else {
            setSortSelect(getChildrenGroups(data ,UIStore.branch.seq))

        }
    },[UIStore.branch])

    useEffect(()=>{
        if(value === ""){
            setInputValue("")
        }
        const target = data?.find(e => e.gbcode === value);
        if(target){
            setInputValue(`${target.gbcode} ${target.group_name}`)
        }

    },[value])

    const selectChangeHandler = (value: string) => {
        if(!onChange){ return }
        const target = value.split(" ")[0]
        const temp = data?.find(info => target === info.gbcode)
        console.log(target, temp, 'inner valuer')
        onChange(temp)
    }



    if(!data){
        return null
    }

    return(
        <Select
            showSearch
            style={{width: "200px", marginRight: "10px"}}
            onChange={selectChangeHandler}
            value={inputValue}
        >
            {nullAble && <Option value={""}><b>전체</b></Option>}
            {sortSelect?.map((item, key) => {
                switch (item.group_type){
                    case "지점":
                        return (<Option key={key} value={`${item.gbcode} ${item.group_name}`}>{Array(item.depth).fill(0).map(() => <>&nbsp;</>) }{item.group_name}</Option>)
                    case "그룹":
                        return (<Option key={key} value={`${item.gbcode} ${item.group_name}`}><b>{Array(item.depth).fill(0).map(() => <>&nbsp;</>) }{item.group_name}</b></Option>)
                }
            })}
        </Select>
    )
}

export default BranchInput