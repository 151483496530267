import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import {Button, Descriptions, message} from "antd";
import {DEFAULT_LIST_COUNT} from "../../Constant/Common";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../Hooks/useQuery";
import {useStore} from "../../Hooks";

import {NotificationModel} from "../../Model/MyPageModel";
import styled from "styled-components";
import QRCodeSVG from "qrcode.react";
import useUser from "../../Hooks/useUser";
import {REPO} from "../../Repository/repo";
import {onError} from "../../Helper/Helper";

const { Item } = Descriptions

const DescriptionsWrap = styled(Descriptions)`
  .ant-descriptions-item-label{
    width: 15% !important;
  }
  .ant-descriptions-item-content{
    width: 75% !important;
  }
`

const TextWrap = styled.ol`
  padding-left: 20px;
  li{
    button{
      margin-left: 10px;
    }
  }
`

const QrWrap = styled.div`
  //padding: 20px;
  margin: 20px 0px;
  text-align: center;
  //display: inline-block;
  //border: 1px solid #ccc;
  //border-radius: 10px;
  font-weight: bold;
  font-size: 1.2em;
`

const MyInfoPage = () => {


    const user = useUser();
    const location = useLocation()

    useEffect(()=>{

    },[location])

    const qrTest = async () => {
        try {
            await REPO.System.PushTest();
            message.success('테스트 알림이 발송되었습니다.')
        } catch (e) {
            message.error('요청에 실패하였습니다.')
        }
    }


    const atnoty_code = user.userData?.data?.atnoty_push_code || ""
    return (
        <PageWrap>
            <h3>내정보관리</h3>
            <DescriptionsWrap column={1} bordered>
                <Item label={"푸시알림 수신"}>
                    <TextWrap>
                        <li>
                            우측 버튼으로 AtNoty 알리미 앱을 다운로드 후 설치합니다.
                            <a href={"https://noty.atw.kr/download.php?os=android"} target={"_blank"}>
                                <Button>안드로이드 APP</Button>
                            </a>
                            <a href={"https://apps.apple.com/kr/app/noty/id6448980095"} target={"_blank"}>
                                <Button>아이폰 APP</Button>
                            </a>
                        </li>
                        <li>앱 실행 후 아래 코드를 직접 입력하거나, QR코드 인식을 통해 입력합니다.</li>
                        <li>활성화 되면 알림 수신이 가능합니다.</li>
                        {atnoty_code
                            ? <QrWrap>
                                <QRCodeSVG value={atnoty_code} size={200}/>
                                <div>{atnoty_code}</div>
                            </QrWrap>
                            : null }
                        <li>
                            우측 버튼으로 정상 동작여부 확인이 가능합니다.
                            <Button onClick={qrTest}>테스트 알림 발송</Button>
                        </li>
                    </TextWrap>

                </Item>
            </DescriptionsWrap>
        </PageWrap>
    )
}

export default MyInfoPage