import styled from "styled-components";

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  button {
    margin: 0 5px;
  }
`

export default ListHeader