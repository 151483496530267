import React from 'react'
import {ColumnsType} from "antd/es/table";
import {Button, Form, Input, Radio, Select, Table} from "antd";
import LocaleInput from "../../Components/LocaleInput/LocaleInput";
import styled from "styled-components";
import {RequestManagementModel, RequestWorkModel} from "../../Model/OperateModel";
import TimeSelect from "../../Components/TimeSelect/TimeSelect";

interface Props {
    onChange: (target: keyof RequestWorkModel, targetValue: string, value: any, key: number) => void,
    data: any[]
    removeItem: (target: keyof RequestWorkModel, key: number) => void
}

const FlexWrap = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  label{
    margin-right: 5px;
  }
  .ant-select{
    width: 100px;
    margin-right: 20px;
  }
`


const AttendanceDefaultTable: React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '이름',
            dataIndex: 'employee_name',
            key: 'employee_name',
            align: "center",
            width: "10%",
        },
        {
            title: '근태',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            width: "35%",
            render: (t, r, i) => (
                <>
                    <Radio.Group buttonStyle="solid" value={props.data[i]["work_type"]} onChange={(radio)=>props.onChange("employee_list", "work_type", radio.target.value, i)}>
                        <Radio.Button value={"출근"}>출근</Radio.Button>
                        <Radio.Button value={"휴가"}>휴가</Radio.Button>
                        <Radio.Button value={"결근"}>결근</Radio.Button>
                        <Radio.Button value={"휴무"}>휴무</Radio.Button>
                    </Radio.Group>
                </>
            )
        },
        {
            title: '내용',
            dataIndex: 'work_type',
            key: 'work_type',
            align: "center",
            width: "20%",
            render: (t, r, i) => {
                const type = props.data[i]["work_type"]
                if(type === "출근") {
                    return (
                        <FlexWrap>
                            <label>연장:</label>
                            <TimeSelect value={props.data[i]["work_extended"]} onChange={(e)=>props.onChange("employee_list", "work_extended", e, i)}/>
                            <label>야간:</label>
                            <TimeSelect value={props.data[i]["work_nightly"]} onChange={(e)=>props.onChange("employee_list", "work_nightly", e, i)}/>
                        </FlexWrap>
                    )
                } else if(type === "휴가") {
                    return (
                        <FlexWrap>
                            <label>연차사용:</label>
                            <Select value={props.data[i]["work_holiday"] || 0} style={{width: "160px"}} onChange={(e)=>props.onChange("employee_list", "work_holiday", e, i)}>
                                <Select.Option value={0}>없음 (유급휴가)</Select.Option>
                                <Select.Option value={0.5}>0.5일</Select.Option>
                                <Select.Option value={1}>1일</Select.Option>
                            </Select>
                        </FlexWrap>
                    )
                }
            }
        },
        {
            title: '사유',
            dataIndex: 'work_reason',
            key: 'work_reason',
            align: "center",
            width: "20%",
            render: (t, r, i) => (
                <Input value={props.data[i]["work_reason"]} onChange={(e)=>props.onChange("employee_list", "work_reason", e.target.value, i)}/>
            )
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}

const AttendanceEtcTable: React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '이름',
            dataIndex: 'work_name',
            key: 'work_name',
            align: "center",
            render: (t, r, i) => (
                <>
                    <Input value={props.data[i]["work_name"]} onChange={(e)=>props.onChange("parttime_list", "work_name", e.target.value, i)}/>
                </>
            )
        },
        {
            title: '유형',
            dataIndex: 'work_type',
            key: 'work_type',
            align: "center",
            width: "200px",
            render: (t, r, i) => (
                <>
                    <Select value={props.data[i]["work_type"]} style={{width: "100%"}} onChange={(e)=>props.onChange("parttime_list", "work_type", e, i)}>
                        <Select.Option value={"파출부"}>파출부</Select.Option>
                        <Select.Option value={"아르바이트"}>아르바이트</Select.Option>
                        <Select.Option value={"기타"}>기타</Select.Option>
                    </Select>
                </>
            )
        },
        {
            title: '근무시각',
            dataIndex: 'work_time',
            key: 'work_time',
            align: "center",
            render: (t,r,i) => (
                <Input value={props.data[i]["work_time"]} onChange={(e)=>props.onChange("parttime_list", "work_time", e.target.value, i)}/>
            )
        },
        {
            title: '사유',
            dataIndex: 'work_reason',
            key: 'work_reason',
            align: "center",
            render: (t,r,i) => (
                <Input value={props.data[i]["work_reason"]} onChange={(e)=>props.onChange("parttime_list", "work_reason", e.target.value, i)}/>
            )
        },
        {
            title: "",
            dataIndex: 'seq',
            key: 'seq',
            align: "center",
            render: (t, r, i) => <Button size={"small"} onClick={()=>props.removeItem("parttime_list", i)} danger>삭제</Button>
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}


export {
    AttendanceEtcTable,
    AttendanceDefaultTable,
}