import React from 'react'
import {Select, SelectProps} from "antd";

const TimeSelect: React.FC<{value: string, onChange: (value: string) => void}> = (props) => {
    return(
        <Select value={props.value} onChange={props.onChange} style={{width: "150px"}}>
            <Select.Option value={"00:00:00"}>
                없음
            </Select.Option>
            {Array(12).fill(0).map((info, h) => (
                Array(6).fill(0).map((i, m) => {
                    let hour = h
                    let min = m + 1
                    if (m === 5) {
                        hour = hour + 1
                        min = 0
                    }
                    let text = `${hour ? `${hour}시간` : ""} ${min === 0 ? "" : `${min}0분`}`
                    let value = `${hour ? `0${hour}` : "00"}:${min}0:00`

                    return (
                        <Select.Option key={`${h}-${m}`} value={value}>
                            {text}
                        </Select.Option>
                    )
                })
            ))}

            {/*<Select.Option value={"02:00"}>2시간</Select.Option>*/}
            {/*<Select.Option value={"03:00"}>3시간</Select.Option>*/}
            {/*<Select.Option value={"04:00"}>4시간</Select.Option>*/}
        </Select>
    )
}

export default TimeSelect