import React from "react";
import {useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../Constant/QueryKeys";
import {REPO} from "../Repository/repo";
import {BranchListModel} from "../Model/BranchModel";


const usePerson = (target: string) => {
    const queryClient = useQueryClient();

    const personData = useQuery(queryKeys.person, async ()=>{
        clearPersonData()
        const result = await REPO.Document.DocumentPerson(target)
        return result.data
    })


    function updatePersonData(newUser: BranchListModel[]): void {
        queryClient.setQueryData(queryKeys.person, newUser)
    }

    function clearPersonData() {
        queryClient.setQueryData(queryKeys.person, null)
    }

    return {
        personData,
        updatePersonData,
        clearPersonData
    }

}


export default usePerson
