import React, {useEffect, useState} from 'react';
import {Button, Descriptions, Form, Input, List, Select, Spin} from "antd";
import styled from "styled-components";
import {REPO} from "../../Repository/repo";
import {useQuery} from "../../Hooks/useQuery";
import {MessageDetailModel, MessageItemModel, MessageRequestType} from "../../Model/MessageModel";
import {getMessageListType, onConfirm} from "../../Helper/Helper";
import {useLocation, useNavigate} from "react-router-dom";
import {useStore} from "../../Hooks";
import {Uploader} from "../../Components";
import {FileModel} from "../../Model/Common";
const { Item } = Descriptions

const Wrap = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`
const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
    button {
        margin: 0 5px;
    }
`

const MessageDetail:React.FC<{id?: number | string | null}> = ({id}) =>{
   const [isLoading, setIsLoading] = useState<boolean>(false)
    const [data, setData] = useState<MessageItemModel>()
    const query = useQuery()
    const location = useLocation()
    const pathname = location.pathname.split('/')[3]
    const navigate = useNavigate()
    const type = getMessageListType(pathname) as MessageRequestType;
    const { UIStore } = useStore()


    const fetchMethod = async ()=>{
       try{
           setIsLoading(true)
           const mdInfo = JSON.parse(query.get("mdPg") || "{}")
           const result = await REPO.Message.GetDetail(mdInfo.id)
           setData(result.data.message)
           setIsLoading(false)
       }catch (e){
       }
    }

    const deleteAction = () => {
        onConfirm("확인","쪽지를 삭제하시겠습니까?",  async() => {
            try {
                const result = await REPO.Message.DeleteMessage(id as string)
                UIStore.closeMdPg()
            } catch (e) {

            }
        })
    }

    useEffect(() => {
        fetchMethod()
    }, []);

    console.log(data?.message_extra_attach_file)
    return(
        <>
            <Wrap>
                <Spin spinning={isLoading}>
                    <Descriptions column={2} bordered>
                        <Item label={"보낸사람"}>
                            {data?.message_sender_name}
                        </Item>
                        <Item label={"받는사람"}>
                            {data?.employee_name}
                        </Item>
                        <Item label={"발송일"}>
                            {data?.message_date}
                        </Item>
                        <Item label={"열람일시"}>
                            {data?.message_confirm ?? "-"}
                        </Item>
                        {data?.message_extra_attach_file?.length !== 0 && (
                            <Item label={"첨부파일"} span={2}>
                                {data?.message_extra_attach_file.map((file, index) => (
                                    <Uploader key={index} showRemoveIcon={false} buttonHidden value={[file]} onPreview={[file]}/>
                                ))}
                            </Item>
                        )}
                        <Item label={"제목"} span={2}>
                          {data?.message_subject}
                        </Item>
                        <Item label={"내용"} span={2}>
                            <div style={{minHeight: "300px"}} className={"document_description"}>
                                {<div dangerouslySetInnerHTML={{__html: String(data?.message_text)}}></div>}
                            </div>
                        </Item>

                    </Descriptions>
                    <BottomWrap>
                        <div>
                            <Button
                                onClick={() => {
                                 UIStore.onMdPg({
                                 active: true,
                                 content: 'sendMessage',
                                 subdata: 'reply',
                                 id: String(data?.seq),
                         })}}>답장</Button>
                            <Button
                                 onClick={()=>{UIStore.onMdPg({
                                 active: true,
                                 content: 'sendMessage',
                                 subdata: 'transmission',
                                 id: String(data?.seq),
                         })}}>전달</Button>
                         <Button type={"primary"} danger onClick={deleteAction}>삭제</Button>
                        </div>
                        <div>
                         <Button onClick={()=>{
                             navigate(`/MyPage/Message/${type === 'inbox'? 'Reception' : 'Outgoing'}`)
                         }}>목록</Button>
                        </div>
                    </BottomWrap>
                </Spin>
            </Wrap>
        </>
    )
}

export default MessageDetail;