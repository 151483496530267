import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Button, DatePicker, Descriptions, Form, Input, InputRef, message, Radio, Select} from "antd";
import {Uploader} from "../../Components";
import {documentType, documentTypeIsKey, onError, onFocus, onSubmit, queryAdd, toLocale} from "../../Helper/Helper";
import BranchSelect from "../../Components/BranchSelect/BranchSelect";
import useUser from "../../Hooks/useUser";
import {useLocation, useNavigate} from "react-router-dom";
import PersonSelect from "../../Components/PersonSelect/PersonSelect";
import HtmlEditor from "../../Components/HtmlEditor/HtmlEditor";
import LocaleInput from "../../Components/LocaleInput/LocaleInput";
import {
    do_branch,
    do_document,
    do_document_approval,
    do_document_remittance,
    do_document_type
} from "../../Model/DBModel";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import dayjs from "dayjs";
import {DocumentDetailModel, DocumentType, RequesetDocumentModel} from "../../Model/DocumentModel";
import {REPO} from "../../Repository/repo";
import {useStore} from "../../Hooks";
import {FileModel} from "../../Model/Common";
import {DOCUMENT_ITEM_TYPE_ARRAY} from "../../Constant/Common";
import {Draft, Official, Remittance} from "../../Constant/Document";
import {useQuery} from "../../Hooks/useQuery";

dayjs.extend(weekday)
dayjs.extend(localeData)

const { Item } = Descriptions

const CostWrap = styled.div`
    .ant-form-item{
      margin-bottom: 10px;
      label{
        width: 40px;
      }
      input{
        width: 200px;
      }
    }
`

const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
`


const ApprovalCreate:React.FC<{id: string | null}> = ({id}) => {
    const [form] = Form.useForm()
    const { userData } = useUser()
    const location = useLocation()
    const [texfree, setTexfree] = useState<number>();
    const [price, setPrice] = useState<number>();
    const [vat, setVat] = useState<number>();
    const {UIStore} = useStore()
    const [modeTitle, setModeTitle] = useState("");
    const [data, setData] = useState<DocumentDetailModel>();
    const [modifyMode, setModifyMode] = useState(false);
    const inputRef1 = useRef<any>(null)
    const inputRef2 = useRef<any>(null)
    const inputRef3 = useRef<any>(null)
    const inputRef4 = useRef<InputRef>(null)
    const inputRef5 = useRef<InputRef>(null)
    const inputRef6 = useRef<InputRef>(null)

    const navigate = useNavigate()
    const query = useQuery()
    const [mode, setMode] = useState<DocumentType>("Remittance");

    useEffect(()=>{
        if(id){
            fetchMethod()
            setModifyMode(true)
        } else {
            form.setFieldsValue({
                document_text: Remittance
            })
        }
    },[])

    const fetchMethod = async () => {
        try {
            // setIsLoading(true)
            const mdInfo = JSON.parse(query.get("mdPg") || "{}")
            const result = await REPO.Document.DocumentDetail(UIStore.getTarget(), mdInfo.id)
            const personArr = result.data.document_person
            setData(result.data)
            setMode(documentTypeIsKey(result.data.document.document_type))
            form.setFieldsValue({
                ...result.data.document,
                item_usedate: dayjs(result.data.document.item_usedate),
                person: {
                    "1st": personArr.find(info => info.person_type === "1차결재자")?.employee_seq || null,
                    "2nd": personArr.find(info => info.person_type === "2차결재자")?.employee_seq || null,
                    "3rd": personArr.find(info => info.person_type === "3차결재자")?.employee_seq || null,
                    "referer": personArr.find(info => info.person_type === "참조")?.employee_seq || null,
                }
            })

        } catch (e) {

        }
    }


    const submitAction = (values: any) => {
        const params = values as do_document

        if(!UIStore.branch?.seq){
            return onError("확인", "지점값이 없습니다")
        }

        if(!params.item_type){
            return onError("확인", "품목을 선택해주세요", ()=>onFocus(inputRef1))
        }

        if(!params.item_usedate){
            return onError("확인", "사용날짜를 선택해주세요", ()=>onFocus(inputRef2))
        }

        if(!params.item_payment){
            return onError("확인", "결제방법을 선택해주세요", ()=>onFocus(inputRef3))
        }

        // if(!Number((texfree || 0) + (price || 0) + (vat || 0))){
        //     return onError("확인", "금액을 입력해주세요.", ()=>onFocus(inputRef4))
        // }

        if(!params.document_subject){
            return onError("확인", "제목을 입력해주세요", ()=>onFocus(inputRef5))
        }

        if(!params.document_text){
            return onError("확인", "내용 입력해주세요")
        }

        if(!params.person["1st"]){
            return onError("확인", "1차결재자를 선택해주세요", ()=>onFocus(inputRef6))
        }
        let persons = Object.values(params.person).filter(info=> info)
        if(persons.length !== new Set(persons).size){
            return onError("확인", "결재대상이 중복되었습니다.", ()=>onFocus(inputRef6))
        }

        const requestParams:RequesetDocumentModel = {
            document: {
                branch_seq: UIStore.branch.seq,
                item_type: params.item_type,
                item_usedate: params.item_usedate ? dayjs(params.item_usedate).format("YYYY-MM-DD") : "",
                item_payment: params.item_payment,
                item_taxfree: params.item_taxfree || 0,
                item_price: params.item_price || 0,
                item_vat: params.item_vat || 0,
                document_type: documentType(mode),
                document_subject: params.document_subject,
                document_text: params.document_text,
                document_extra_attach_file: params.document_extra_attach_file?.map(info => {
                    let temp = info as FileModel
                    return temp.url || ""
                })
            },
            person: {
                ...params.person,
            }
        }
        
        if(modifyMode){
            if(!id){ return }
            onSubmit("확인", "문서를 수정하시겠습니까?", async ()=> {
                try {
                    requestParams.document.branch_seq = data?.document?.branch_seq
                    const result = await REPO.Document.DocumentPut(UIStore.getTarget(), id, requestParams)
                    form.resetFields()
                    message.success("요청이 성공적으로 완료되었습니다.")
                    UIStore.onMdPg({
                        active: true,
                        content: "document",
                        id: id || null,
                    })
                } catch (e) {

                }
            })
        } else {
            onSubmit("확인", "문서를 기안하시겠습니까?", async ()=> {
                try {
                    const result = await REPO.Document.DocumentPost(UIStore.getTarget(), requestParams)
                    form.resetFields()
                    message.success("요청이 성공적으로 완료되었습니다.")
                    // UIStore.closeMdPg()
                } catch (e) {

                }
            })
        }
        

    }

    // useEffect(()=>{
    //     form.setFieldsValue({
    //         branch_seq: UIStore.branch?.seq,
    //     })
    //     console.log(data, data?.document?.document_text, 'data')
    //
    //
    // },[mode])

    const modeChangeHandler = (type: DocumentType) => {
        let documentText: string = ""
        let title: string = ""

        switch (type) {
            case "Remittance":
                documentText = Remittance
                title = "송금신청서"
                break
            case "Expenses":
                documentText = ""
                title = "경비지출"
                break
            case "Draft":
                documentText = Draft
                title = "기안서"
                break
            case "Official":
                documentText = Official
                title = "공문"
                break
        }

        // setTimeout(()=>{
        //
        // },300)
        form.setFieldsValue({
            branch_seq: UIStore.branch?.seq,
            document_text: documentText
        })
        setModeTitle(title)
        setMode(type)
    }

    return(
        <div>
            <Form form={form} onFinish={submitAction}>
                <Descriptions column={2} bordered>
                    <Item label={"지점"}>
                        {UIStore?.branch?.group_name}
                        {/*<Form.Item name={"branch_seq"}>*/}
                        {/*    <BranchSelect allHide/>*/}
                        {/*</Form.Item>*/}
                    </Item>
                    <Item label={"기안자"}>
                        {modifyMode
                            ? <>{data?.document.employee_name}({data?.document.document_date})</>
                            : userData.data?.employee_name
                        }
                    </Item>
                    <Item label={"유형"}>
                        <Select style={{width: "100%"}} value={mode} onChange={(e)=>modeChangeHandler(e as DocumentType)}>
                            <Select.Option value={"Remittance"}>송금신청서</Select.Option>
                            <Select.Option value={"Expenses"}>경비지출</Select.Option>
                            <Select.Option value={"Draft"}>기안서</Select.Option>
                            <Select.Option value={"Official"}>공문</Select.Option>
                        </Select>
                    </Item>
                    <Item label={"품목*"}>
                        <Form.Item name={"item_type"}>
                            <Select placeholder={"품목을 선택해주세요."} ref={inputRef1} showSearch>
                                {DOCUMENT_ITEM_TYPE_ARRAY.map((info, key) => (
                                    <Select.Option key={key} value={info}>{info}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Item>
                    <Item label={"사용날짜*"}>
                        <Form.Item name={"item_usedate"}>
                            <DatePicker format={"YYYY-MM-DD"} ref={inputRef2}/>
                        </Form.Item>
                    </Item>
                    <Item label={"결제방법*"}>
                        <Form.Item name={"item_payment"}>
                            <Select placeholder={"결제방법을 선택해주법세요."} ref={inputRef3}>
                                <Select.Option value={"현금"}>현금</Select.Option>
                                <Select.Option value={"법인카드"}>법인카드</Select.Option>
                                <Select.Option value={"개인카드"}>개인카드</Select.Option>
                                <Select.Option value={"본사결제"}>본사결제</Select.Option>
                            </Select>
                        </Form.Item>
                    </Item>
                    <Item label={"금액*"} span={2}>
                        <CostWrap>
                            <Form.Item name={"item_taxfree"} label={"면세"}>
                                <LocaleInput ref={inputRef4} onChange={(value) => setTexfree(value || 0)}/>
                            </Form.Item>
                            <Form.Item name={"item_price"} label={"과세"}>
                                <LocaleInput onChange={(value) => setPrice(value || 0)}/>
                            </Form.Item>
                            <Form.Item name={"item_vat"} label={"VAT"}>
                                <LocaleInput onChange={(value) => setVat(value || 0)}/>
                            </Form.Item>
                            <div className={"ant-form-item"}>
                                <label>합계 : <span style={{marginLeft: "5px"}}>{
                                    toLocale(
                                        Number(texfree || 0)
                                        + Number(price || 0)
                                        + Number(vat || 0)
                                    )
                                }</span></label>
                                {/*<Input value={}/>*/}
                            </div>
                        </CostWrap>
                    </Item>
                    <Item label={"제목*"} span={2}>
                        <Form.Item name={"document_subject"}>
                            <Input ref={inputRef5}/>
                        </Form.Item>
                    </Item>

                    <Item label={"내용*"} span={2} contentStyle={{height: "300px"}}>
                        <Form.Item name={"document_text"}>
                            <HtmlEditor

                            />
                        </Form.Item>
                    </Item>
                    <Item label={"첨부파일"} span={2}>
                        <Form.Item name={"document_extra_attach_file"}>
                            <Uploader multiple/>
                        </Form.Item>
                    </Item>
                </Descriptions>
                <Descriptions layout={"vertical"} column={4} bordered>
                    <Item label={"1차결재자"}>
                        <Form.Item name={["person", "1st"]}>
                            <PersonSelect ref={inputRef6}/>
                        </Form.Item>
                    </Item>
                    <Item label={"2차결재자"}>
                        <Form.Item name={["person", "2nd"]}>
                            <PersonSelect />
                        </Form.Item>
                    </Item>
                    <Item label={"3차결재자"}>
                        <Form.Item name={["person", "3rd"]}>
                            <PersonSelect />
                        </Form.Item>
                    </Item>
                    <Item label={"참조"}>
                        <Form.Item name={["person", "referer"]}>
                            <PersonSelect />
                        </Form.Item>
                    </Item>
                </Descriptions>
                <BottomWrap>
                    <div>

                    </div>
                    <div>
                        <Button type={"primary"} style={{marginRight: "5px"}} htmlType={"submit"}>{id ? "수정" : "등록"}</Button>
                        <Button onClick={id ? ()=>UIStore.onMdPg({
                            active: true,
                            content: "document",
                            id: id || null,
                        }) : ()=>form.resetFields()}>취소</Button>
                    </div>
                </BottomWrap>
            </Form>

        </div>
    )
}

export default ApprovalCreate