import React, {useEffect, useState} from 'react'
import {
    AppstoreOutlined,
    ContainerOutlined,
    DesktopOutlined,
    MailOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu } from 'antd';
import Logo from "../../Components/Logo/Logo";
import styled from "styled-components";
import {router} from "../../router";
import {
    IndexRouteObject,
    Link,
    NonIndexRouteObject, RouteObject,
    useLocation,
    useMatches,
    useNavigate,
    useNavigation
} from "react-router-dom";
import {Simulate} from "react-dom/test-utils";
import {useStore} from "../../Hooks";

type MenuItem = Required<MenuProps>['items'][number];

const MenuHeader = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  div{
    margin-top: 5px;
  }
`

const items: MenuItem[] = []


function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const SideMenu: React.FC<{}> = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const location = useLocation()
    const match = useMatches()
    const navigator = useNavigate()
    const { UIStore } = useStore()
    const [selectedKey, setSelectedKey] = useState<string[]>([]);
    const [openKey, setOpenKey] = useState<string[]>([]);
    let routes = router.routes[0].children as RouteObject[]

    
    useEffect(()=>{
        let menuItems: MenuItem[] = []
        routes.forEach((route, key) => {
            if(!route.visibleSideMenu){
                return
            }

            if(!route.id){
                return;
            }

            if(route.path === "Branch"){
                if(UIStore.branch?.gbcode !== "G1"){
                    return
                }
            }

            // @ts-ignore
            menuItems.push({
                key: route.id,
                label: route.label,
                // @ts-ignore
                children: route.children.map((subMenu, k) => {
                    if(subMenu.visibleSideMenu === false){
                        return
                    }
                    if(UIStore.branch?.group_type === "그룹"){
                        if(subMenu.branchType === "branch"){
                            return
                        }
                    }
                    return {
                        key: subMenu.id,
                        label: subMenu.label,
                        onClick: ()=> {
                            // setSelectedKey([subMenu.id])
                            if(!subMenu.element){
                                alert("준비중인 페이지 입니다.")
                                return
                            }
                            navigator({
                                pathname: `/${route.path}/${subMenu.path}`,
                                search: `?gbcode=${UIStore.getTarget()}`
                            })
                        }
                    }
                })
            })
        })
        setMenuItems(menuItems)
    },[])


    useEffect(()=>{
        const routeKey = UIStore.routeID
        setOpenKey([routeKey.split("-").slice(0,2).join("-")])
        setSelectedKey([routeKey])
    },[UIStore.routeID])


    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return(
        <div style={{height: "100%", overflow: "auto", fontWeight: "bold", fontSize: "1.2em"}}>
            <MenuHeader>
                <Link to={"/Main"}>
                    <Logo/>
                </Link>
                {/*<div style={{fontSize:"0.8em"}}>운영 관리 시스템</div>*/}
            </MenuHeader>

            <Menu
                openKeys={openKey}
                selectedKeys={selectedKey}
                mode="inline"
                theme="dark"
                onOpenChange={(key)=>setOpenKey(key)}
                onSelect={(key)=> {
                    const target = routes.find((route) => key.keyPath[1] === route.id )
                    if(target){
                        const targetRoute = target.children?.find(route => key.keyPath[0] === route.id)
                        if(!targetRoute?.element){
                            return
                        }
                    }

                    UIStore.setRouteID(key.selectedKeys[0])
                    setSelectedKey(key.selectedKeys)
                }}
                items={menuItems}

            />
        </div>
    )
}

export default SideMenu