import React, {useEffect, useState} from "react";
import {BranchListModel} from "../../Model/BranchModel";
import {REPO} from "../../Repository/repo";
import {Select} from "antd";
import {useStore} from "../../Hooks";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import usePerson from "../../Hooks/usePerson";

interface Props {
    onChange?: (value: number) => void,
    value?: number
}


const PersonSelect = React.forwardRef<any, Props>( ({onChange, value}, ref) => {
    const [inputValue, setInputValue] = useState<number>()
    const { UIStore } = useStore()
    const { personData } = usePerson(UIStore.getTarget())
    useEffect(()=>{
        fetchMethod()
    },[])

    const fetchMethod = async () => {
        // try {
        //     const personList = await REPO.Document.DocumentPerson(UIStore.getTarget())
        //     setPersonList(personList?.data?.list)
        //     setInputValue(value)
        // } catch (e) {
        //
        // }
    }

    const inputValueChange = (e: number) => {
        const value = e
        setInputValue(value)
        if(onChange){
            onChange(value)
        }
    }

    if(!personData.data){
        return null
    }

    return(
        <Select ref={ref} onChange={inputValueChange} value={value} placeholder={"선택해주세요"}>
            <Select.Option value={null}>{"선택해주세요."}</Select.Option>
            {personData.data?.list?.map((item, key) => (
                <Select.Option key={key} value={item.seq}>{item.employee_name}</Select.Option>
            ))}
        </Select>
    )
})

export default PersonSelect