import React, {ReactNode} from 'react'
import styled from "styled-components";
import {Button, DatePicker, Form, Input, Select, Table} from "antd";
import {Link, useLocation} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {GroupListModel} from "../../Model/BranchModel";
import {ListHeader, SearchRow, SearchWrap} from "../../Components";
import {mdPg, onConfirm} from "../../Helper/Helper";
import {EmployeeGroup, EmployeesListModel} from "../../Model/EmployeesModel";
import {useStore} from "../../Hooks";

const ListWrap = styled.div`
  box-sizing: border-box;
`

type ListModel = ColumnsType<EmployeeGroup>

const AuthListTable: React.FC<{data?: EmployeeGroup[]}> = ({data}) => {
    const location = useLocation()
    const {UIStore} = useStore()
    const [form] = Form.useForm()
    const deleteAction = () => {
        onConfirm("확인", "해당 권한을 삭제하시겠습니까?", ()=>{})
    }

    let columns: ListModel = [
        {
            title: '#',
            dataIndex: 'group_seq',
            key: 'group_seq',
        },
        {
            title: '유형',
            dataIndex: 'group_type',
            key: 'group_type',
        },
        {
            title: '이름',
            dataIndex: 'group_name',
            key: 'group_name',
        },
        // {
        //     title: '삭제',
        //     dataIndex: 'seq',
        //     key: 'seq',
        //     render: (t) => (
        //         <Button onClick={deleteAction} size={"small"} type={"dashed"} danger>삭제</Button>
        //     )
        // },
    ];

    return (
        <ListWrap>
            <Table
                dataSource={data || []}
                columns={columns}
                pagination={false}
            />
        </ListWrap>
    )
}

export default AuthListTable