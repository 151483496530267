import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import {EmployeesList} from "../../Containers";
import {REPO} from "../../Repository/repo";
import {TablePaginationConfig} from "antd";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {BranchListModel} from "../../Model/BranchModel";
import {queryAdd} from "../../Helper/Helper";
import {DEFAULT_LIST_COUNT} from "../../Constant/Common";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../Hooks/useQuery";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {useStore} from "../../Hooks";

const EmployeesPage = () => {
    const [data, setData] = useState<EmployeesListModel[]>([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(DEFAULT_LIST_COUNT)
    const [offset, setOffset] = useState(0)
    const {UIStore} = useStore()
    const navigate = useNavigate()
    const location = useLocation()
    const query = useQuery()

    useEffect(()=>{
        fetchMethod()
    },[location])

    const fetchMethod = async () => {
        try{
            const result = await REPO.Employee.GetEmployeeList(UIStore.getTarget(),{
                offset: Number(query.get("offset")),
                limit: Number(query.get("limit") || limit),
                search_query: query.get("search_query") || "",
                search_type: query.get("search_type") || "",
                date_type: query.get("date_type") || "",
                date_begin: query.get("date_begin") || "",
                date_end: query.get("date_end") || "",
                branch_seq: query.get("branch_seq") || "",
                status: query.get("status") || ""
            })
            setData(result.data.list.map(info => ({...info, key: info.seq})))
            setTotal(result.data.total)
            setLimit(result.data.limit)
            setOffset(result.data.offset)
        } catch (e) {

        }
    }

    const pageChangeHandler = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<BranchListModel>) => {
        if(pagination.current && pagination.pageSize){
            query.set("offset", String((pagination.current-1) * pagination?.pageSize))
            query.set("limit", String(pagination.pageSize))
            const newQuery = queryAdd(query)
            navigate({
                pathname: location.pathname,
                search: newQuery
            })
        }
    };

    return (
        <PageWrap>
            <h3>직원목록</h3>
            <EmployeesList
                type={"list"}
                data={data}
                total={total}
                offset={offset}
                limit={limit}
                setLimit={setLimit}
                onChange={pageChangeHandler}
                fetchMethod={fetchMethod}
            />
        </PageWrap>
    )
}

export default EmployeesPage