import React from 'react'
import dayjs from "dayjs";
import {DatePickerProps, Table} from "antd";
import styled from "styled-components";
import {MonthStatus} from "../../Model/OperateModel";
import {ArrowDownOutlined} from "@ant-design/icons";

const getAlldate = (now: DatePickerProps['value'], lastday: number) => {
    if(!now){ return }
    let dates = [];
    let today = now.clone().startOf("M").date() -1

    for (let i = 0; i <= lastday-1; i++) {
        today++;
        //마지막 날보다 날짜가 클경우 today를 1로 초기화.
        if (today > lastday) {
            today = 1;
            dates[i] = today;
        }
        //일반 경우 그냥 날짜 추가
        else {
            dates[i] = today;
        }
    }

    return dates;
};

const getAllweak = (now: DatePickerProps['value'], lastday: number) => {
    if(!now){ return }
    let strWeak = ["일", "월", "화", "수", "목", "금", "토"];
    let weaklist = [];
    let temp = now.clone().startOf("M").day() -1
    //첫번째 오늘 날짜 적용
    for (let i = 0; i <= lastday-1; i++) {
        temp++;
        if (temp > 6) {
            temp = 0;
            weaklist[i] = strWeak[temp];
        } else {
            weaklist[i] = strWeak[temp];
        }
    }

    return weaklist;
};

const CalenderTable = styled.table`
  text-align: center;
  border-collapse : collapse;
  border-spacing: 0;
  
  th,td{
    min-width: 35px;
    min-height: 35px;
    height: 35px;
    border : 1px solid #ced4da;
  }
  
  .blue{
    color: #4c6ef5;
  }
  .dayoff{
    background-color: #ffd8a8 !important;
    //background-color: #ccc;
    //color: #fff !important;
  }
  
  .now{
    //background-color: #28D583 !important;
    //color: #fff !important;
    font-weight: bolder;
  }

  .active{
    //background-color: #ffa94d !important;
    background-color: #28D583 !important;
    color: #fff !important;
  }
  
  .red{ 
    color: #f03e3e;
  }
  
  th{
    
    background-color: #fafafa;
    
  }
  td:first-child{
    :hover{
      background-color: #fff;
      cursor: auto;
    }
  }
  td:hover{
    background-color: #28D58350;
  }
  td{
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.3s;
  }
`

const IconWrap = styled.div`
  color: #f03e3e;
  position: relative;
  margin-top: -30px;
  margin-bottom: 10px;
  text-align: center;
  width: 35px;
`

const CalendarWrap = styled.div`
  overflow-x: auto;
  width: auto;
  
  .dayoff{
    background-color: #ffd8a8 !important;
    //background-color: #ccc;
    //color: #fff !important;
  }

  .now{
    //background-color: #28D583 !important;
    //color: #fff !important;
    font-weight: bolder;
  }

  .active{
    //background-color: #ffa94d !important;
    background-color: #28D583 !important;
    color: #fff !important;
  }
`

const Box = styled.div`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 3px;
`

interface CalendarItemModel {
    day: number
    week: string
    color: string
    active: boolean
    now: boolean
    dateString: string
}

const HorizontalityCalendar: React.FC<{
    now: DatePickerProps['value'],
    activePage:string,
    dateChange?: (value: DatePickerProps['value'],
    target: string) => void,
    activeArr: MonthStatus
}> = ({ now, dateChange, activeArr, activePage }) => {
    if(!now) { return null }
    const lastDay = now.daysInMonth()

    const genetorDayTable = () => {
        let result: CalendarItemModel[] = []

        const CalendarDay = getAlldate(now, lastDay) || [];
        const CalendarWeak = getAllweak(now, lastDay) || [];

        CalendarWeak.forEach((week, key) => {
            let color = "none"
            if(week === "토"){ color = "blue"}
            if(week === "일"){ color = "red"}
            result.push({
                day: CalendarDay[key],
                week: week,
                color: color,
                active: false,
                now: CalendarDay[key] === now.date(),
                dateString: now.format("YYYY-MM") + `-${String(CalendarDay[key]).length === 1 ? `0${CalendarDay[key]}` : CalendarDay[key]}`
            })
        })

        return result
    }

    let timeTable = genetorDayTable()

    return (
        <CalendarWrap>
            <div style={{display: "flex", alignItems: "center"}}>
                <Box className={"active"}/> 등록완료 <Box className={"dayoff"}/> 쉬는날
            </div>
            <CalenderTable>
                <tbody>
                    <tr>
                        <th style={{minWidth: "120px"}}/>
                        {timeTable.map((info,key) => (
                            <th key={key} className={info.color}>
                                {info.now && activePage ? <IconWrap><ArrowDownOutlined /></IconWrap> : null }
                                {info.week}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        <td>운영일지</td>
                        {timeTable.map((info,key) => (
                            <td
                                key={key}
                                onClick={()=>dateChange && dateChange(now.clone().set('date', info.day), "Create")}
                                className={`
                                ${info.color}
                                ${activeArr.management_days_by_month.indexOf(info.dateString) > -1 ? ' active' : "" }
                                ${activeArr.management_dayoffs_by_month.indexOf(info.dateString) > -1 ? ' dayoff' : "" }
                                ${(info.now && activePage === "Create") && ' now'}
                                `}>{info.day}
                            </td>)
                        )}
                    </tr>
                    <tr>
                        <td>근태목록</td>
                        {timeTable.map((info,key) => (
                            <td
                                key={key}
                                onClick={()=>dateChange && dateChange(now.clone().set('date', info.day), "Attendance")}
                                className={`
                                ${info.color} ${activeArr.work_days_by_month?.indexOf(info.dateString) > -1 ? 'active' : "" }
                                ${activeArr.management_dayoffs_by_month.indexOf(info.dateString) > -1 ? ' dayoff' : "" }
                                ${(info.now && activePage === "Attendance") && 'now'}`}>{info.day}
                            </td>)
                        )}
                    </tr>
                </tbody>
            </CalenderTable>
        </CalendarWrap>
    )
}

export default HorizontalityCalendar