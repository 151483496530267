export const Remittance = "<div><h2>송금 신청서</h2><figure class=\"table\"><table><tbody><tr><td>순번</td><td>성명(거래처)</td><td>사업자(주민번호)</td><td>사유</td><td>금액</td><td>계좌</td><td>은행</td><td>예금주</td><td>비고(근무일)</td><td>주소</td></tr><tr><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>2</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>3</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>4</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></figure><p>* 신분증, 영수증 첨부 필수</p></div>"

export const Draft = `
    <h2>기안지</h2> 
    <figure class="table">
        <table>
            <tbody>
            <tr>
                <td>기안일자 :&nbsp;</td>
                <td>기안구분 :&nbsp;</td>
                <td>작 성 자 :&nbsp;</td>
            </tr>
            <tr>
                <td>기안부서 :&nbsp;</td>
                <td>전결권자 :&nbsp;</td>
                <td>보존기한 :&nbsp;</td>
            </tr>
            <tr>
                <td colSpan="3">제목 :</td>
            </tr>
            <tr>
                <td colSpan="3"><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
                    <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
                    이하 내용으로 품의를 올리오니 검토 후 재가 바랍니다.
                </td>
            </tr>
            </tbody>
        </table>
    </figure>
`

export const Official = `
    <h2>주식회사 다온푸드 서비스</h2><p>18469 경기도 화성시 동탄첨단산업1로 27 금강IX타워 B동 826호 &nbsp; &nbsp;Tel 031.376.0614 &nbsp;Fax 031.378. 0614 &nbsp; &nbsp;www.daon-food.co.kr</p><figure class="table ck-widget ck-widget_with-selection-handle" contenteditable="false"><div class="ck ck-widget__selection-handle"><svg class="ck ck-icon ck-reset_all-excluded ck-icon_inherit-color" viewBox="0 0 16 16"><path d="M4 0v1H1v3H0V.5A.5.5 0 0 1 .5 0H4zm8 0h3.5a.5.5 0 0 1 .5.5V4h-1V1h-3V0zM4 16H.5a.5.5 0 0 1-.5-.5V12h1v3h3v1zm8 0v-1h3v-3h1v3.5a.5.5 0 0 1-.5.5H12z"></path><path fill-opacity=".256" d="M1 1h14v14H1z"></path><g class="ck-icon__selected-indicator"><path d="M7 0h2v1H7V0zM0 7h1v2H0V7zm15 0h1v2h-1V7zm-8 8h2v1H7v-1z"></path><path fill-opacity=".254" d="M1 1h14v14H1z"></path></g></svg></div><table><tbody><tr><td class="ck-editor__editable ck-editor__nested-editable" role="textbox" contenteditable="true"><span class="ck-table-bogus-paragraph">문서번호 :&nbsp;<br>일자 :<br>수신 :<br>참조 :<br>제목 :</span></td></tr></tbody></table><div class="ck ck-reset_all ck-widget__type-around"><div class="ck ck-widget__type-around__button ck-widget__type-around__button_before" title="Insert paragraph before block" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"><path d="M9.055.263v3.972h-6.77M1 4.216l2-2.038m-2 2 2 2.038"></path></svg></div><div class="ck ck-widget__type-around__button ck-widget__type-around__button_after" title="Insert paragraph after block" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"><path d="M9.055.263v3.972h-6.77M1 4.216l2-2.038m-2 2 2 2.038"></path></svg></div><div class="ck ck-widget__type-around__fake-caret"></div></div></figure><p>- 귀원의 무궁한 발전을 기원합니다.<br><br><br data-cke-filler="true"></p></div>
`