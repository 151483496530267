import React, {useEffect, useState} from "react";
import {Button, Calendar, DatePicker, DatePickerProps, Descriptions, Form, Input, message, Select, Spin} from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import {REPO} from "../../Repository/repo";
import HorizontalityCalendar from "../../Components/HorizontalityCalendar/HorizontalityCalendar";
import {AttendanceDefaultTable, AttendanceEtcTable} from "./AttendanceTables";
import {useStore} from "../../Hooks";
import {MonthStatus, RequestManagementModel, RequestWorkModel} from "../../Model/OperateModel";
import {EmployeeSearch} from "../index";
import {AxiosError} from "axios/index";
import {DefaultApiResponse} from "../../Model/Common";
import {onConfirm, onSubmit, queryAdd} from "../../Helper/Helper";
import DateSelector from "../../Components/DateSelector/DateSelector";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../Hooks/useQuery";

const { Item } = Descriptions

const CostWrap = styled.div`
    .ant-form-item{
      margin-bottom: 10px;
      label{
        width: 40px;
      }
      input{
        width: 200px;
      }
    }
`

const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
  padding-top: 20px;
  
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  button{
    margin-right: 10px;
    :last-child{
      margin-right: 0;
    }
  }
`

const Title = styled.h3`
`



const AttendanceCreate: React.FC<{}> = () => {
    const [now, setNow] = useState<DatePickerProps['value']>()
    const [form] = Form.useForm()
    const { UIStore } = useStore()
    const [originData, setOriginData] = useState<RequestWorkModel>({
        employee_list: [],
        parttime_list: [],
    })
    const [data, setData] = useState<RequestWorkModel>({
        employee_list: [],
        parttime_list: [],
    })
    const [isLoad, setIsLoad] = useState( true);
    const [activeOperate, setActiveOperate] = useState<string[]>([]);
    const [onDateSelect, setOnDateSelect] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const location = useLocation()
    const navigator = useNavigate()
    const query = useQuery()
    const [monthStatus, setMonthStatus] = useState<MonthStatus>({
        management_days_by_month: [],
        management_dayoffs_by_month: [],
        work_days_by_month: [],
        closing_begin: "",
        closing_end: "",
        monthly_list: [],
    })

    useEffect(()=>{
        const now = query.get('now')
        setNow(now ? dayjs(now) : dayjs())
    },[query])


    useEffect(()=>{
        fetchMethod()
    },[now])

    const fetchMethod = async () => {
        if(!now || !UIStore.branch?.seq){
            return
        }

        const month = await REPO.Operate.GetMonthStatus(UIStore.getTarget(), now.format("YYYY-MM-DD"), UIStore.branch.seq)
        setMonthStatus(month.data)

        try {
            const result = await REPO.Operate.GetWork(UIStore.getTarget(), now.format("YYYY-MM-DD"), UIStore.branch.seq)
            let reqData = {
                employee_list: result?.data.work.employee_list || [],
                parttime_list: result?.data.work.parttime_list || []
            }
            // message.success(`${now.format("YYYY-MM-DD")} 자료를 불러왔습니다.`)
            setActiveOperate(result?.data.exists_days_by_month)
            setData(reqData)
            setOriginData(reqData)
            setIsLoad(false)
        } catch (e) {
            const error = e as AxiosError<DefaultApiResponse, any>
            if(error?.response?.data.code === 1404){
                message.info("작성된 근태기록이 없습니다. 기본 설정값으로 입력됩니다.")
                let reqData: RequestWorkModel
                if(error?.response?.data.extra.default_work){
                    reqData = error?.response?.data.extra.default_work
                } else {
                    reqData = {
                        employee_list: error?.response?.data.extra.employee_list || [],
                        parttime_list: error?.response?.data.extra.parttime_list || []
                    }
                }
                setActiveOperate(error?.response?.data.extra.exists_days_by_month)
                setData(reqData)
                setOriginData(reqData)
                setIsLoad(false)
            }
        }
    }

    const onInputChange = (target: keyof RequestWorkModel, targetValue: string, value: any, key: number) => {
        const newValue = [...data[target]].map((info, k) => {
            if(k === key){
                info[targetValue] = value
                info.error = false
                return info
            }
            return info
        })
        setData({
            ...data,
            [target]: newValue
        })
    }

    const addItem = (target: keyof RequestWorkModel, initValue?:any) => {
        const newValue = [...data[target]].concat(initValue ? initValue : {})
        setData({
            ...data,
            [target]: newValue
        })
    }

    const removeItem = (target: keyof RequestWorkModel, key: number) => {
        const newValue = [...data[target]].filter((info, k) => k !== key)
        setData({
            ...data,
            [target]: newValue
        })
    }


    const submitAction = (batch: boolean = false, temps: string[] = []) => {
        if(!now){
            return
        }

        let error = false
        let error_message = ""

        const parttime_list = data.parttime_list.map((info, key) => {
            if(!info.work_name){
                error = true
                error_message = "기타인력을 확인해주세요"
                info.error = true
            } else {
                delete info.error
            }
            return info
        })


        const employee_list = data.employee_list.map((info, key) => {
            if(!info.work_type){
                error = true
                error_message = "근태내역을 확인해주세요"
                info.error = true
            } else {
                info.error = false
            }

            let nulltime = "00:00:00"

            switch (info.work_type){
                case "출근":
                    info.work_extended = info.work_extended ? info.work_extended : nulltime
                    info.work_nightly = info.work_nightly ? info.work_nightly : nulltime
                    info.work_holiday = 0
                    break
                case "휴가":
                    info.work_extended = nulltime
                    info.work_nightly = nulltime
                    info.work_holiday = info.work_holiday ? Number(info.work_holiday) : 0
                    break
                case "휴무":
                    info.work_extended = nulltime
                    info.work_nightly = nulltime
                    info.work_holiday = 0
                    break
                case "결근":
                    info.work_extended = nulltime
                    info.work_nightly = nulltime
                    info.work_holiday = 0
                    break
            }

            return info
        })

        const params = {
            ...data,
            parttime_list: parttime_list,
            employee_list: employee_list
        }

        setData(params)

        if (error) {
            if(batch){
                setOnDateSelect(false)
            }
            return message.error(error_message)
        }

        if(batch){
            onSubmit("확인", "근태를 일괄등록 하시겠습니까?",  async()=> {
                const results = await temps.reduce(async (prev, curr) => {
                    const prevValue = await prev.then()
                    const result = await REPO.Operate.PostWork(UIStore.getTarget(), curr, data, UIStore.branch?.seq || 0)
                    if(result.status === 200){
                        prevValue.success = prevValue.success +1
                    } else {
                        prevValue.fail = prevValue.fail +1
                    }

                    return Promise.resolve(prevValue)
                }, Promise.resolve({success: 0, fail: 0}))

                message.info(`${results.success}건의 요청이 성공하였습니다.`)
                setOnDateSelect(false)
            })
        } else {
            onSubmit("확인", "근태를 등록 하시겠습니까?", async ()=> {
                try {
                    const result = await REPO.Operate.PostWork(UIStore.getTarget(), now.format("YYYY-MM-DD") || "",data, UIStore.branch?.seq || 0)
                    message.success("요청이 성공적으로 완료되었습니다.")
                } catch (e) {

                }
            })
        }



    }

    const dateCahngeHandler = (value: DatePickerProps['value'], target: string) => {
        if (!value) {
            return
        }

        let path = location.pathname
        query.set("now", value.format("YYYY-MM-DD"))

        if (target !== "Attendance") {
            path = "/Operate/Create"
        }

        navigator({
            pathname: path,
            search: queryAdd(query)
        }, {replace: true})

        // setNow(value)
    }

    const dateCahnge = (value: DatePickerProps['value']) => {
        if (!value) {
            return
        }

        let path = location.pathname
        query.set("now", value.format("YYYY-MM-DD"))

        navigator({
            pathname: path,
            search: queryAdd(query)
        }, {replace: true})

        // setNow(value)
    }

    const onDelete = () => {
        onConfirm("확인", `${now?.format("YYYY-MM-DD")}날짜의 근태를 삭제하시겠습니까?`, async ()=> {
            try {
                const result = await REPO.Operate.DeleteWork(UIStore.getTarget(), now?.format("YYYY-MM-DD") || "", UIStore.branch?.seq || 0)
                message.info("삭제되었습니다.")
                fetchMethod()
            } catch (e) {

            }

        })
    }

    const onCancel = () => {
        onConfirm("확인", "변경하신 내용을 취소하시겠습니까?", ()=> {
            setData({
                employee_list: originData?.employee_list || [],
                parttime_list: originData?.parttime_list || [],
            })
            message.info("취소되었습니다.")
        })
    }

    // if(isLoad){
    //     return null
    // }

    return(
        <Spin spinning={isLoad}>
            <EmployeeSearch active={modalOpen} close={()=>setModalOpen(false)} onSelect={(employee) => addItem("employee_list", {employee_seq: employee.seq, employee_name: employee.employee_name})}/>
            <DateSelector
                active={onDateSelect}
                onSubmit={submitAction}
                close={()=>setOnDateSelect(false)}
                onSelect={(temps)=>submitAction(true, temps)}
            />
            <Form form={form}>
                <HeaderWrap>
                    <Title>{now?.format("YYYY년 MM월")}</Title>
                    <div>
                        <Button style={{marginRight: "10px"}} onClick={()=>dateCahnge(now?.clone().subtract(1, "month"))}>이전</Button>
                        <DatePicker value={now} onChange={dateCahnge}/>
                        <Button style={{marginLeft: "10px"}} onClick={()=>dateCahnge(now?.clone().add(1, "month"))}>다음</Button>
                    </div>
                </HeaderWrap>
                <HorizontalityCalendar now={now} activePage={"Attendance"} dateChange={dateCahngeHandler} activeArr={monthStatus}/>
                <TitleWrap>
                    <Title>직원 근무</Title>
                    <div>
                        <Button onClick={()=>setModalOpen(true)}>직원 등록</Button>
                    </div>
                </TitleWrap>
                <AttendanceDefaultTable data={data.employee_list} onChange={onInputChange} removeItem={removeItem}/>
                <TitleWrap>
                    <Title>기타 인력</Title>
                    <Button onClick={()=>addItem("parttime_list", {work_type: "파출부"})}>추가</Button>
                </TitleWrap>
                <AttendanceEtcTable data={data.parttime_list} onChange={onInputChange} removeItem={removeItem}/>
                <BottomWrap>
                    <div>
                        <Button type={"primary"} onClick={onDelete} danger>근태삭제</Button>
                    </div>
                    <div>
                        <Button type={"primary"} style={{marginRight: "5px"}} onClick={()=>submitAction()}>등록</Button>
                        <Button type={"primary"} style={{marginRight: "5px"}} ghost onClick={()=>setOnDateSelect(true)}>일괄등록</Button>
                        <Button onClick={onCancel}>취소</Button>
                    </div>
                </BottomWrap>
            </Form>

        </Spin>
    )
}

export default AttendanceCreate