import React from 'react'
import {PageWrap} from "../../Components";
import OperateCreate from "../../Containers/Operate/OperateCreate";

const OperateCreatePage = () => {
    return (
        <PageWrap>
            <h3>운영일지 등록</h3>
            <OperateCreate/>
        </PageWrap>
    )
}

export default OperateCreatePage