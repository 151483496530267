import React, {ReactNode, useEffect, useState} from 'react'
import styled from "styled-components";
import {Button, DatePicker, Form, Input, message, Select, Space, Table} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {BranchInput, GroupSelect, ListHeader, SearchRow, SearchWrap} from "../../Components";
import {mdPg, onConfirm, queryAdd, toLocale} from "../../Helper/Helper";
import {useDepartment, useStore} from "../../Hooks";
import {ListProps} from "../../Model/Common";
import BranchSelect from "../../Components/BranchSelect/BranchSelect";
import {useQuery} from "../../Hooks/useQuery";
import dayjs from "dayjs";
import {DocumentListModel} from "../../Model/DocumentModel";
import {OperateApprovalsTable, OperateIngredientsTable, OperateSalesTable} from "./OperateTables";
import {DepartmentItemModel} from "../../Model/DepartmentModel";
import {REPO} from "../../Repository/repo";

const ListWrap = styled.div`
  box-sizing: border-box;
`

const Title = styled.h3`
  margin-top: 40px;
`

type ListModel = ColumnsType<any>

interface Props extends ListProps {
    data?: any[]
    deleteHandler?: (id: number) => void
}
const OperateList: React.FC<Props> = ({ deleteHandler,  onChange}) => {
    const location = useLocation()
    const {UIStore} = useStore()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const query = useQuery()
    const [target, setTarget] = useState<DepartmentItemModel>();
    const [targetSelect, setTargetSelect] = useState<DepartmentItemModel>()
    const [data, setData] = useState<any>({});
    const { departmentData } = useDepartment()

    useEffect(()=>{
        let target = query.get("target_branch")
        // console.log(target, 'target')
        if(!target){
            query.set("target_branch", String(UIStore.branch?.seq))
            const newQuery = queryAdd(query)
            navigate({
                pathname: location.pathname,
                search: newQuery
            }, {
                replace: true
            })
        }

        const data: any = {}
        query.forEach((q, k) => data[k] = String(q))
        if(!query.get("target_branch")){
            data.target_branch = UIStore.branch
        }
        data.date_begin = data.date_begin ? dayjs(data.date_begin) : null
        data.date_end = data.date_end ? dayjs(data.date_end) : null
        const temp = departmentData.data?.find(info => info.seq === Number(target))
        setTarget(temp)
        setTargetSelect(temp)
        // console.log(temp, 'temp')
        form.setFieldsValue(data)
        if(data.date_begin && data.date_end && temp){
            fetchMethod(temp)
        }
    },[location])

    useEffect(()=>{
        const target = Number(query.get("target_branch"))
        // console.log(UIStore.branch?.seq, query.get("target_branch"), target)
        if(!query.get("target_branch") || UIStore.branch?.seq !== target){
            query.set('target_branch', String(UIStore.branch?.seq))
            const newQuery = queryAdd(query)
            navigate({
                pathname: location.pathname,
                search: newQuery
            })
            // setTargetSelect(UIStore.branch)


        }
    },[UIStore.branch])

    // console.log(targetSelect, 'target')

    const onSearch = (values: any) => {
        const params = values
        if(!params.date_begin || !params.date_end){
            message.error("날짜를 선택해 주세요.")
            return
        }
        params.date_begin = params.date_begin.format("YYYY-MM-DD")
        params.date_end = params.date_end.format("YYYY-MM-DD")
        params.target_branch = targetSelect?.seq
        params.offset = 0
        for (const [key, value] of Object.entries(params)) {
            query.set(key, value as string || "")
        }
        const newQuery = queryAdd(query)
        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }

    const fetchMethod = async (target?: DepartmentItemModel) => {
        // if(!target){ return }
        let temp = target || UIStore.branch
        if(!temp){ return }
        try {
            const result = await REPO.Operate.GetManagementStatus(temp.gbcode,{
                date_begin: query.get("date_begin") || "",
                date_end: query.get("date_end") || "",
                branch_seq: temp?.seq
            })
            setData(result.data)
        } catch (e) {

        }
    }

    const targetChangeHandler = (value: string) => {
        query.set("target_branch", value)
        navigate({
            ...location,
            search: queryAdd(query)
        })
    }

    let columns: ListModel = [
        {
            title: '지점',
            dataIndex: 'branch_seq',
            key: 'branch_seq',
            render: (t,r) => (
                <a onClick={()=>targetChangeHandler(t)}>{r.branch_name}</a>
            )
        },
        {
            title: '운영일수',
            dataIndex: 'days',
            key: 'days',
        },
        {
            title: '매출',
            children: [
                {
                    title: <>전월결산</>,
                    dataIndex: 'closing_sale',
                    key: 'closing_sale',
                    align: "center",
                    render: (t, r) => (
                        <>
                            {toLocale(t)}<br/>
                            <span style={{fontSize: "0.8em"}}>({dayjs(r.closing_month).month()+1}월)</span>
                        </>
                    )
                },
                {
                    title: '금액',
                    dataIndex: 'management_sale',
                    key: 'management_sale',
                    align: "center",
                    render: (t) => toLocale(t)
                },
                {
                    title: "%",
                    dataIndex: 'sale_closing_ratio',
                    key: 'sale_closing_ratio',
                    align: "center",
                    render: (t) => toLocale(t) + "%"
                },
            ]
        },
        {
            title: '재료비',
            children: [
                {
                    title: "금액",
                    dataIndex: 'management_foodcost',
                    key: 'management_foodcost',
                    align: "center",
                    render: (t) => toLocale(t)
                },
                {
                    title: '구성비',
                    dataIndex: 'sale_foodcost_ratio',
                    key: 'sale_foodcost_ratio',
                    align: "center",
                    render: (t) => toLocale(t) + "%"
                },
                {
                    title: '목표',
                    dataIndex: 'branch_target_ratio',
                    key: 'branch_target_ratio',
                    align: "center",
                    render: (t) => toLocale(t) + "%"
                },
                {
                    title: '차이',
                    dataIndex: 'sale_target_ratio_diff',
                    key: 'sale_target_ratio_diff',
                    align: "center",
                    render: (t) => toLocale(t) + "%"
                },
            ]
        },
    ];

    const dayClick = (type: "1d" | "1w") => {
        let begin
        if(type === "1d"){
            begin = dayjs().subtract(1, "day")
        } else {
            begin = dayjs().subtract(1, "week")
        }
        form.setFieldsValue({
            date_begin: begin,
            date_end: dayjs()
        })
    }


    return (
        <ListWrap>
            <SearchWrap form={form} onFinish={onSearch}>
                <SearchRow>
                    <BranchInput
                        data={departmentData.data}
                        value={targetSelect?.gbcode || ""}
                        onChange={(value)=> {
                        setTargetSelect(value)
                    }}/>
                    <Form.Item name={"date_begin"} label={"기간"}>
                        <DatePicker format={"YYYY-MM-DD"}/>
                    </Form.Item>
                    <label>~</label>
                    <Form.Item name={"date_end"}>
                        <DatePicker format={"YYYY-MM-DD"}/>
                    </Form.Item>
                    <Button htmlType={"submit"} type={"primary"}>검색</Button>
                    <Form.Item style={{marginLeft: "20px"}}>
                        <Button style={{marginRight: "10px"}} onClick={()=>dayClick("1d")}>1일</Button>
                        <Button onClick={()=>dayClick("1w")} >1주일</Button>
                    </Form.Item>
                </SearchRow>
            </SearchWrap>

            {target?.group_type === "지점"
                ? <>
                    <Table
                        dataSource={data.list}
                        columns={columns}
                        pagination={false}
                        bordered
                    />
                    <Title>매출내역</Title>
                    <OperateSalesTable data={data.sale_list}/>
                    <Title>매입 내역</Title>
                    <OperateIngredientsTable data={data.purchase_list}/>
                    <Title>승인된 경비 내역</Title>
                    <OperateApprovalsTable data={data.expense_list}/>
                 </>
                : <Table
                    dataSource={data.list}
                    onRow={(r)=>{
                        return{
                            onClick: ()=>targetChangeHandler(r.branch_seq)
                        }
                    }}
                    columns={columns}
                    pagination={onChange}
                    bordered
                />
            }

        </ListWrap>
    )
}

export default OperateList