import React from 'react'
import {PageWrap} from "../../Components";

import DefaultCreate from "../../Containers/Operate/DefaultCreate";

const DefaultPage = () => {
    return (
        <PageWrap>
            <h3>기본값 설정</h3>
            <DefaultCreate/>
        </PageWrap>
    )
}

export default DefaultPage