import React, {useEffect, useState} from "react";
import {BranchListModel} from "../../Model/BranchModel";
import {REPO} from "../../Repository/repo";
import {Select} from "antd";
import useBranch from "../../Hooks/useBranch";
import {useDepartment} from "../../Hooks";
import styled from "styled-components";


const OptGroup = styled.div`
  font-weight: bold;
  color: #333 !important;
  font-size: 0.8em;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 8px;
`

interface Props {
    onChange?: (value: number) => void,
    value?: number,
    allHide?: boolean,
    groupSelect?: boolean,
}

const BranchSelect = React.forwardRef<any, Props>(({onChange, value, allHide, groupSelect}, ref) => {
    const [inputValue, setInputValue] = useState<number>()
    const { departmentData } = useDepartment()

    useEffect(()=>{
        //@ts-ignore
        setInputValue(Number(value) || "")
    },[value])

    const inputValueChange = (e: number) => {
        const value = e
        setInputValue(value)
        if(onChange){
            onChange(value)
        }
    }


    return(
            <Select ref={ref} dropdownStyle={{padding: 0}} onChange={inputValueChange} value={inputValue} placeholder={"지점을 선택해주세요"}>
                {!allHide && <Select.Option value={""}>전체</Select.Option>}
                {departmentData.data?.map((item, key) => {
                    if(item.group_type === "그룹"){
                        if(groupSelect){
                            return <Select.Option key={key} value={item.seq}>{Array(item.depth).fill(0).map(() => <>&nbsp;</>)}<b style={{fontSize: "0.9em"}}>{item.group_name}</b></Select.Option>
                        } else {
                            return <Select.OptGroup key={key} label={<OptGroup>{Array(item.depth).fill(0).map(() => <>&nbsp;</>)}{item.group_name}</OptGroup>}/>
                        }
                    } else {
                        return <Select.Option key={key} value={item.seq}>{Array(item.depth).fill(0).map(() => <>&nbsp;</>)}{item.group_name}</Select.Option>
                    }

                })}
            </Select>
    )
})

export default BranchSelect