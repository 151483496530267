import React, {useEffect, useRef, useState} from 'react'
import {Button, Descriptions, Form, Input, InputRef, Select, Spin} from "antd";
import {Link} from "react-router-dom";
import {EmployeeSearch, EmployeesList} from "../index";
import styled from "styled-components";
import {onConfirm, onError, onFocus, onSubmit} from "../../Helper/Helper";
import {useStore} from "../../Hooks";
import {REPO} from "../../Repository/repo";
import {useQuery} from "../../Hooks/useQuery";
import {GroupDetailModel, GroupListModel, RequestGroupModel} from "../../Model/BranchModel";
import {GroupSelect} from "../../Components";
import {EmployeesListModel} from "../../Model/EmployeesModel";

const { Item } = Descriptions

const Wrap = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BottomWrap = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;;
`

const GroupDetail:React.FC<{id: number | string}> = () => {
    const [data, setData] = useState<GroupDetailModel>()
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    // const [groupList, setGroupList] = useState<GroupListModel[]>([])
    const query = useQuery()
    const inputRef = useRef<InputRef>(null)

    const { UIStore } = useStore()

    const [form] = Form.useForm()

    const fetchMethod = async () => {
        try {
            setIsLoading(true)
            const mdInfo = JSON.parse(query.get("mdPg") || "{}")
            // const groupList = await REPO.Branch.GetGroupList({
            //     offset: 0,
            //     limit: 100,
            // })
            const result = await REPO.Branch.GroupDetail(mdInfo.id)
            form.setFieldsValue(result?.data?.group)
            // setGroupList(groupList.data?.list)
            setData(result.data)
            setIsLoading(false)
        } catch (e) {

        }
    }

    useEffect(()=>{
        fetchMethod()

    },[])

    const submitAction = (values: any) => {
        if(!values.group_name){
            return onError("확인", "그룹명을 입력해주세요", ()=>onFocus(inputRef))
        }
        onSubmit("확인", "그룹 내용을 수정하시겠습니까?",async ()=>{
            if(!data){
                return false
            }
            try {
                const permisson = new Set<number>(data?.permission_employee.filter(info => info.group_seq === data?.group.seq).map(info => info.employee_seq))
                const params: RequestGroupModel = {
                    group: values,
                    permission_employee_seq: Array.from<number>(permisson) || []
                }
                const result = await REPO.Branch.GroupModify(data.group.seq, params)
                UIStore.closeMdPg()
            } catch (e) {

            }
        })
    }

    const deleteAction = () => {
        onConfirm("확인", "해당 그룹을 삭제하시겠습니까?", async ()=>{
            try {
                if(!data){ return }
                await REPO.Branch.GroupRemove(data.group.seq)
                UIStore.closeMdPg()
            } catch (e) {

            }
        })
    }

    const userSelect = (user: EmployeesListModel) => {
        if(!data){ return }
        setData({
            ...data,
            permission_employee: [...data?.permission_employee, {
                ...user,
                employee_seq: user.seq,
                group_seq: data.group.seq
            }]
        })
        setModalOpen(false)
    }

    return(
        <Wrap form={form} onFinish={submitAction}>
            <Spin spinning={isLoading}>
                <EmployeeSearch active={modalOpen} close={()=>setModalOpen(false)} onSelect={userSelect}/>
                <Descriptions title={"기본정보"} column={2} bordered>
                    <Item label={"그룹번호"}>{data?.group?.seq}</Item>
                    <Item label={"표시여부"}>
                        <Form.Item name={"group_show"}>
                            <Select placeholder={"표시여부를 선택해주세요"}>
                                <Select.Option value={1}>표시</Select.Option>
                                <Select.Option value={0}>숨김</Select.Option>
                            </Select>
                        </Form.Item>
                    </Item>
                    <Item label={"그룹명*"}>
                        <Form.Item name={"group_name"}>
                            <Input ref={inputRef}/>
                        </Form.Item>
                    </Item>
                    <Item label={"소속그룹"}>
                        <Form.Item name={"parent_group_seq"}>
                            {/*<Select placeholder={"소속그룹을 선택해주세요"}>*/}
                            {/*    {groupList.map((item, key) => (*/}
                            {/*        <Select.Option key={key} value={item.seq}>{item.group_name}</Select.Option>*/}
                            {/*    ))}*/}
                            {/*</Select>*/}
                            <GroupSelect/>
                        </Form.Item>
                    </Item>
                    <Item label={"하위그룹"}>{data?.group_children.length}개</Item>
                    <Item label={"소속지점"}>
                        <Link to={{
                            pathname: "/Branch/Manage/"
                        }}>{data?.group_branches.length}개</Link>
                    </Item>
                </Descriptions>
                <TitleWrap>
                    <h4>관리직원목록</h4>
                    <Button onClick={()=>setModalOpen(true)}>추가</Button>
                </TitleWrap>
                <EmployeesList
                    type={"detail"}
                    parentSeq={data?.group.seq}
                    data={data?.permission_employee}
                    deleteHandler={(id) => {
                        if(!data){ return }
                        setData({
                            ...data,
                            permission_employee: data.permission_employee.filter((info, k) => id !== k)
                        })
                    }}
                />
            </Spin>
            <BottomWrap>
                <div>
                    <Button type={"primary"} onClick={deleteAction} danger>그룹삭제</Button>
                </div>
                <div>
                    <Button type={"primary"} style={{marginRight: "5px"}} htmlType={"submit"}>저장</Button>
                    <Button onClick={UIStore.closeMdPg}>목록으로</Button>
                </div>
            </BottomWrap>
        </Wrap>
    )
}

export default GroupDetail