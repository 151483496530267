import React, {useState} from 'react'
import {useQuery, useQueryClient} from "react-query";
import {REPO} from "../Repository/repo";
import {RequestAuthModel, ResponseTokenModel} from "../Model/AuthModel";
import {useLocalStorage} from "./useLocalStorage";
import {useStore} from "./index";
import {useNavigate} from "react-router-dom";
import {localStorageKeys} from "../Constant/LocalStorageKeys";
import {queryKeys} from "../Constant/QueryKeys";
import useUser from "./useUser";
import {onError} from "../Helper/Helper";
import {API, DO_API} from "../Repository/Api";


export interface AuthManager {
    isSign: boolean
    signIn: (params: RequestAuthModel, errorHandler?: ()=>void, prevUrl?: string) => void
    signUp: () => void
    signOut: () => void
    logOut: () => void
}


const useAuth: () => AuthManager = () => {
    const { UIStore } = useStore()
    const [token, setToken] = useLocalStorage<ResponseTokenModel | string>(localStorageKeys.tokenKey, null)
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    async function signIn(params: RequestAuthModel, errorHandler?: ()=>void, prevUrl?: string){
        queryClient.clear()
        try {
            const result = await REPO.Auth.Login(params)
            API.defaults.headers.common["Authorization"] = "Bearer " + result.data.access_token
            setToken(result.data)
            navigate("/Main")

        } catch (e) {
            console.log("error catch", e)
            if(errorHandler){
                errorHandler()
            } else {
                // onError("확인", )
            }
        }
    }

    async function logOut() {
        const result = await REPO.Auth.Revoke()
        setToken("")
        queryClient.clear()
        navigate("/Login")
        window.location.reload()
    }

    function signUp() {

    }

    function signOut() {

    }

    return { isSign: UIStore.isSign, signIn, signOut, signUp, logOut }

}

export default useAuth