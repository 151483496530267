import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {DefaultApiResponse} from "../Model/Common";
import {getLocalStorage, onError, setLocalStorage} from "../Helper/Helper";
import {localStorageKeys} from "../Constant/LocalStorageKeys";
import {ResponseTokenModel} from "../Model/AuthModel";
import {REPO} from "./repo";
import {Modal} from "antd";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const API = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: false,
})

API.defaults.headers.common["Authorization"] =`Bearer ${getLocalStorage<ResponseTokenModel>(localStorageKeys.tokenKey)?.access_token || "asdf123456cf"}`

let refresh = false
let tokenResetError = false

API.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return new Promise((resolve, reject) => {
            const originalReq = error.config

            if(!error.response){
                alert("요청중 오류가 발생하였습니다.")
                return reject(error)
            }

            if(error.response?.status !== 400)
                return reject(error)

            if(! error.response.data)
                return reject(error)

            const data = error.response.data as DefaultApiResponse

            switch (data.code) {
                case 401:
                    if(error.config && !error.config.__isRetryRequest){}
                    originalReq.__isRetryRequest = true

                    let res = REPO.Auth.Refresh({
                        grant_type: "refresh_token",
                        refresh_token: getLocalStorage<ResponseTokenModel>(localStorageKeys.tokenKey)?.refresh_token
                    })
                        .then(res => {
                            setLocalStorage(localStorageKeys.tokenKey, res.data)
                            originalReq.headers["Authorization"] = "Bearer " + res.data.access_token
                            API.defaults.headers.common["Authorization"] = "Bearer " + res.data.access_token
                            refresh = false
                            return axios(originalReq)
                        })

                    return resolve(res);

                case 1404:
                    if(!tokenResetError) {
                        tokenResetError = true
                        if(data.message === "token not found"){
                            setLocalStorage(localStorageKeys.tokenKey, undefined)
                            onError("확인", "인증이 만료되었습니다.", ()=>{
                                window.location.href = "/Login"
                                tokenResetError = false
                            })
                        } else {
                            // onError("확인", data.message, ()=>{window.history.back()})
                        }
                    }
                    break;

                case 1403:
                    onError("확인", data.message, ()=> {
                        const query = new URLSearchParams(window.location.search)
                        window.location.href = "/Main" + "?" + `gbcode=${query.get("gbcode")}`
                        // window.history.back()
                    })
                    break;

                default: onError("확인", data.message)

            }

            return reject(error)

        })
    }
)

const DO_API = {
    get: async (url: string, config?: AxiosRequestConfig) => {
        return await API.get(url, {
            ...config,
            headers: {
                Authorization: "Bearer " + `${getLocalStorage<ResponseTokenModel>(localStorageKeys.tokenKey)?.access_token || "asdf123456789"}`
            }
        })
            .then(res => res)
    },

    post: async (url: string, body?: any, config?: AxiosRequestConfig) => {
        return await API.post(url, body, {
            ...config,
            headers: {
                Authorization: "Bearer " + `${getLocalStorage<ResponseTokenModel>(localStorageKeys.tokenKey)?.access_token || "asdf123456789"}`
            }
        })
            .then(res => res)
    },

    patch: async (url: string, body?: any, config?: AxiosRequestConfig) => {
        return await API.patch(url, body, {
            ...config,
            headers: {
                Authorization: "Bearer " + `${getLocalStorage<ResponseTokenModel>(localStorageKeys.tokenKey)?.access_token || "asdf123456789"}`
            }
        })
            .then(res => res)
    },

    put: async (url: string, body?: any, config?: AxiosRequestConfig) => {
        return await API.put(url, body, {
            ...config,
            headers: {
                Authorization: "Bearer " + `${getLocalStorage<ResponseTokenModel>(localStorageKeys.tokenKey)?.access_token || "asdf123456789"}`
            }
        })
            .then(res => res)
    },

    delete: async (url: string, config?: AxiosRequestConfig) => {
        return await API.delete(url, {
            ...config,
            headers: {
                Authorization: "Bearer " + `${getLocalStorage<ResponseTokenModel>(localStorageKeys.tokenKey)?.access_token || "asdf123456789"}`
            }
        })
            .then(res => res)
    },
}


export {
    API,
    DO_API,
    API_BASE_URL
}