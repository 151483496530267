import React from "react";
import {useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../Constant/QueryKeys";
import {REPO} from "../Repository/repo";
import {UserInfoModel} from "../Model/UserModel";
import {DepartmentItemModel} from "../Model/DepartmentModel";


const useDepartment = () => {
    const queryClient = useQueryClient();

    const departmentData = useQuery(queryKeys.department, async ()=>{
        const result = await REPO.Department.My()
        return result.data
    })


    function updateDepartmentData(newUser: DepartmentItemModel[]): void {
        queryClient.setQueryData(queryKeys.department, newUser)
    }

    function clearDepartmentData() {
        queryClient.setQueryData(queryKeys.department, null)
    }

    return {
        departmentData,
        updateDepartmentData,
        clearDepartmentData
    }

}


export default useDepartment
