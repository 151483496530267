import React, {useEffect, useState} from 'react'
import {Button, DatePickerProps, Modal} from "antd";
import {CustomCalendar} from "../index";
import dayjs from "dayjs";

const DateSelector:React.FC<{active: boolean, close: ()=>void, onSelect: (dates: [])=>void, onSubmit: (batch?: boolean, temps?: string[])=>void}> = ({active, close, onSelect, onSubmit}) => {
    const [selected, setSelected] = useState<string[]>([]);
    const [now, setNow] = useState<DatePickerProps['value']>(dayjs());

    useEffect(()=>{
        setSelected([])
    },[active])

    const dateSelectHandler = (date: DatePickerProps['value']) => {
        let value = date?.format("YYYY-MM-DD") || ""
        if(selected.indexOf(value) > -1){
            setSelected(selected.filter(info => info !== value))
        } else {
            setSelected(selected.concat(value))
        }
    }

    // console.log(selected)

    return(
        <Modal
            open={active}
            onCancel={close}
            title={"일괄등록"}
            width={"360px"}
            footer={
                <>
                    <Button type={"primary"} onClick={()=>onSubmit(true, selected)}>등록</Button>
                    <Button onClick={close}>취소</Button>
                </>
            }
        >
            <CustomCalendar
                activeSelect
                now={now} dateChange={date=>setNow(date)}  activeArr={selected} onSelect={dateSelectHandler}/>
        </Modal>
    )
}

export default DateSelector