import React, {ReactNode, useEffect} from 'react'
import styled from "styled-components";
import {Button, DatePicker, Form, Input, Select, Table} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {GroupListModel} from "../../Model/BranchModel";
import {ListHeader, SearchRow, SearchWrap} from "../../Components";
import {mdPg, onConfirm, queryAdd, toLocale} from "../../Helper/Helper";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {useStore} from "../../Hooks";
import {ListProps} from "../../Model/Common";
import BranchSelect from "../../Components/BranchSelect/BranchSelect";
import {useQuery} from "../../Hooks/useQuery";
import dayjs from "dayjs";
import {LIST_CHANGE_ITEM} from "../../Constant/Common";

const ListWrap = styled.div`
  box-sizing: border-box;
`

type ListModel = ColumnsType<EmployeesListModel>

interface Props extends ListProps {
    type: "detail" | "list"
    data?: EmployeesListModel[]
    deleteHandler?: (id: number) => void
    setManage?: (id: number | null) => void
    parentSeq?: number
    isBranch?: boolean
}
const EmployeesList: React.FC<Props> = ({type, data, limit, total, setLimit, deleteHandler,  onChange, parentSeq,isBranch,setManage}) => {
    const location = useLocation()
    const {UIStore} = useStore()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const query = useQuery()

    useEffect(()=>{
        const data: any = {}
        query.forEach((q, k) => data[k] = String(q))
        data.date_begin = data.date_begin ? dayjs(data.date_begin) : null
        data.date_end = data.date_end ? dayjs((data.date_end)) : null
        form.setFieldsValue(data)
    },[location])

    const onSearch = (values: any) => {
        const params = values
        params.date_begin = params.date_begin ? params.date_begin.format("YYYY-MM-DD") : null
        params.date_end = params.date_end ? params.date_end.format("YYYY-MM-DD") : null
        params.offset = 0
        for (const [key, value] of Object.entries(params)) {
            query.set(key, value as string || "")
        }
        const newQuery = queryAdd(query)
        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }

    let columns: ListModel
    switch (type){
        case "detail":
            if(!deleteHandler){
                return null
            }
            columns = [
                {
                    title: '직원번호',
                    dataIndex: 'employee_seq',
                    key: 'employee_seq',
                    render: (t) => (<a onClick={()=>UIStore.onMdPg({
                        active: true,
                        content: "employee",
                        id: t,
                    }, "/Employee/Manage")}>{t}</a>)
                },
                {
                    title: '이름',
                    dataIndex: 'employee_name',
                    key: 'employee_name',
                },
                {
                    title: '직책',
                    dataIndex: 'employee_position',
                    key: 'employee_position',
                },
                {
                    title: '연락처',
                    dataIndex: 'employee_phone',
                    key: 'employee_phone',
                },
                {
                    title: '권한',
                    dataIndex: 'permission',
                    key: 'permission',
                },
                {
                    title: '',
                    dataIndex: 'seq',
                    key: 'seq',
                    render: (t, r, k) => (
                        isBranch &&  <Button onClick={() => setManage && setManage(r.employee_seq || null)} size={"small"}>책임자설정</Button>
                    )
                },
                {
                    title: '',
                    dataIndex: 'delete',
                    key: 'delete',
                    render: (t, r, k) => (
                        (parentSeq === r.group_seq) ? <Button onClick={()=>deleteHandler(k)} size={"small"} type={"dashed"} danger>삭제</Button> : null
                    )
                },
            ];
            break
        case "list":
            columns = [
                {
                    title: '직원번호',
                    dataIndex: 'seq',
                    key: 'seq',
                    render: (t) => (<Link to={{
                        pathname: location.pathname,
                        search: location.search + mdPg({
                            active: true,
                            content: "employee",
                            id: t,
                        }),
                    }}>{t}</Link>)
                },
                {
                    title: '이름',
                    dataIndex: 'employee_name',
                    key: 'employee_name',
                },
                {
                    title: '직책',
                    dataIndex: 'employee_position',
                    key: 'employee_position',
                },
                {
                    title: '소속지점',
                    dataIndex: 'branch_name',
                    key: 'branch_name',
                },
                {
                    title: '로그인',
                    dataIndex: 'employee_login',
                    key: 'employee_login',
                    render: (t)=> t ? "가능" : "불가"
                },
                {
                    title: '연락처',
                    dataIndex: 'employee_contact',
                    key: 'employee_contact',
                },
                {
                    title: '생년월일',
                    dataIndex: 'employee_birthday',
                    key: 'employee_birthday',
                },
                {
                    title: '입사일',
                    dataIndex: 'employee_begin',
                    key: 'employee_begin',
                },
                {
                    title: '퇴사일',
                    dataIndex: 'employee_end',
                    key: 'employee_end',
                },

            ];
    }

    return (
        <ListWrap>
            {type === "list" && <>
                <SearchWrap form={form} onFinish={onSearch}>
                    <SearchRow>
                        <Form.Item name={"search_type"} initialValue={"employee_name"}>
                            <Select>
                                <Select.Option value={"employee_name"}>이름</Select.Option>
                                <Select.Option value={"employee_position"}>직책</Select.Option>
                                <Select.Option value={"branch_name"}>소속지점</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={"search_query"}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={"date_type"} initialValue={"employee_begin"}>
                            <Select>
                                <Select.Option value={"employee_begin"}>입사일</Select.Option>
                                <Select.Option value={"employee_end"}>퇴사일</Select.Option>
                                <Select.Option value={"employee_birthday"}>생년월일</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={"date_begin"}>
                            <DatePicker format={"YYYY-MM-DD"}/>
                        </Form.Item>
                        <label>~</label>
                        <Form.Item name={"date_end"}>
                            <DatePicker format={"YYYY-MM-DD"}/>
                        </Form.Item>
                    </SearchRow>
                    <SearchRow>
                        <Form.Item name={"branch_seq"} label={"지점"}>
                            <BranchSelect allHide={false}/>
                        </Form.Item>
                        <Form.Item name={"status"} label={"상태"} >
                            <Select defaultValue={""}>
                                <Select.Option value={""}>전체</Select.Option>
                                <Select.Option value={"1"}>정상</Select.Option>
                                <Select.Option value={"2"}>퇴사</Select.Option>
                                <Select.Option value={"3"}>로그인가능</Select.Option>
                                <Select.Option value={"4"}>로그인불가</Select.Option>
                            </Select>
                        </Form.Item>
                        <Button htmlType={"submit"} type={"primary"}>검색</Button>
                    </SearchRow>
                </SearchWrap>
                <ListHeader>
                    <div></div>
                    <div>
                    <Button type={"primary"} onClick={()=>UIStore.onMdPg({
                    active: true,
                    content: "employee",
                    id: null
                })}>직원추가</Button>
                    </div>
                </ListHeader>
            </>}
            <Table
                dataSource={data}
                columns={columns}
                pagination={type === "detail" ? false : {
                    pageSize: limit,
                    pageSizeOptions: LIST_CHANGE_ITEM,
                    showSizeChanger: true,
                    onShowSizeChange: (e) => setLimit && setLimit(e),
                    showTotal: (total) => `검색결과 ${toLocale(total)}건`,
                    total: total,
                }}
                onChange={onChange}
                onRow={type === "detail" ? ()=>({}) : (r) => {
                    return{
                        onClick: () => {
                            UIStore.onMdPg({
                                active: true,
                                content: "employee",
                                id: String(r.seq)
                            })
                        }
                    }
                }}
            />
        </ListWrap>
    )
}

export default EmployeesList