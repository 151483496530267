import React, {ReactNode, useState} from 'react'
import styled from "styled-components";
import {Badge, Button, Input, message, Table} from "antd";
import {Link, useLocation} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {GroupListModel} from "../../Model/BranchModel";
import {green} from "@ant-design/colors";
import {ActiveBadge} from "../../Components";
import {do_branch} from "../../Model/DBModel";
import {mdPg, onConfirm, toLocale} from "../../Helper/Helper";
import {useStore} from "../../Hooks";
import AddGroupModal from "../../Components/AddGroupModal/AddGroupModal";
import {ListProps} from "../../Model/Common";
import {REPO} from "../../Repository/repo";
import {LIST_CHANGE_ITEM} from "../../Constant/Common";

const ListWrap = styled.div`
  box-sizing: border-box;
`

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  button {
    margin: 0 5px;
  }
`

type ListModel = ColumnsType<GroupListModel>

interface Props extends ListProps {
    data: GroupListModel[]
    changeShow: (seq_arr: number[], state: 0 | 1) => void
}

const GroupList: React.FC<Props> = ({data, total, limit, setLimit, offset,onChange, changeShow, fetchMethod}) => {
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false)
    const [initGroup, setInitGroup] = useState<number>()
    const [selectedRowsKeys, setSelectedRowsKeys] = useState<number[]>([])
    const { UIStore } = useStore()

    const deleteAction = (id: number) => {
        onConfirm("확인", "해당 그룹을 삭제하시겠습니까?", async ()=>{
            try {
                await REPO.Branch.GroupRemove(id)
                fetchMethod && fetchMethod()
            } catch (e) {

            }
        })
    }

    const columns: ListModel = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            render: (t) => (<a onClick={()=>UIStore.onMdPg({
                active: true,
                content: "group",
                id: t
            })}>{t}</a>)
        },
        {
            title: '그룹명',
            dataIndex: 'group_name',
            key: 'group_name',
            render: (t,r) => {
                return (
                    // <></>
                    <span>
                        {r.parent_group_seq ? <span style={{marginLeft: "10px"}}>ㄴ</span> : ""}{t}<ActiveBadge isActive={r.group_show}/>
                    </span>
                )
            }
        },
        {
            title: '권한 부여직원',
            dataIndex: 'group_permissions',
            key: 'group_permissions',
            render: (t) => t
        },
        {
            title: '지점수',
            dataIndex: 'branch_count',
            key: 'branch_count',
        },
        {
            title: '',
            dataIndex: 'seq',
            key: 'seq',
            render: (t, r) => (
                <span>
                    <Button size={"small"} type={"dashed"} style={{marginRight: "5px", zIndex: 100}} onClick={(e)=> {
                        e.stopPropagation()
                        setAddModalOpen(true)
                        setInitGroup(t)
                    }}>추가</Button>
                    {t !== 1 && <Button onClick={(e)=> {
                        e.stopPropagation()
                        deleteAction(t)
                    }} size={"small"} type={"dashed"} danger>삭제</Button>}
                </span>
            )
        },
    ];

    return (
        <ListWrap>
            <AddGroupModal
                data={data}
                open={addModalOpen}
                fetchMethod={fetchMethod}
                initGroup={initGroup}
                setOpen={(bool)=> {
                    setAddModalOpen(bool)
                    setInitGroup(undefined)
                }}
            />
            <ListHeader>
                <div>
                    <label>선택한 그룹을</label>
                    <Button onClick={()=> {
                        if(selectedRowsKeys.length === 0){
                            message.error("그룹을 선택해주세요")
                            return
                        }
                        changeShow(selectedRowsKeys, 1)
                        setSelectedRowsKeys([])
                    }}>표시</Button>
                    <Button onClick={()=> {
                        if(selectedRowsKeys.length === 0){
                            message.error("그룹을 선택해주세요")
                            return
                        }
                        changeShow(selectedRowsKeys, 0)
                        setSelectedRowsKeys([])
                    }}>숨김</Button>
                </div>
                <div>
                    <Button type={"primary"} onClick={()=>setAddModalOpen(true)}>그룹추가</Button>
                </div>
            </ListHeader>
            <Table
                dataSource={data}
                onRow={(r) => {
                    return{
                        onClick: (e) => {
                            UIStore.onMdPg({
                                active: true,
                                content: "group",
                                id: String(r.seq)
                            })
                        }
                    }
                }}
                columns={columns}
                pagination={{
                    pageSize: limit,
                    pageSizeOptions: LIST_CHANGE_ITEM,
                    showSizeChanger: true,
                    onShowSizeChange: (e) => setLimit && setLimit(e),
                    showTotal: (total) => `검색결과 ${toLocale(total)}건`,
                    total: total,
                }}
                onChange={onChange}
                rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: selectedRowsKeys,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: GroupListModel[]) => {
                        setSelectedRowsKeys(selectedRows.map(info => info.seq))
                    },
                }}
            />
        </ListWrap>
    )
}

export default GroupList