import React, {ReactNode, useEffect, useState} from 'react'
import styled from "styled-components";
import {Button, Form, Input, Select, Table} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {BranchListModel, GroupListModel} from "../../Model/BranchModel";
import {onConfirm, queryAdd, toLocale} from "../../Helper/Helper";
import {useStore} from "../../Hooks";
import AddGroupModal from "../../Components/AddGroupModal/AddGroupModal";
import {SearchRow, SearchWrap, ListHeader, GroupSelect} from "../../Components";
import {ListProps} from "../../Model/Common";
import {useQuery} from "../../Hooks/useQuery";
import {LIST_CHANGE_ITEM} from "../../Constant/Common";

const ListWrap = styled.div`
  box-sizing: border-box;
`



type ListModel = ColumnsType<BranchListModel>

interface Props extends ListProps {
    data: BranchListModel[]
}

const BranchList: React.FC<Props> = ({data, total, limit, setLimit, offset,onChange, fetchMethod}) => {
    const location = useLocation()
    const { UIStore } = useStore()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const query = useQuery()

    useEffect(()=>{
        const data: any = {}
        query.forEach((q, k) => {
            if(k === "group_seq"){
                data[k] = Number(q)
            } else {
                data[k] = String(q)
            }

        })
        console.log(data)
        form.setFieldsValue(data)
    },[])

    const columns: ListModel = [
        {
            title: '지점번호',
            dataIndex: 'key',
            key: 'key',
            render: (t) => (<a onClick={()=>UIStore.onMdPg({
                active: true,
                content: "branch",
                id: t
            })}>{t}</a>)
        },
        {
            title: '그룹명',
            dataIndex: 'group_name',
            key: 'group_name',
        },
        {
            title: '지점명',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: '지점연락처',
            dataIndex: 'branch_contact',
            key: 'branch_contact',
        },
        {
            title: '지점관리자',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
    ];

    const onSearch = (values: any) => {
        values.offset = 0
        for (const [key, value] of Object.entries(values)) {
            query.set(key, value as string ?? "")
        }
        const newQuery = queryAdd(query)
        console.log(newQuery)
        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }


    return (
        <ListWrap>
            <SearchWrap form={form} onFinish={onSearch}>
                <SearchRow>
                    <Form.Item name={"search_type"} initialValue={"branch_name"}>
                        <Select>
                            <Select.Option value={"branch_name"}>지점명</Select.Option>
                            <Select.Option value={"branch_contact"}>연락처</Select.Option>
                            <Select.Option value={"branch_address"}>주소</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={"search_query"}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"group_seq"} label={"소속그룹"}>
                        <GroupSelect/>
                    </Form.Item>
                    <Button htmlType={"submit"} type={"primary"}>검색</Button>
                </SearchRow>
            </SearchWrap>
            <ListHeader>
                <div>

                </div>
                <div>
                    <Button type={"primary"} onClick={()=>UIStore.onMdPg({
                        active: true,
                        content: "branch",
                        id: null
                    })}>지점추가</Button>
                </div>
            </ListHeader>
            <Table
                dataSource={data}
                columns={columns}
                pagination={{
                    pageSize: limit,
                    pageSizeOptions: LIST_CHANGE_ITEM,
                    showSizeChanger: true,
                    onShowSizeChange: (e) => setLimit && setLimit(e),
                    showTotal: (total) => `검색결과 ${toLocale(total)}건`,
                    total: total,
                }}
                onChange={onChange}
                onRow={(r) => {
                    return{
                        onClick: () => {
                            UIStore.onMdPg({
                                active: true,
                                content: "branch",
                                id: String(r.seq)
                            })
                        }
                    }
                }}
            />
        </ListWrap>
    )
}

export default BranchList