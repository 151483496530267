import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import {EmployeesList} from "../../Containers";
import {REPO} from "../../Repository/repo";
import {TablePaginationConfig} from "antd";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {BranchListModel} from "../../Model/BranchModel";
import {queryAdd} from "../../Helper/Helper";
import {DEFAULT_LIST_COUNT} from "../../Constant/Common";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../Hooks/useQuery";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {useStore} from "../../Hooks";
import DataInquiry from "../../Containers/Operate/DataInquiry";

const DataPage = () => {
    const [data, setData] = useState<EmployeesListModel[]>([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(DEFAULT_LIST_COUNT)
    const [offset, setOffset] = useState(0)
    const {UIStore} = useStore()
    const navigate = useNavigate()
    const location = useLocation()
    const query = useQuery()

    useEffect(()=>{

    },[])

    const fetchMethod = async () => {

    }


    return (
        <PageWrap>
            <h3>자료조회</h3>
            <DataInquiry/>
        </PageWrap>
    )
}

export default DataPage