import React, {ReactNode} from 'react'
import styled from "styled-components";
import {Table} from "antd";
import {Link, useLocation} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {BoardItemModel, NoticeListModel} from "../../Model/BoardModel";
import {useStore} from "../../Hooks";
import {DocumentListModel} from "../../Model/DocumentModel";

const ListWrap = styled.div`
  box-sizing: border-box;
`

const NoticeList: React.FC<{data: BoardItemModel[]}> = ({data}) => {
    const location = useLocation()
    const { UIStore } = useStore()
    const columns = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            render: (t:any) => <a>{t}</a>
        },
        {
            title: '제목',
            dataIndex: 'board_subject',
            key: 'board_subject',
        },
        {
            title: '작성자',
            dataIndex: 'board_writer_name',
            key: 'board_writer_name',
        },
    ];
    return (
        <ListWrap>
            <Table
                onRow={(r)=>{
                    return {
                        onClick: ()=>{
                            UIStore.onMdPg({
                                active: true,
                                subdata: 'notice',
                                id: String(r.seq),
                                content: "board",
                            })
                        }
                    }
                }}
                dataSource={data}
                columns={columns}
                pagination={false}
            />
        </ListWrap>
    )
}


const DocumentList: React.FC<{data: DocumentListModel[]}> = ({data}) => {
    const location = useLocation()
    const { UIStore } = useStore()
    const columns = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            render: (t:any) => <a>{t}</a>
        },
        {
            title: '지점',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: '제목',
            dataIndex: 'document_subject',
            key: 'document_subject',
        },
        {
            title: '기안자',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: '등록일',
            dataIndex: 'item_usedate',
            key: 'item_usedate',
        },
    ];

    return (
        <ListWrap>
            <Table
                onRow={(r)=>{
                    return {
                        onClick: ()=>{
                            UIStore.onMdPg({
                                active: true,
                                id: String(r.seq),
                                content: "document",
                            })
                        }
                    }
                }}
                dataSource={data}
                columns={columns}
                pagination={false}
            />
        </ListWrap>
    )
}

export {
    NoticeList,
    DocumentList
}