import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Calendar,
    DatePicker,
    DatePickerProps,
    Descriptions,
    Form,
    Input,
    message, Modal,
    Radio,
    Select,
    Spin
} from "antd";
import BranchSelect from "../../Components/BranchSelect/BranchSelect";
import LocaleInput from "../../Components/LocaleInput/LocaleInput";
import {onConfirm, onError, onSubmit, queryAdd, toLocale} from "../../Helper/Helper";
import HtmlEditor from "../../Components/HtmlEditor/HtmlEditor";
import {CustomCalendar, Uploader} from "../../Components";
import PersonSelect from "../../Components/PersonSelect/PersonSelect";
import styled from "styled-components";
import useUser from "../../Hooks/useUser";
import {useLocation, useNavigate} from "react-router-dom";
import {useStore} from "../../Hooks";
import {do_document} from "../../Model/DBModel";
import {RequesetDocumentModel} from "../../Model/DocumentModel";
import dayjs from "dayjs";
import {REPO} from "../../Repository/repo";
import {
    OperateInfoTable,
    OperateCreateSalesTable,
    OperatePurchaseTable,
    OperateInventoryTable, OperateIssueTable, OperateMonthTable
} from "./OperateCreateTables";
import {BeforeManagementModel, ManagementModel, MonthStatus, RequestManagementModel, MonthlyList} from "../../Model/OperateModel";
import {AxiosError} from "axios";
import {DefaultApiResponse} from "../../Model/Common";
import HorizontalityCalendar from "../../Components/HorizontalityCalendar/HorizontalityCalendar";
import {useQuery} from "../../Hooks/useQuery";
import OperateMonthModifyModal from "../../Components/OperateMonthModifyModal/OperateMonthModifyModal";


const { Item } = Descriptions

const CostWrap = styled.div`
    .ant-form-item{
      margin-bottom: 10px;
      label{
        width: 40px;
      }
      input{
        width: 200px;
      }
    }
`

const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
  padding-top: 15px
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  &>div:first-child{
    width: 100%;
    margin-right: 20px;
  }
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  button{
    margin-right: 10px;
    :last-child{
      margin-right: 0;
    }
  }
`

const Title = styled.h3`
`



const OperateCreate: React.FC<{}> = () => {
    const [now, setNow] = useState<DatePickerProps['value']>()
    const [form] = Form.useForm()
    const { UIStore } = useStore()
    const [originData, setOriginData] = useState<ManagementModel>();
    const [sumData, setSumData] = useState<{branch_name?: string, sale?: number, stuff?: number, count?: number}>({});
    const [activeOperate, setActiveOperate] = useState<string[]>([]);
    const [branchName, setBranchName] = useState("");
    const [data, setData] = useState<RequestManagementModel>({
        sale_list: [],
        purchase_list: [],
        inventory_list: [
            {item_name: "쌀", item_price: undefined, item_vat: 0},
            {item_name: "김치", item_price: undefined, item_vat: 0},
        ],
        report_list: [],
        // monthly_list: [],
    })
    const [isLoad, setIsLoad] = useState( true);
    const [issueSelect, setIssueSelect] = useState("고객의 소리");
    const navigator = useNavigate()
    const location = useLocation()
    const query = useQuery()
    const [dayOff, setDayOff] = useState(false)
    const [monthStatus, setMonthStatus] = useState<MonthStatus>({
        management_days_by_month: [],
        management_dayoffs_by_month: [],
        work_days_by_month: [],
        closing_begin: "",
        closing_end: "",
        monthly_list: []
    })
    const [beforeManagement, setBeforeManagement] = useState<BeforeManagementModel>({});
    const [monthModal, setMonthModal] = useState(false);

    const defaultData = useRef<RequestManagementModel>()

    const areaRef1 = useRef<HTMLDivElement>(null)

    useEffect(()=>{
        const now = query.get('now')
        setNow(now ? dayjs(now) : dayjs())
    },[query])


    useEffect(()=>{
        fetchMethod()
    },[now])

    const setSum = (data: RequestManagementModel, beforeData: BeforeManagementModel) => {
        let result: {branch_name?: string, sale?: number, purchase?:number, food_cost?: number, count?: number} = {}
        result.sale = data.sale_list.reduce((curr, prev)=>{
            return Number(curr) + (Number(prev.item_price) || 0)
        } , 0)

        let purchase = data.purchase_list.reduce((curr, prev)=>{
            return Number(curr) + (Number(prev.item_price) || 0)
        } , 0) //매입

        result.purchase = purchase

        let inventoryCost = data.inventory_list.reduce((curr, prev)=>{
            return Number(curr) + (Number(prev.item_price) || 0)
        } , 0) // 당일재고

        result.food_cost = purchase + (beforeData?.management_inventory || 0) - inventoryCost

        result.count = data.sale_list.reduce((curr, prev)=>{
            return Number(curr) + (Number(prev.item_count) || 0)
        } , 0)

        setSumData(result)
    }

    const fetchMethod = async () => {
        if(!now || !UIStore.branch?.seq){
            return
        }

        const month = await REPO.Operate.GetMonthStatus(UIStore.getTarget(), now.format("YYYY-MM-DD"), UIStore.branch.seq)
        setMonthStatus(month.data)

        try {
            const result = await REPO.Operate.GetManagement(UIStore.getTarget(), now.format("YYYY-MM-DD"), UIStore.branch.seq)
            let reqData = {
                sale_list: result.data.management.sale_list,
                purchase_list: result.data.management.purchase_list,
                monthly_list: month.data.monthly_list,
                report_list: result.data.management.report_list,
                inventory_list: result.data.management.inventory_list.length === 0 ? [
                    {item_name: "쌀", item_price: undefined, item_vat: 0},
                    {item_name: "김치", item_price: undefined, item_vat: 0},
                ] : result.data.management.inventory_list
            }
            // message.success(`${now.format("YYYY-MM-DD")} 운영일지를 불러왔습니다.`)
            setOriginData(result.data.management)
            setActiveOperate(result.data.exists_days_by_month)
            setSum(reqData, result.data?.before_management)
            setData(reqData)
            setBranchName(result.data.management.branch_name)
            setDayOff(result.data.management.management_dayoff === 1 ? true : false)
            setBeforeManagement(result.data?.before_management)
            setIsLoad(false)
        } catch (e) {
            const error = e as AxiosError<DefaultApiResponse, any>
            if(error?.response?.data.code === 1404){
                message.info("작성된 운영일지가  없습니다. 기본 설정값으로 입력됩니다.")
                const reqData = {
                    sale_list: error.response.data.extra.default_management?.sale_list || [],
                    purchase_list: error.response.data.extra.default_management?.purchase_list || [],
                    monthly_list: month.data.monthly_list,
                    report_list: error.response.data.extra.default_management?.report_list || [],
                    inventory_list: [
                        {item_name: "쌀", item_price: undefined, item_vat: 0},
                        {item_name: "김치", item_price: undefined, item_vat: 0},
                    ]
                }
                setSum(reqData, error.response.data?.extra?.before_management)
                setActiveOperate(error.response.data.extra.exists_days_by_month)
                setBranchName(error.response.data.extra.branch_name)
                setBeforeManagement(error?.response?.data?.extra?.before_management)
                setData(reqData)
                setDayOff(false)
                setIsLoad(false)
                defaultData.current = reqData
            }
        }
    }

    const onInputChange = (target: keyof Omit<RequestManagementModel, "dayoff" | "monthly_list">, targetValue: string, value: any, key: number) => {
        const newValue = [...data[target]].map((info, k) => {
            if(k === key){
                info[targetValue] = value
                info.error = false
                return info
            }
            return info
        })
        setData({
            ...data,
            [target]: newValue
        })
    }

    const saleListAuto = () => {
        const newValue = [...data["sale_list"]].map((info) => {
            info.item_price = (info.item_count || 0) * (info.item_unitcost || 0)
            info.item_vat = ((info.item_count || 0) * (info.item_unitcost || 0) * 0.1)
            return info
        })
        setData({
            ...data,
            sale_list: newValue
        })
    }

    const addItem = (target: keyof Omit<RequestManagementModel, "dayoff" | "monthly_list">, initValue?:any) => {
        const newValue = [...data[target]].concat(initValue ? initValue : {})
        setData({
            ...data,
            [target]: newValue
        })
    }

    const removeItem = (target: keyof Omit<RequestManagementModel, "dayoff" | "monthly_list">, key: number) => {
        const newValue = [...data[target]].filter((info, k) => k !== key)
        setData({
            ...data,
            [target]: newValue
        })
    }

    const monthChangeAction = (data:MonthlyList[]) => {
        onSubmit("확인", "월간 매입/매출을 수정 하시겠습니까?", async ()=> {
            try {
                const result = await REPO.Operate.PostMonthSalePurchase(UIStore.getTarget(), monthStatus.closing_begin, data, UIStore.branch?.seq || 0)
                message.success("요청이 성공적으로 완료되었습니다.")
                setMonthStatus({
                    ...monthStatus,
                    monthly_list: data
                })
                setMonthModal(false)
            } catch (e) {

            }
        })
    }


    const submitAction = () => {
        if(!now){
            return
        }

        let error = false
        let error_message = ""

        const sale_list = data.sale_list.map((info, key) => {
            if(error){ return info }

            info.item_unitcost = info.item_unitcost || 0

            if(!info.item_name){
                error = true
                error_message = "매출내역 항목을 입력해주세요"
                info.error = 0
                // areaRef1.current?.scrollIntoView()
            } else if(info.item_price !== 0 && !info.item_price){
                error = true
                error_message = "매출내역 금액을 입력해주세요"
                info.error = 3
                // areaRef1.current?.scrollIntoView()
            } else if(info.item_vat !== 0 && !info.item_vat){
                error = true
                error_message = "매출내역 VAT를 입력해주세요"
                info.error = 4
                // areaRef1.current?.scrollIntoView()
            } else {
                info.error = false
            }

            return info
        })

        const purchase_list = data.purchase_list.map((info, key) => {
            if(error){ return info }


            if(!info.item_name){
                error = true
                error_message = "매입내역 항목을 입력해주세요"
                info.error = 0
            } else if (info.item_price !== 0 && !info.item_price) {
                error = true
                error_message = "매입내역 금액을 확인해주세요"
                info.error = 1
            } else if(info.item_vat !== 0 && !info.item_vat){
                error = true
                error_message = "매입내역 VAT를 입력해주세요"
                info.error = 2
            } else {
                info.error = false
            }

            return info
        })

        const inventory_list = data.inventory_list.map((info, key) => {
            if(error){ return info }
            if((info.item_price !== 0 && !info.item_price)){
                info.item_price = 0
                // error = true
                // error_message = "재고내역 금액을 입력해주세요"
                // info.error = 0
            } else {
                // delete info.error
            }
            return info
        })

        // const monthly_list = data.monthly_list.map((info, key) => {
        //     if(error){ return info }
        //
        //     if(!info.item_name){
        //         error = true
        //         error_message = "월간 매입/매출 항목을 입력해주세요"
        //         info.error = 0
        //     } else if (info.item_price !== 0 && !info.item_price){
        //         error = true
        //         error_message = "월간 매입/매출 금액을 입력해주세요"
        //         info.error = 1
        //     } else if (info.item_vat !== 0 && !info.item_vat){
        //         error = true
        //         error_message = "월간 매입/매출 VAT를 입력해주세요"
        //         info.error = 2
        //     } else {
        //         info.error = false
        //     }
        //
        //     return info
        // })
        
        const params = {
            ...data,
            dayoff: dayOff ? 1 : 0,
            sale_list: sale_list,
            purchase_list: purchase_list,
            inventory_list: inventory_list,
            // monthly_list: monthly_list
        }

        setData(params)

        if (error) {
            return message.error(error_message)
        }
        onSubmit("확인", "일지를 등록 하시겠습니까?", async ()=> {
            try {
                const result = await REPO.Operate.PostManagement(UIStore.getTarget(), now.format("YYYY-MM-DD") || "", params, UIStore.branch?.seq || 0)
                message.success("요청이 성공적으로 완료되었습니다.")
                fetchMethod()
            } catch (e) {

            }
        })

    }


    const dateCahngeHandler = (value: DatePickerProps['value'], target: string) => {
        if (!value) {
            return
        }

        let path = location.pathname
        query.set("now", value.format("YYYY-MM-DD"))

        if (target !== "Create") {
            path = "/Operate/Attendance"
        }

        navigator({
            pathname: path,
            search: queryAdd(query)
        }, {replace: true})

        // setNow(value)
    }

    const dateCahnge = (value: DatePickerProps['value']) => {
        if (!value) {
            return
        }

        let path = location.pathname
        query.set("now", value.format("YYYY-MM-DD"))

        navigator({
            pathname: path,
            search: queryAdd(query)
        }, {replace: true})

        // setNow(value)
    }

    const onDelete = () => {
        onConfirm("확인", `${now?.format("YYYY-MM-DD")}날짜의 일지를 삭제하시겠습니까?`, async ()=> {
            try {
                const result = await REPO.Operate.DeleteManagement(UIStore.getTarget(), now?.format("YYYY-MM-DD") || "", UIStore.branch?.seq || 0)
                message.info("삭제되었습니다.")
                fetchMethod()
            } catch (e) {

            }

        })
    }

    const onCancel = () => {
        onConfirm("확인", "변경하신 내용을 취소하시겠습니까?", ()=> {
            setData({
                sale_list: originData?.sale_list || [],
                // monthly_list: originData?.monthly_list || [],
                purchase_list: originData?.purchase_list || [],
                inventory_list: originData?.inventory_list || [],
                report_list: originData?.report_list || []
            })
            message.info("취소되었습니다.")
        })
    }
    
    const fillDayoff = () => {
        onConfirm("확인", "쉬는날을 채우시겠습니까?", async () => {
            try {
                await REPO.Operate.GetMonthFillDayOff(UIStore.getTarget(), now?.format("YYYY-MM-DD") || "", UIStore.branch?.seq || 0)
                message.info("요청이 완료되었습니다.")
                fetchMethod()
            } catch (e) {

            }
        })
    }

    // if(isLoad){
    //     return null
    // }

    return(
        <Spin spinning={isLoad}>
            <OperateMonthModifyModal
                open={monthModal}
                complete={monthChangeAction}
                close={()=>setMonthModal(false)}
                initData={monthStatus.monthly_list || []}
                monthStatus={monthStatus}
            />
            <Form form={form} onFinish={submitAction}>
                <HeaderWrap>
                    <Title>{now?.format("YYYY년 MM월")}</Title>
                    <div>
                        <Button style={{marginRight: "10px"}} onClick={()=>dateCahnge(now?.clone().subtract(1, "month"))}>이전</Button>
                        <DatePicker value={now} onChange={dateCahnge}/>
                        <Button style={{marginLeft: "10px"}} onClick={()=>dateCahnge(now?.clone().add(1, "month"))}>다음</Button>
                    </div>
                </HeaderWrap>
                <HorizontalityCalendar now={now} activePage={"Create"} dateChange={dateCahngeHandler} activeArr={monthStatus}/>
                <Button style={{marginTop: "5px"}} onClick={fillDayoff}>근태 없는 날짜 쉬는날로 채우기</Button>
                {/*<HorizontalityCalendar now={now} dateChange={dateCahngeHandler} activeArr={activeOperate}/>*/}
                <div style={{maxWidth: "680px"}}>
                    <HeaderWrap>
                        <div>
                            <Title>{now?.format("YYYY년 MM월 DD일")} 운영일지</Title>
                        </div>
                        <Button style={{marginRight: "5px"}} type={!dayOff ? "primary" : "default"} onClick={()=>{
                            // if(defaultData.current){
                            //     setData(defaultData.current)
                            // }
                            setDayOff(false)
                        }}>정상운영</Button>
                        <Button type={dayOff ? "primary" : "default"} onClick={()=> {
                            onConfirm("확인", <>변경사항이 저장되지 않습니다.<br/>쉬는날로 변경하시겠습니까?</>,()=>{
                                setData({
                                    sale_list: [],
                                    purchase_list: [],
                                    inventory_list: [
                                        {item_name: "쌀", item_price: undefined, item_vat: 0},
                                        {item_name: "김치", item_price: undefined, item_vat: 0}
                                    ],
                                    report_list: [],
                                    // monthly_list: [],
                                })
                                setDayOff(true)
                            })

                        }}>쉬는날</Button>
                        {/*<CustomCalendar now={now} activeArr={activeOperate} dateChange={dateCahngeHandler}/>*/}
                    </HeaderWrap>
                    <OperateInfoTable name={branchName} data={sumData}/>
                    {!dayOff && <Button style={{marginTop: "10px"}} onClick={() => setSum(data, beforeManagement)}>금액 새로고침</Button>}
                </div>
                {!dayOff && <>
                    <TitleWrap>
                        <Title ref={areaRef1}>1.매출내역</Title>
                        <div>
                            <Button onClick={saleListAuto}>식수 매출 자동입력</Button>
                            <Button onClick={() => addItem("sale_list")}>추가</Button>
                        </div>
                    </TitleWrap>
                    <Form.Item name={"sale_list"}>
                        <OperateCreateSalesTable onChange={onInputChange} data={data.sale_list}
                                                 removeItem={removeItem}/>
                    </Form.Item>
                    <TitleWrap>
                        <Title>2.매입내역</Title>
                        <Button onClick={() => addItem("purchase_list")}>추가</Button>
                    </TitleWrap>
                    <Form.Item name={"purchase_list"}>
                        <OperatePurchaseTable onChange={onInputChange} data={data.purchase_list}
                                              removeItem={removeItem}/>
                    </Form.Item>
                    <TitleWrap>
                        <Title>3.재고내역</Title>
                    </TitleWrap>
                    <div style={{width: "350px", textAlign: 'right'}}>
                        <OperateInventoryTable onChange={onInputChange} data={data.inventory_list}
                                               removeItem={removeItem}/>
                        {beforeManagement?.management_inventory !== 0 && !beforeManagement?.management_inventory ? "" : <div>전일재고합계: <b>{toLocale(beforeManagement?.management_inventory)}</b>원 ({beforeManagement?.management_date})</div> }
                    </div>
                    <TitleWrap>
                        <Title>4.특이사항</Title>
                        <div>
                            <Select value={issueSelect} onChange={v => setIssueSelect(v)}
                                    style={{width: "200px", marginRight: "10px"}}>
                                <Select.Option value={"고객의 소리"}>고객의 소리</Select.Option>
                                <Select.Option value={"조치사항"}>조치사항</Select.Option>
                                <Select.Option value={"기타"}>기타</Select.Option>
                            </Select>
                            <Button onClick={() => addItem("report_list", {item_name: issueSelect})}>추가</Button>
                        </div>
                    </TitleWrap>
                    <OperateIssueTable onChange={onInputChange} data={data.report_list} removeItem={removeItem}/>
                    <>
                        <TitleWrap>
                            <Title>5.월간 매입/매출</Title>
                            <div>
                                <Button onClick={()=>setMonthModal(true)}>수정</Button>
                                {/*<Button onClick={() => addItem("monthly_list", {item_type: "매출"})}>매출 추가</Button>*/}
                                {/*<Button onClick={() => addItem("monthly_list", {item_type: "매입"})}>매입 추가</Button>*/}
                            </div>
                        </TitleWrap>
                        <OperateMonthTable noModify onChange={onInputChange} data={monthStatus.monthly_list} removeItem={removeItem}/>
                        <span>월간 항목은 {dayjs(monthStatus.closing_begin)?.format("MM/DD")} ~ {dayjs(monthStatus.closing_end)?.format("MM/DD")} 기간동안 일할 적용됩니다.</span>
                    </>
                </>}
                <BottomWrap>
                    <div>
                        {originData && <Button type={"primary"} onClick={onDelete} danger>일지삭제</Button>}
                    </div>
                    <div>
                        <Button type={"primary"} style={{marginRight: "5px"}} onClick={submitAction}>저장</Button>
                        <Button onClick={onCancel}>취소</Button>
                    </div>
                </BottomWrap>
            </Form>

        </Spin>
    )
}

export default OperateCreate