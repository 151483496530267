import React, {useEffect, useRef, useState} from 'react'
import styled from "styled-components";
import Logo from "../../Components/Logo/Logo";
import {Button, Descriptions, Form, Input, InputRef, message, Modal} from "antd";
import {LockOutlined, UserOutlined, SettingOutlined} from "@ant-design/icons";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";
import {useForm} from "antd/es/form/Form";
import {RequestAuthModel} from "../../Model/AuthModel";
import {REPO} from "../../Repository/repo";
import useAuth from "../../Hooks/useAuth";
import dayjs from "dayjs";
import {onConfirm} from "../../Helper/Helper";

const LoginFormWrap = styled.div`
  position: relative;
  min-width: 400px;
  height: 330px;
  background: #099268;
  background: linear-gradient(0deg, #0ca678 0%, #087f5b 40%);
  text-align: center;
  padding: 25px; 
  box-sizing: border-box;
  border-radius: 0px 70px 0 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
`

const InputForm = styled(Form)`
  width: 100%;
  .ant-form-item{
    margin-bottom: 15px;
  }
  .site-form-item-icon svg{
    fill: #ccc !important;
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
  border-radius: 0;
  height: 45px;
  font-weight: bold;
`

const Footer = styled.div`
  height: 25%;
  ul{
    position: absolute;
    white-space: nowrap;
    margin-top: auto;
    bottom: 0;
    right: 0;
    display: flex;
    font-size: 0.85em;
    color: #fff;
    li{
      margin-right: 10px;
      .site-form-item-icon svg{
        fill: #fff !important;
      }
    }
  }
`

const FlexWrap = styled.div`
  display: flex;
`

const Count = styled.div`
  color: red;
`

const Counter: React.FC<{initCount: number}> = ({initCount}) => {
    const [count, setCount] = useState(initCount);

    let minutes = Math.floor((count / 10 / 60) % 60)
    let seconds = Math.floor((count) % 60)

    useEffect(()=>{
        setCount(initCount)
    },[initCount])

    useEffect(() => {
        const id = setInterval(() => {
            setCount(count => count - 1);
        }, 1000);
        if(count === 0){
            clearInterval(id);
        }
        return () => clearInterval(id);
    }, [count]);

    let secText = String(seconds).length === 1 ? `0${seconds}` : seconds
    let minText = String(minutes).length === 1 ? `0${minutes}` : minutes

    if(!count){
        return null
    }

    return <Count>{`${minText}:${secText}`}</Count>;
}
const LoginForm: React.FC<{}> = () => {
    const [form] = useForm()
    const [changeForm] = useForm()
    const [modal, setModal] = useState<boolean>(false)
    const [time, setTiem] = useState<number>(0)
    const [requestId, setRequestId] = useState<number>()
    const { signIn } = useAuth()

    const onSubmit = async (values: any) => {
        const params = values as RequestAuthModel
        params.grant_type = "login"
        signIn(params)
    }

    const onAuthCode = async () => {
        const value = changeForm.getFieldsValue()
        try {
            const result = await REPO.Auth.AuthCode(value.id)
            setRequestId(result.data.request_id)
            setTiem(1800)
        } catch (e) {

        }
    }

    const onPasswordChange = () => {
        const value = changeForm.getFieldsValue()

        if(!requestId) {
            message.error("인증정보가 없습니다.")
            inputRef1.current?.focus()
            return
        }

        if(!value.authcode) {
            message.error("인증번호를 입력해주세요.")
            inputRef2.current?.focus()
            return
        }
        if(!value.new_password) {
            message.error("새로운 비밀번호를 입력해 주세요.")
            inputRef3.current?.focus()
            return
        }

        onConfirm("확인", "비밀번호를 변경하시겠습니까?", async() => {
            try {
                const result = await REPO.Auth.AuthPassword(requestId || 0, value.authcode, value.new_password)
                setRequestId(undefined)
                changeForm.resetFields()
                setModal(false)
            } catch (e) {

            }
        })

    }

    const inputRef1 = useRef<InputRef>(null)
    const inputRef2 = useRef<InputRef>(null)
    const inputRef3 = useRef<InputRef>(null)

    return(
        <LoginFormWrap>
            <Modal
                open={modal}
                onCancel={()=>{
                    setRequestId(undefined)
                    changeForm.resetFields()
                    setModal(false)
                }}
                title={"비밀번호 재설정"}
                width={"600px"}
                onOk={onPasswordChange}
            >
                <Form form={changeForm}>
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label={"휴대폰 번호"}>
                            <FlexWrap>
                                <Form.Item name={"id"}>
                                    <PhoneInput ref={inputRef1} placeholder={"휴대폰 번호"} />
                                </Form.Item>
                                <Button style={{marginLeft: "5px"}} onClick={onAuthCode}>인증번호 발송</Button>
                            </FlexWrap>
                        </Descriptions.Item>
                        <Descriptions.Item label={"인증 번호"}>
                            <Form.Item name={"authcode"}>
                                <Input ref={inputRef2} style={{width: "300px"}} placeholder={"인증 번호"} suffix={<Counter initCount={time}/>}/>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label={"신규 비밀번호"}>
                            <Form.Item name={"new_password"}>
                                <Input ref={inputRef3} type={"password"} placeholder={"비밀번호"} />
                            </Form.Item>
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
                <span>* 비밀번호는 6자 이상 입력하세요.</span>
            </Modal>
            <Logo style={{marginTop: "0px", marginBottom: "20px"}}/>
            <InputForm form={form} onFinish={onSubmit}>
                <Form.Item name={"phone"}>
                    <PhoneInput placeholder={"아이디"} prefix={<UserOutlined className="site-form-item-icon" />}/>
                </Form.Item>
                <Form.Item name={"passcode"}>
                    <Input type={"password"} placeholder={"비밀번호"} prefix={<LockOutlined className="site-form-item-icon" />}/>
                </Form.Item>
                <SubmitButton htmlType={"submit"}>로그인</SubmitButton>
            </InputForm>
            <Footer>
                <ul>
                    {/*<li><SettingOutlined className="site-form-item-icon"/> 설정</li>*/}
                    <li onClick={()=>setModal(true)} style={{cursor: "pointer"}}><UserOutlined className="site-form-item-icon" />계정찾기</li>
                </ul>
            </Footer>
        </LoginFormWrap>
    )
}

export default LoginForm