import React from 'react'
import {PageWrap} from "../../Components";
import MonthlyDetail from "../../Containers/Monthly/MonthlyDetail";

const MonthlyCreatePage = () => {
    return (
        <PageWrap>
            <h3>결산 작성</h3>
            <MonthlyDetail/>
        </PageWrap>
    )
}

export default MonthlyCreatePage