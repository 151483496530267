import React, {LegacyRef, RefObject, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Badge, Button, Card, Col, DatePicker, Descriptions, Form, Input, Row, Select, Spin} from "antd";
import {Uploader} from "../../Components";
import {encode_queryAdd, onConfirm, onError, onSubmit, queryAdd} from "../../Helper/Helper";
import {EmployeeDetailModel} from "../../Model/EmployeesModel";
import {useQuery} from "../../Hooks/useQuery";
import {useStore} from "../../Hooks";
import {DocumentDetailModel} from "../../Model/DocumentModel";
import {REPO} from "../../Repository/repo";
import dayjs from "dayjs";
import {cyan, green, magenta, red, yellow} from "@ant-design/colors";
import {useLocation, useNavigate} from "react-router-dom";
import {Axios, AxiosError} from "axios";
import {DefaultApiResponse} from "../../Model/Common";
import ReactDOM from "react-dom/client";
import {findDOMNode} from "react-dom";
import {PrinterFilled, PrinterOutlined} from "@ant-design/icons";
import CommentBox from "../../Components/Comment/CommentBox";

const { Item } = Descriptions

const CostWrap = styled.div`
    .ant-form-item{
      margin-bottom: 10px;
      label{
        width: 40px;
      }
      input{
        width: 200px;
      }
    }
`

const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
`


const ApprovalCreate:React.FC<{id?: number | string | null, wrapRef: RefObject<HTMLDivElement>}> = ({id,wrapRef}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<DocumentDetailModel>()
    const query = useQuery()
    const location = useLocation()
    const { UIStore } = useStore()
    const [form] = Form.useForm()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(()=>{
        fetchMethod()
    },[])

    const approvalAction = async (action:string) => {
        const mdInfo = JSON.parse(query.get("mdPg") || "{}")
        await REPO.Document.DocumentAction(UIStore.getTarget(), mdInfo.id, "approval", action)
        fetchMethod()
        wrapRef.current?.scrollIntoView()
    }

    const remittanceAction = async (action:string) => {
        const mdInfo = JSON.parse(query.get("mdPg") || "{}")
        await REPO.Document.DocumentAction(UIStore.getTarget(), mdInfo.id, "remittance", action)
        fetchMethod()
        wrapRef.current?.scrollIntoView()
    }

    const documentDelete = async () => {
        const mdInfo = JSON.parse(query.get("mdPg") || "{}")
        await REPO.Document.DocumentRemove(UIStore.getTarget(), mdInfo.id)
        UIStore.closeMdPg()
    }

    const fetchMethod = async () => {
        try {
            setIsLoading(true)
            const mdInfo = JSON.parse(query.get("mdPg") || "{}")
            const result = await REPO.Document.DocumentDetail(UIStore.getTarget(), mdInfo.id)
            setData(result.data)
            setIsLoading(false)
            form.setFieldsValue(result.data.document)
        } catch (e: any) {
            if(e.response.status === 400){
                if(e.response.data.code === 1404){
                    onError("확인", e.response.data.message, ()=>window.history.back())
                }
            }
        }
    }

    const printAction = () => {
        // const head = window.document.head.innerHTML
        // let container = document.createElement("div")
        // container.className = "print_container"
        // let printContents = ref.current?.innerHTML || ""
        // container.innerHTML = printContents + data?.document.document_text
        const queryString = encode_queryAdd(query)
        const windowObject = window.open(`/Print${queryString}`);

    }

    return(
        <div ref={ref}>
            <Spin spinning={isLoading}>
                <Form form={form}>
                    <div>
                        <Row>
                            <Col>
                                <h1>{data?.document.seq}번 {data?.document.document_type}</h1>
                                <Badge color={
                                    data?.document.document_approval == '요청중' ? yellow[6] :
                                    data?.document.document_approval == '승인됨' ? cyan[5] :
                                    data?.document.document_approval == '반려됨' ? red[3] : ""
                                } status={"success"} count={data?.document.document_approval} />
                                &nbsp;
                                <Badge color={green[6]} count={data?.document.document_remittance} />
                            </Col>
                            <Col flex="auto">
                                <Row justify="end">
                                {data?.document_person.map((row) => (
                                    <Col span={4}>
                                    <Card title={row.person_type} bordered={true} size={"small"}>
                                        <b>
                                        {row.employee_name}
                                        &nbsp;-&nbsp;
                                        {row.person_action ?? "요청중"}
                                        </b>
                                        <sub style={{"display": "block"}}>
                                            {row.action_date}
                                        </sub>
                                    </Card>
                                    </Col>
                                 ))}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <Descriptions column={2} bordered>
                        <Item label={"지점"}>
                            {data?.document.branch_name}
                        </Item>
                        <Item label={"기안자"}>
                            {data?.document.employee_name}
                            ({data?.document.document_date})
                        </Item>
                        <Item label={"유형"}>
                            {data?.document.document_type}
                        </Item>
                        <Item label={"품목"}>
                            {data?.document.item_type}
                        </Item>
                        <Item label={"사용날짜"}>
                            {data?.document.item_usedate}
                        </Item>
                        <Item label={"결제방법"}>
                            {data?.document.item_payment}
                        </Item>
                        <Item label={"금액"} span={2}>
                            <b>합계: { Intl.NumberFormat().format(Number(data?.document.item_taxfree) + Number(data?.document.item_price) + Number(data?.document.item_vat)) }</b>
                            <br />
                            - 면세: {Intl.NumberFormat().format(Number(data?.document.item_taxfree))}
                            <br />
                            - 과세: {Intl.NumberFormat().format(Number(data?.document.item_price))}
                            <br />
                            - VAT: {Intl.NumberFormat().format(Number(data?.document.item_vat))}
                        </Item>
                        <Item label={"제목"} span={2}>
                            <Form.Item name={"document_subject"}>
                                {data?.document.document_subject}
                            </Form.Item>
                        </Item>

                        <Item label={"내용"} className={"print_hidden"} span={2} >
                            <Form.Item name={"document_text"} className={data?.document?.document_type === "송금신청서" ? "document_description" : "document_ex_description"}>
                                {<div dangerouslySetInnerHTML={{__html: String(data?.document.document_text)}}></div>}
                            </Form.Item>
                        </Item>
                        <Item className={"print_hidden"} label={"첨부파일"} span={2}>
                            <Form.Item  name={"document_extra_attach_file"}>
                                <Uploader buttonHidden={true} showRemoveIcon={false}/>
                            </Form.Item>
                        </Item>
                        <Item className={"print_hidden"} label={"댓글"} span={2}>
                            {data && <CommentBox tableRow={`do_document:${data?.document.seq}`} />}
                        </Item>
                    </Descriptions>

                    <div className={"print_only"}>
                        {<div dangerouslySetInnerHTML={{__html: String(data?.document.document_text)}}></div>}
                    </div>

                    <BottomWrap className={"print_hidden"}>
                        <div>
                            <Button type={"primary"} style={{marginRight: "5px"}} onClick={()=>onSubmit("승인", "승인 하시겠습니까?", ()=>{ approvalAction("승인") })}>승인</Button>
                            <Button style={{marginRight: "5px"}} onClick={()=>onSubmit("반려", "반려 하시겠습니까?", ()=>{
                                approvalAction("반려")
                            })}>반려</Button>
                            <Button style={{marginRight: "5px"}} onClick={()=> remittanceAction("송금완료") }>송금완료</Button>
                            <Button style={{marginRight: "5px"}} onClick={()=> remittanceAction("") }>송금대기</Button>
                            <Button onClick={ printAction } icon={<PrinterOutlined/>}>인쇄하기</Button>
                        </div>
                        <div>
                            <Button style={{marginRight: "5px"}} onClick={()=>onSubmit("삭제", "결재 문서를 삭제하시겠습니까?", ()=>{ documentDelete() })}>삭제</Button>
                            {data?.is_update_permit ? <Button style={{marginRight: "5px"}} onClick={() => UIStore.onMdPg({
                                active: true,
                                content: "documentUpdate",
                                id: String(data?.document.seq) || null,
                            })}>수정</Button> : null}
                            {/*<Button style={{marginRight: "5px"}}>복제</Button>*/}
                            <Button onClick={UIStore.closeMdPg}>목록</Button>
                        </div>
                    </BottomWrap>
                </Form>
            </Spin>
        </div>
    )
}

export default ApprovalCreate