import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Descriptions, Form, Input, message, Radio, Select, Spin} from "antd";
import Item = Descriptions.Item;
import Uploader from "../../Components/Uploader/Uploader";
import HtmlEditor from "../../Components/HtmlEditor/HtmlEditor";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import MultipleEmployeeSearch from "../EmployeesSearch/MultipleEmploySearch";
import {onConfirm} from "../../Helper/Helper";
import {REPO} from "../../Repository/repo";
import {useStore} from "../../Hooks";
import {FileModel} from "../../Model/Common";
import {useQuery} from "../../Hooks/useQuery";

const Wrap = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`
const BottomWrap = styled.div`
  text-align: right;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
    button {
        margin: 0 5px;
    }
`

const SendMessage:React.FC<{id?: number | string | null, boardType:string | null}> = ({id,boardType}) => {
    const { UIStore } = useStore()
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const query = useQuery()
    const [employeeSeqList, setEmployeeSeqList] = useState<number[]>([]);

    const userSelect = (user: EmployeesListModel[])=>{
        const selectedSeq = user.map((e)=> e.seq)
        const selectedName = user.map((e)=> e.employee_name).filter((name): name is string => name !== undefined)
        form.setFieldsValue({ employee_seq_list: selectedName });
        setEmployeeSeqList(selectedSeq);
        setModalOpen(false)
    }
    // const submitAction = (values: MessageSendRequestModel)=>{
    const submitAction = (values:any)=>{
        console.log(values, 'values')
        if(!values.message_subject){
            message.error("제목 항목을 입력해 주세요.")
            return
        }
        if(!values.message_text){
            message.error("내용을 입력해 주세요.")
            return
        }
        if(employeeSeqList.length === 0 ){
            message.error("받는사람을 선택해 주세요.")
            return
        }

        const params = {
            ...values,
            employee_seq_list: employeeSeqList,
        };
        const attachFiles = values.message_extra_attach_file?.map((info: FileModel) => info.url || "");

        if (attachFiles && attachFiles.length > 0) {
            params.message_extra_attach_file = attachFiles as FileModel[];
        }else{
            params.message_extra_attach_file = []
        }
        onConfirm("확인","입력한 내용으로 발송하시겠습니까?", async () => {
            try {
                const result = await REPO.Message.PostMessage(params)
                UIStore.closeMdPg()
                message.success("발송이 완료되었습니다.")
            } catch (e) {
            }
        })
    }

    const onCancel = () => {
        onConfirm("확인", "정말로 취소하시겠습니까?", ()=> {
            message.info("취소되었습니다.")
            UIStore.closeMdPg()
        })
    }

    const fetchMethod = async ()=>{
        try{
            setIsLoading(true)
            const mdInfo = JSON.parse(query.get("mdPg") || "{}")
            const result = await REPO.Message.GetDetail(mdInfo.id)

            if(boardType === 'reply'){
                setEmployeeSeqList([result.data.message.message_sender_seq])
                form.setFieldsValue({
                    employee_seq_list: [result.data.message.message_sender_name], // 받는 사람 리스트
                    message_subject: 're: '
                })
            }else if(boardType === 'transmission'){
                form.setFieldsValue({
                    message_extra_attach_file: result.data.message.message_extra_attach_file,
                    message_subject: result.data.message.message_subject,
                    message_text: result.data.message.message_text,
                })
            }
            setIsLoading(false)
        }catch (e){
            console.log(e)
        }
    }

    useEffect(() => {
        if(id){
            fetchMethod()
        }
    }, []);

    return(
        <>
            {/*<Form<MessageSendRequestModel> form={form} onFinish={submitAction}>*/}
            <Wrap form={form} onFinish={submitAction}>
                <Spin spinning={isLoading}>
                    <MultipleEmployeeSearch active={modalOpen} close={()=> setModalOpen(false)} onSelect={userSelect} filter={{'status': 3}}/>
                    <Descriptions column={2} bordered>
                        <Item label={"받는사람"} span={2}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Form.Item name={"employee_seq_list"} style={{width: "100%", marginRight: "10px"}}>
                                    <Input readOnly disabled/>
                                </Form.Item>
                                <Button onClick={()=>setModalOpen(true)}>직원선택</Button>
                            </div>
                        </Item>
                        <Item label={"첨부파일"} span={2}>
                            <Form.Item name={"message_extra_attach_file"}>
                                <Uploader maxCount={5}/>
                            </Form.Item>
                            <div style={{textAlign: "right"}}>
                                <span>(업로드 가능 파일 jpg,gif,png,hwp,doc,docx,xls,xlsx,ppt,pptx,txt / 최대 크기 2MB)</span>
                            </div>

                        </Item>
                        <Item label={"제목"} span={2}>
                            <Form.Item name={"message_subject"} >
                                <Input/>
                            </Form.Item>
                        </Item>
                        <Item label={"내용"} span={2}>
                            <Form.Item name={"message_text"} >
                                <HtmlEditor/>
                            </Form.Item>
                        </Item>
                    </Descriptions>
                    <BottomWrap>
                        <Button htmlType={"submit"} type={"primary"}>등록</Button>
                        <Button onClick={onCancel}>취소</Button>
                    </BottomWrap>
                </Spin>
            </Wrap>
            {/*</Form>*/}
        </>
    )
}

export default SendMessage;