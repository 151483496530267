import React from "react";
import {useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../Constant/QueryKeys";
import {REPO} from "../Repository/repo";
import {UserInfoModel} from "../Model/UserModel";
import {DepartmentItemModel} from "../Model/DepartmentModel";
import {BranchListModel} from "../Model/BranchModel";


const useGroup = () => {
    const queryClient = useQueryClient();

    const groupData = useQuery(queryKeys.group, async ()=>{
        const result = await REPO.Branch.GetGroupList({offset:0, limit: 100})
        return result.data
    })


    function updateGroupData(newUser: BranchListModel[]): void {
        queryClient.setQueryData(queryKeys.group, newUser)
    }

    function clearGroupData() {
        queryClient.setQueryData(queryKeys.group, null)
    }

    return {
        groupData,
        updateGroupData,
        clearGroupData
    }

}


export default useGroup
