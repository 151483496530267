import {ColumnsType, ColumnType} from "antd/es/table";
import {InputRef, Modal, Select} from "antd";
import React, {ReactNode} from "react";
import {ModalPage} from "../Model/UIModel";
import {router} from "../router";
import {IndexRouteObject, RouteObject} from "react-router-dom";
import {DocumentType} from "../Model/DocumentModel";

export function addColumn<T>(params: ColumnType<T>): ColumnType<T> {
    return params
}

export function onConfirm(title: string, content: ReactNode, onOk: () => void) {
    Modal.confirm({
        title: title,
        content: content,
        onOk: onOk
    })
}

export function onSubmit(title: string, content: ReactNode, onOk: () => void) {
    Modal.confirm({
        title: title,
        content: content,
        onOk: onOk
    })
}

export function onError(title: string, content: ReactNode, onOk?: () => void) {
    Modal.warning({
        title: title,
        content: content,
        onOk: onOk
    })
}

export function mdPg(params: ModalPage) {
    return `&mdPg=${encodeURIComponent(JSON.stringify(params))}`
}


export const phoneWithHyphen = (phoneNumber: string) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    if (number.length < 4) return number;
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

export function getLocalStorage<T>(key: string): T | undefined {
    try{
        const value = window.localStorage.getItem(key) as string
        if(!value){
            throw "not value"
        }
        return JSON.parse(value) as T
    } catch (e) {
        return undefined
    }
}

export function setLocalStorage<T>(key: string, value?: T) {
    try{
        const temp = JSON.stringify(value)
        window.localStorage.setItem(key, temp)
    } catch (e) {
        return undefined
    }
}

export function queryAdd(data: URLSearchParams){
    let newQuery = "?"
    data?.forEach((q:string, k:string) => newQuery = newQuery + `&${k}=${q}`)
    return newQuery
}

export function encode_queryAdd(data: URLSearchParams){
    let newQuery = "?"
    data?.forEach((q:string, k:string) => newQuery = newQuery + `&${k}=${encodeURIComponent(q)}`)
    return newQuery
}

export const onlyNumber = (str: any) => {
    if(!str){
        return null
    }

    const temp = str.replace(/[^0-9-.]/g, "");
    const value = temp.match(/-?[0-9]+\.?[0-9]*/)
    // console.log(value, 'match')
    if(value === ""){
        return null
    }
    if(value) {
        return value[0]
    } else {
        return temp
    }

    // return str.replace(/[^0-9.]/g, "").replace(/(\.*)\./g, "$1");
};

export function toLocale(value?: number | string, maximumFractionDigits: number = 2): string {
    if(value !== 0 && !value){
        return ""
    }
    const str = String(value)
    let minimumFractionDigits = 0

    if(str.indexOf(".") !== -1){
        minimumFractionDigits = str.split(".")[1] ? (str.split(".")[1].length) : 0
    }


    const result = value.toLocaleString(undefined, {minimumFractionDigits: minimumFractionDigits > 2 ? 2 : minimumFractionDigits, maximumFractionDigits: maximumFractionDigits})

    return result
}

export const excelDownload = (data?:string, name?:string) => {
    const blob = new Blob([data || ""], {type: "application/vnd.ms-excel"})

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}`);
    document.body.appendChild(link);
    link.click();
}

export const onFocus = (ref: React.MutableRefObject<any>) => {
    setTimeout(() => {
        ref.current?.focus()
    }, 300)
}

export const documentType = (type: string) => {
    switch (type){
        case "Remittance":
            return "송금신청서"
        case "Expenses":
            return "경비지출"
        case "Draft":
            return "기안서"
        case "Official":
            return "공문"
    }
}

export const documentTypeIsKey = (type: string): DocumentType => {
    switch (type){
        case "송금신청서":
            return "Remittance"
        case "경비지출":
            return "Expenses"
        case "기안서":
            return "Draft"
        case "공문":
            return "Official"
        //@todo 임시기본값
        default: return "Draft"
    }
}

export const boardTypeText = (type: string) => {
    switch (type){
        case "notice":
            return "공지사항"
        case "carte":
            return "식단표"
        case "document":
            return "자료실"
    }
}

export function getRouterPath (path: string): RouteObject[] {
    const routes = router.routes[0]?.children
    const pathArr = path.split("/")

    let depps1 = routes?.find(info => info.path === pathArr[1]) as RouteObject
    let depps2 = depps1?.children?.find(info => info.path === pathArr[2]) as RouteObject

    return [depps1, depps2]
}

export const getMessageListType = (path : string)=> {
    switch(path){
        case 'Reception':
            return 'inbox'
        case 'Outgoing':
            return 'sentbox'
        default:
            return
    }
}