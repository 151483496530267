import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {mdPg, toLocale} from "../../Helper/Helper";
import dayjs from "dayjs";
import {ColumnsType} from "antd/es/table";
import {Button, Form, Input, Modal, Table} from "antd";
import LocaleInput from "../../Components/LocaleInput/LocaleInput";
import {ResponseMonthlyModel} from "../../Model/MonthlyModel";
import StateInput from "../../Components/StateInput/StateInput";

interface Props {
    env: any;
    onChange: (target: any, targetValue: string, value: any, key: number) => void,
    onItemRemove?: (target: any, key: number) => void,
    data: any[]
    modify?: boolean
    // removeItem: (target: any, key: number) => void
}


const MonthlySalesTable: React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '구분*',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "center",
            render: (t, r, i) => !props.modify ? t : (
                <StateInput state={props.data[i]["error"] === 0} value={props.data[i]["item_name"]} onChange={(e)=>props?.onChange("sale", "item_name", e.target.value, i)}/>
            )
        },
        {
            title: '식수',
            dataIndex: 'item_count',
            key: 'item_count',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) : (
                <LocaleInput value={props.data[i]["item_count"]} onChange={(e)=>props?.onChange("sale", "item_count", e, i)}/>
            )
        },
        {
            title: '단가',
            dataIndex: 'item_unitcost',
            key: 'item_unitcost',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) :  (
                <LocaleInput value={props.data[i]["item_unitcost"]} onChange={(e)=>props?.onChange("sale", "item_unitcost", e, i)}/>
            )
        },
        {
            title: '매출원가*',
            dataIndex: 'item_price',
            key: 'item_price',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) :  (
                <LocaleInput state={props.data[i]["error"] === 3} value={props.data[i]["item_price"]} onChange={(e)=>props?.onChange("sale", "item_price", e, i)}/>
            )
        },

        {
            title: 'VAT*',
            dataIndex: 'item_vat',
            key: 'item_vat',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) : (
                <LocaleInput state={props.data[i]["error"] === 4} value={props.data[i]["item_vat"]} onChange={(e)=>props?.onChange("sale", "item_vat", e, i)}/>
            )
        },
        {
            title: '합계',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (t, r, i) => {
                let temp = ((Number(r.item_price) || 0) + (Number(r.item_vat) || 0))
                return <>{toLocale(temp)}</>
            }
        },
        {
            title: '비고',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            render: (t, r, i) => !props.modify ? t :  (
                <Input value={props.data[i]["item_etc"]} onChange={(e)=>props?.onChange("sale", "item_etc", e.target.value, i)}/>
            )
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}

const MonthlyPurchaseTable: React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '구매처 (품목)*',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "center",
            render: (t, r, i) => !props.modify ? t : (
                <StateInput state={props.data[i]["error"] === 0} value={props.data[i]["item_name"]} onChange={(e)=>props?.onChange("purchase", "item_name", e.target.value, i)}/>
            )
        },
        {
            title: '매입원가',
            dataIndex: 'item_primecost',
            key: 'item_primecost',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) : (
                <LocaleInput value={props.data[i]["item_primecost"]} onChange={(e)=>props?.onChange("purchase", "item_primecost", e, i)}/>
            )
        },
        {
            title: '공급가액*',
            dataIndex: 'item_price',
            key: 'item_price',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) : (
                <LocaleInput state={props.data[i]["error"] === 2} value={props.data[i]["item_price"]} onChange={(e)=>props?.onChange("purchase", "item_price", e, i)}/>
            )

        },

        {
            title: 'VAT*',
            dataIndex: 'item_vat',
            key: 'item_vat',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) :  (
                <LocaleInput state={props.data[i]["error"] === 3} value={props.data[i]["item_vat"]} onChange={(e)=>props?.onChange("purchase", "item_vat", e, i)}/>
            )
        },
        {
            title: "합계",
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            render: (t, r, i) => {
                let temp = ((Number(r.item_price) || 0) + (Number(r.item_vat) || 0))
                return <>{toLocale(temp)}</>
            }
        },
        {
            title: "비고",
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            render: (t, r, i) => !props.modify ? t :  (
                <Input value={props.data[i]["item_etc"]} onChange={(e)=>props?.onChange("purchase", "item_etc", e.target.value, i)}/>
            )
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}


const MonthlyManTable:React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '성명',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "center",
            render: (t, r, i) => !props.modify || props.data[i].employee_seq ? toLocale(t) :  (
                <Input value={props.data[i]["item_name"]} onChange={(e)=>props?.onChange("pay", "item_name", e.target.value, i)}/>
            )
        },
        {
            title: '급여',
            dataIndex: 'item_base_pay',
            key: 'item_base_pay',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) :  (
                <LocaleInput value={props.data[i]["item_base_pay"]} onChange={(e)=>props?.onChange("pay", "item_base_pay", e, i)}/>
            )
        },
        {
            title: '연장수당',
            dataIndex: 'item_overtime_pay',
            key: 'item_overtime_pay',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) :  (
                <LocaleInput value={props.data[i]["item_overtime_pay"]} onChange={(e)=>props?.onChange("pay", "item_overtime_pay", e, i)}/>
            )
        },
        {
            title: '기본합계',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (t, r, i) => {
                let temp = ((Number(r.item_base_pay) || 0) + (Number(r.item_overtime_pay) || 0))
                return <>{toLocale(temp)}</>
            }
        },
        {
            title: '퇴충,4대',
            dataIndex: 'item_insurance_pay',
            key: 'item_insurance_pay',
            align: "center",
            render: (t, r, i) => !props.modify ? toLocale(t) : (
              <>
                <LocaleInput value={props.data[i]["item_insurance_pay"]} onChange={(e)=>props?.onChange("pay", "item_insurance_pay", e, i)}/>
                <Button onClick={() => props?.onChange('pay', 'item_insurance_pay',
                  Math.floor( ((props.data[i].item_base_pay || 0) + (props.data[i].item_overtime_pay || 0)) * (props.env.퇴충_4대보험_요율 / 100) )
                  , i)}>재계산입력</Button>
              </>
            )
        },
        {
            title: '급여계',
            dataIndex: 'total_all',
            key: 'total_all',
            align: "center",
            render: (t, r, ) => {
                let temp = (Number(r.item_base_pay) + Number(r.item_overtime_pay) + Number(r.item_insurance_pay))
                return <>{toLocale(temp)}</>
            }
        },
        {
            title: '입사일',
            dataIndex: 'employee_begin',
            key: 'employee_begin',
            align: "center",
        },
        {
            title: '퇴사일',
            dataIndex: 'employee_end',
            key: 'employee_end',
            align: "center",
        },
        {
            title: '',
            dataIndex: 'item_detail',
            key: 'item_detail',
            align: "center",
            render: (t,r,i) => {
                if (! props.data[i].employee_seq) {
                    if (props.modify) {
                        return <>
                            <Button onClick={() => {
                                if (props?.onItemRemove) props?.onItemRemove("pay", i)
                            }} danger>삭제</Button>
                        </>
                    }

                    return '-';
                }

                return (
                    <>
                        <Button onClick={()=>Modal.info({
                            icon: null,
                            title: "근무내용",
                            content: !props.modify
                              ? (<div style={{whiteSpace: "pre"}}>{t}</div>)
                              : (
                                <Input.TextArea
                                    style={{width: "450px", height: "200px", resize: "none"}}
                                    defaultValue={props.data[i]["item_detail"]}
                                    onChange={(e)=>props?.onChange("pay", "item_detail", e.target.value, i)}>
                                </Input.TextArea>
                            )
                        })}>근무내용</Button>
                    </>
                )
            }
        },
    ];

    return(
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}

const MonthlyCostTable:React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '번호',
            dataIndex: 'document_seq',
            key: 'document_seq',
            align: "center",

        },
        {
            title: '품목',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "center",
        },
        {
            title: '원가',
            dataIndex: 'item_price',
            key: 'item_price',
            align: "center",
            render: (t) => toLocale(t)
        },
        {
            title: '면세',
            dataIndex: 'item_taxfree',
            key: 'item_taxfree',
            align: "center",
            render: (t) => toLocale(t)
        },
        {
            title: 'VAT',
            dataIndex: 'item_vat',
            key: 'item_vat',
            align: "center",
            render: (t) => toLocale(t)
        },

        {
            title: '합계',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            render: (t, r) => {
                let temp = (Number(r.item_price) || 0) + (Number(r.item_taxfree) || 0) + (Number(r.item_vat) || 0)
                return toLocale(temp)
            }
        },
        {
            title: '비고',
            dataIndex: 'item_etc',
            key: 'item_etc',
            align: "center",
        },
    ];

    return(
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}

function percent(num: number, total: number): string {
    const result = num/total * 100
    return `${toLocale(result)}%`
}

const MonthlySumTable:React.FC<{data: ResponseMonthlyModel, env:any, modify: boolean}> = ({data, env, modify}) => {
    type ListModel = ColumnsType<any>
    const [saleTotal, setSaleTotal] = useState(0);
    const [purchaseTotal, setPurchaseTotal] = useState(0);
    const [foodcostTotal, setFoodcostTotal] = useState(0);
    const [payTotal, setPayTotal] = useState(0);
    const [expense, setExpenseTotal] = useState(0);
    const [managemenTotal, setManagementTotal] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(()=>{
        valueChange()

    },[data])

    const valueChange = () => {
        let saleTotal = data.sale?.reduce((prev, curr)=> {
            return prev + (Number(curr?.item_price) || 0)
        }, 0)

        let purchaseTotal = data.purchase?.reduce((prev, curr)=> {
            if(curr.item_name === "전월재고"){
                return prev
            }
            if(curr.item_name === "당월재고"){
                return prev
            }
            return prev + (Number(curr?.item_price) || 0)
        }, 0)

        let foodcostTotla = data.purchase?.reduce((prev, curr)=> {
            return prev + (Number(curr?.item_price) || 0)
        }, 0)

        let payTotal = data.pay?.reduce((prev, curr)=> {
            return prev + (Number(curr?.item_base_pay) || 0) + (Number(curr?.item_insurance_pay) || 0) + (Number(curr?.item_overtime_pay)
                || 0)
        }, 0)

        let expense = data.expense?.reduce((prev, curr)=> {
            return prev + (Number(curr?.item_price) || 0) + (Number(curr?.item_taxfree) || 0)
        }, 0)

        let managemenTotal = (Number(saleTotal) || 0) * ((data.admin_ratio || 0)/100)

        let total = (Number(saleTotal) || 0) - ((Number(purchaseTotal) || 0) + (Number(payTotal) || 0) + (Number(expense) || 0) + (Number(managemenTotal) || 0))

        setSaleTotal(saleTotal || 0)
        setPurchaseTotal(purchaseTotal || 0)
        setFoodcostTotal(foodcostTotla || 0)
        setExpenseTotal(expense || 0)
        setPayTotal(payTotal || 0)
        setManagementTotal(managemenTotal || 0)
        setTotal(total)
    }

    let columns: ListModel = [
        {
            title: '매출',
            dataIndex: 'saleTotal',
            key: 'saleTotal',
            align: "center",
            render: (t, r, i) => {
                if(i === 0){
                    return  !modify ? toLocale(data?.closing_sale) : toLocale(saleTotal)
                } else {
                    return "100%"
                }
            }
        },
        {
            title: '매입',
            dataIndex: 'purchaseTotal',
            key: 'purchaseTotal',
            align: "center",
            render: (t, r, i) => {
                if(i === 0){
                    return !modify ? toLocale(data?.closing_purchase) :  toLocale(purchaseTotal)
                } else {
                    return !modify ? percent(data?.closing_purchase || 0, data?.closing_sale || 0) : percent(purchaseTotal || 0, saleTotal || 0)
                }
            }
        },
        {
            title: '재료비',
            dataIndex: 'foodcostTotla',
            key: 'foodcostTotla',
            align: "center",
            render: (t, r, i) => {
                if(i === 0){
                    return !modify ? toLocale(data?.closing_foodcost) : toLocale(foodcostTotal)
                } else {
                    return !modify ? percent(data?.closing_foodcost || 0, data?.closing_sale || 0) : percent(foodcostTotal || 0, saleTotal || 0)
                }
            }
        },
        {
            title: '인건비',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            render: (t, r, i) => {
                if(i === 0){
                    return !modify ? toLocale(data?.closing_pay) : toLocale(payTotal)
                } else {
                    return !modify ? percent(data?.closing_pay || 0, data?.closing_sale || 0) : percent(payTotal || 0, saleTotal || 0)
                }
            }
        },
        {
            title: '경비',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            render: (t, r, i) => {
                if(i === 0){
                    return !modify ? toLocale(data?.closing_expense) : toLocale(expense)
                } else {
                    return !modify ? percent(data?.closing_expense || 0, data?.closing_sale || 0) :  percent(expense || 0, saleTotal || 0)
                }
            }
        },
        {
            title: '관리비',
            dataIndex: 'management',
            key: 'management',
            align: "center",
            render: (t, r, i) => {
                if(i === 0){
                    return !modify ? toLocale(data?.closing_admin) : toLocale(managemenTotal)
                } else {
                    return !modify ? percent(data?.closing_admin || 0, data?.closing_sale || 0) :  percent(managemenTotal || 0, saleTotal || 0)
                }
            }
        },
        {
            title: '합계',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (t, r, i) => {
                let originTotal = (Number(data?.closing_sale) || 0) - ((Number(data?.closing_purchase) || 0) + (Number(data?.closing_pay) || 0) + (Number(data?.closing_expense) || 0) + (Number(data?.closing_admin) || 0))
                if(i === 0){
                    return !modify ? toLocale(originTotal) : toLocale(total)
                } else {
                    return !modify ? percent(Number(originTotal) || 0, Number(saleTotal) || 0) : percent(Number(total) || 0, Number(saleTotal) || 0)
                }
            }
        },
        {
            title: '운영일수',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            onCell: (_, index) => index === 0 ? {rowSpan: 2} : {rowSpan: 0},
            render: (t, r, i)=> toLocale(data.closing_business_day)
        },
        {
            title: '총식수',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            onCell: (_, index) => index === 0 ? {rowSpan: 2} : {rowSpan: 0},
            render: (t, r, i)=> toLocale(data.closing_total_count)
        },
    ];

    return(
        <>
            <Table
                columns={columns}
                dataSource={[{}, {}]}
                bordered
                pagination={false}
            />
        </>
    )
}



export {
    MonthlyPurchaseTable,
    MonthlySalesTable,
    MonthlyManTable,
    MonthlyCostTable,
    MonthlySumTable
}