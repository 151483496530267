import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../../Components";
import {GroupList} from "../../../Containers";
import {REPO} from "../../../Repository/repo";
import {GroupListModel} from "../../../Model/BranchModel";
import {DEFAULT_LIST_COUNT} from "../../../Constant/Common";
import {TablePaginationConfig} from "antd";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";
import {onConfirm, queryAdd} from "../../../Helper/Helper";

const GroupListPage = () => {
    const [data, setData] = useState<GroupListModel[]>([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(DEFAULT_LIST_COUNT)
    const [offset, setOffset] = useState(0)
    const navigate = useNavigate()
    const location = useLocation()
    const query = useQuery()

    useEffect(()=>{
        fetchMethod()
    },[location])

    const fetchMethod = async () => {
        try{
            const result = await REPO.Branch.GetGroupList({
                offset: Number(query.get("offset")),
                limit: Number(query.get("limit") || limit)
            })
            setData(result.data.list.map(info => ({...info, key: info.seq})))
            setTotal(result.data.total)
            setLimit(result.data.limit)
            setOffset(result.data.offset)
        } catch (e) {

        }
    }

    const setShowState = async (seq_arr: number[], state: 0 | 1) => {
        // onConfirm("확인", `상태를 ${state? "공개" : "비공개"} 로 변경하시겠습니까?`,async ()=>{
        //     try {
        //         await REPO.Branch.GroupShowChagne(seq_arr, state)
        //         fetchMethod()
        //     } catch (e) {
        //
        //     }
        // })
        try {
            await REPO.Branch.GroupShowChagne(seq_arr, state)
            fetchMethod()
        } catch (e) {

        }
    }

    const pageChangeHandler = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<GroupListModel>) => {
        if(pagination.current && pagination.pageSize){
            query.set("offset", String((pagination.current-1) * pagination?.pageSize))
            query.set("limit", String(pagination.pageSize))
            const newQuery = queryAdd(query)
            navigate({
                pathname: location.pathname,
                search: newQuery
            })
        }
    };

    return (
        <PageWrap>
            <h3>그룹목록</h3>
            <GroupList data={data} total={total} setLimit={setLimit} offset={offset} limit={limit} onChange={pageChangeHandler} changeShow={setShowState} fetchMethod={fetchMethod}/>
        </PageWrap>
    )
}

export default GroupListPage