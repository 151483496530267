import { createGlobalStyle } from 'styled-components'

export const theme = {
    backgroundLightGray: "#F3F6F9",
    textGray: "#868e96"
}

//@todo print 하드코딩 제거
const GlobalStyle = createGlobalStyle`

  html{
    height: 100%;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
  }

    @media print {
      .print_button {
        display: none;
      }
    }

    .print_only{
      display: none;
    }
    
    .print_container{
      background-color: #fff !important;
      width: 100%;
      height: 100%;
      overflow: auto;
      .print_hidden{
        display: none;
      }

      .print_only{
        display: block;
      }
      
      .ant-descriptions-row{
        
        th, td{
          padding: 5px !important;
        }
      }
      
      .ant-card{
        white-space: nowrap;
        width: auto;
      }

      figure.table{
        padding: 0;
        margin: 0;
        table{
          border-collapse : collapse;
          //border: 1px solid #ccc;
          border-spacing: 0;
          margin: 0;
          width: 100%;
          table-layout: auto !important;
          
          td{
            border : 1px solid #ced4da;
            padding: 5px;
            //width: auto !important;
            //white-space: nowrap;
          }

        }
      }
    }
    
    body{
      height: 100%;
      min-width: 1000px;
    }
    
    #root{
      height: 100%;
    }
    
    ul{
      list-style: none;
    }
    
    input{
      font-size: 0.9em;
    }
    
    .document_description{
       img{
        width: 100%;
       }
      figure.table{
        padding: 0;
        margin: 0;
        table{
          border-collapse : collapse;
          //border: 1px solid #ccc;
          border-spacing: 0;
          margin: 0;
          width: 100%;
          table-layout: auto !important;
          text-align: center;
          td{
            border : 1px solid #ced4da;
            padding: 5px;
            //width: auto !important;
            //white-space: nowrap;
          }

          tr:first-child{
            font-weight: bold;
            background-color: #f8f9fa;
            width: 100%;
          }
        }
      }
    }

    .document_ex_description{
      figure.table{
        padding: 0;
        margin: 0;
        table{
          border-collapse : collapse;
          //border: 1px solid #ccc;
          border-spacing: 0;
          margin: 0;
          width: 100%;
          table-layout: auto !important;
          //text-align: center;
          td{
            border : 1px solid #ced4da;
            padding: 5px;
            //width: auto !important;
            //white-space: nowrap;
          }

          tr:first-child{
            width: 100%;
          }
        }
      }
    }
   

    .ant-form-item{
      margin-bottom: 0;
    }
    
    .ant-descriptions-item-label{
      width:20% !important;
      max-width: 195px !important;
      min-width: 195px !important;
      font-weight: bold !important;
    }

    .ant-descriptions-item-content{
      width: 30% !important;
    }
    
    .ant-descriptions{
      margin: 20px 0;
      :first-child{
        margin-top: 0;
      }

      
    }


`

export default GlobalStyle