import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../../Components";
import {BranchList} from "../../../Containers";
import {BranchListModel, GroupListModel} from "../../../Model/BranchModel";
import {DEFAULT_LIST_COUNT} from "../../../Constant/Common";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";
import {REPO} from "../../../Repository/repo";
import {TablePaginationConfig} from "antd";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {queryAdd} from "../../../Helper/Helper";

const BranchListPage = () => {
    const [data, setData] = useState<BranchListModel[]>([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(DEFAULT_LIST_COUNT)
    const [offset, setOffset] = useState(0)
    const navigate = useNavigate()
    const location = useLocation()
    const query = useQuery()

    useEffect(()=>{
        fetchMethod()
    },[location])

    const fetchMethod = async () => {
        try{
            const result = await REPO.Branch.GetBranchList({
                offset: Number(query.get("offset")),
                limit: Number(query.get("limit") || limit),
                search_query: query.get("search_query") || "",
                search_type: query.get("search_type") || "",
                group_seq: Number(query.get("group_seq")) || undefined,

            })
            setData(result.data.list.map(info => ({...info, key: info.seq})))
            setTotal(result.data.total)
            setLimit(result.data.limit)
            setOffset(result.data.offset)
        } catch (e) {

        }
    }

    const pageChangeHandler = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<BranchListModel>) => {
        if(pagination.current && pagination.pageSize){
            query.set("offset", String((pagination.current-1) * pagination?.pageSize))
            query.set("limit", String(pagination.pageSize))
            const newQuery = queryAdd(query)
            navigate({
                pathname: location.pathname,
                search: newQuery
            })
        }
    };

    return (
        <PageWrap>
            <h3>지점목록</h3>
            <BranchList
                data={data}
                total={total}
                offset={offset}
                limit={limit}
                setLimit={setLimit}
                onChange={pageChangeHandler}
                fetchMethod={fetchMethod}
            />
        </PageWrap>
    )
}

export default BranchListPage