import React from 'react'
import {Link} from "react-router-dom";
import {mdPg, toLocale} from "../../Helper/Helper";
import dayjs from "dayjs";
import {ColumnsType} from "antd/es/table";
import {Button, Form, Input, Table} from "antd";
import LocaleInput from "../../Components/LocaleInput/LocaleInput";
import {ExpensItem, PurchaseListModel, SaleListModel} from "../../Model/OperateModel";
import {useStore} from "../../Hooks";

interface Props {

}


const OperateSalesTable: React.FC<{data: SaleListModel[]}> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '구분',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "center",
            render: (t) => t
        },
        {
            title: '식수',
            dataIndex: 'item_count',
            key: 'item_count',
            align: "center",
            render: (t) => toLocale(t)
        },
        {
            title: '기준단가',
            dataIndex: 'item_unitcost',
            key: 'item_unitcost',
            align: "center",
            render: (t) => toLocale(t)
        },
        {
            title: '매출',
            dataIndex: 'item_price',
            key: 'item_price',
            align: "center",
            render: (t) => toLocale(t)
        },
        {
            title: 'VAT',
            dataIndex: 'item_vat',
            key: 'item_vat',
            align: "center",
            render: (t) => toLocale(t)
        },
        {
            title: '합계',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (t, r) => {
                let temp = 0
                temp = temp + Number(r.item_price||0) + Number(r.item_vat||0)
                return toLocale(temp)
            }
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}


const OperateIngredientsTable: React.FC<{data: PurchaseListModel[]}> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '품목',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "center",
        },
        {
            title: '공급가액',
            dataIndex: 'item_price',
            key: 'item_price',
            align: "center",
            render: (t) => toLocale(t)
        },
        {
            title: 'VAT',
            dataIndex: 'item_vat',
            key: 'item_vat',
            align: "center",
            render: (t) => toLocale(t)
        },
        {
            title: '합계',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (t, r) => {
                let temp = 0
                temp = temp + Number(r.item_price||0) + Number(r.item_vat||0)
                return toLocale(temp)
            }
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}

const OperateApprovalsTable: React.FC<{data: ExpensItem[]}> = (props) => {
    type ListModel = ColumnsType<any>
    const { UIStore } = useStore()
    let columns: ListModel = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            align: "center",
        },
        {
            title: '제목',
            dataIndex: 'document_subject',
            key: 'document_subject',
            align: "center",
            render: (t,r) => (<a onClick={()=>UIStore.onMdPg({
                active: true,
                content: "document",
                id: r.seq,
            }, "/Approval/Manage")}>{t}</a>)
        },
        {
            title: <>금액<br/>(과세/면세/VAT)</>,
            dataIndex: 'item_price',
            key: 'item_price',
            align: "center",
            render: (t,r) => <>
                {r.item_price ? "과세: " + Intl.NumberFormat().format(r.item_price) : ""}
                {r.item_taxfree ? " / 면세: " + Intl.NumberFormat().format(r.item_taxfree) : ""}
                {r.item_vat ? " / VAT: " + Intl.NumberFormat().format(r.item_vat) : ""}
            </>
        },
        {
            title: '합계',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            render: (t,r) => Intl.NumberFormat().format(r.item_price + r.item_taxfree + r.item_vat)
        },
        {
            title: '사용날짜',
            dataIndex: 'item_usedate',
            key: 'item_usedate',
            align: "center",
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}

            />
        </>
    )
}



export {
    OperateSalesTable,
    OperateIngredientsTable,
    OperateApprovalsTable,
}