import React, {useEffect, useState} from 'react'
import {Button, Descriptions, Form, Input, Select, Spin} from "antd";
import {Link} from "react-router-dom";
import {EmployeeSearch, EmployeesList} from "../index";
import styled from "styled-components";
import {onConfirm, onError, onSubmit} from "../../Helper/Helper";
import {useStore} from "../../Hooks";
import {REPO} from "../../Repository/repo";
import {useQuery} from "../../Hooks/useQuery";
import {GroupDetailModel, GroupListModel, RequestGroupModel} from "../../Model/BranchModel";
import {GroupSelect, Uploader} from "../../Components";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {BoardDetailModel} from "../../Model/BoardModel";

const { Item } = Descriptions

const Wrap = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BottomWrap = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
`

const BoardDetail:React.FC<{id?: number | string | null, boardType:string}> = ({id, boardType}) => {
    const [data, setData] = useState<BoardDetailModel>()
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    // const [groupList, setGroupList] = useState<GroupListModel[]>([])
    const query = useQuery()

    const { UIStore } = useStore()

    // const [form] = Form.useForm()

    const fetchMethod = async () => {
        try {
            setIsLoading(true)
            const mdInfo = JSON.parse(query.get("mdPg") || "{}")
            // const groupList = await REPO.Branch.GetGroupList({
            //     offset: 0,
            //     limit: 100,
            // })
            const result = await REPO.Board.GetDetail(boardType, mdInfo.id)
            setData(result.data.board)
            setIsLoading(false)
        } catch (e) {

        }
    }

    useEffect(()=>{
        fetchMethod()
    },[])

    const submitAction = (values: any) => {

    }

    const deleteAction = () => {
        onConfirm("확인","게시글을 삭제하시겠습니까?",  async() => {
            try {
                const result = await REPO.Board.Delete(boardType, String(data?.seq))
                UIStore.closeMdPg()
            } catch (e) {

            }
        })
    }



    return(
        <Wrap onFinish={submitAction}>
            <Spin spinning={isLoading}>
                <Descriptions column={2} bordered>
                    <Item label={"작성자"}>
                        {data?.board_writer_name}
                    </Item>
                    <Item label={"등록일"}>
                        {data?.board_write_date}
                    </Item>
                    <Item label={"조회수"}>
                        {data?.board_count_view}
                    </Item>
                    <Item label={"수정일"}>
                        {data?.board_modify_date}
                    </Item>
                    <Item label={"첨부파일"} span={2}>
                        {data?.board_extra_attach_file[0] && <Uploader showRemoveIcon={false} buttonHidden value={[data?.board_extra_attach_file[0]]}/>}
                        {data?.board_extra_attach_file[1] && <Uploader showRemoveIcon={false} buttonHidden value={[data?.board_extra_attach_file[1]]}/>}
                        {data?.board_extra_attach_file[2] && <Uploader showRemoveIcon={false} buttonHidden value={[data?.board_extra_attach_file[2]]}/>}
                        {data?.board_extra_attach_file[3] && <Uploader showRemoveIcon={false} buttonHidden value={[data?.board_extra_attach_file[3]]}/>}
                        {data?.board_extra_attach_file[4] && <Uploader showRemoveIcon={false} buttonHidden value={[data?.board_extra_attach_file[4]]}/>}
                    </Item>
                    <Item label={"제목"} span={2}>
                        {data?.board_subject}
                    </Item>
                    <Item label={"내용"} span={2}>
                        <div style={{minHeight: "300px"}} className={"document_description"}>
                            {<div dangerouslySetInnerHTML={{__html: String(data?.board_text)}}></div>}
                        </div>
                    </Item>
                </Descriptions>
            </Spin>
            <BottomWrap>
                <div>
                    <Button type={"primary"} onClick={deleteAction} danger>삭제</Button>
                </div>
                <div>
                    <Button type={"primary"} style={{marginRight: "5px"}} onClick={()=>UIStore.onMdPg({
                        active: true,
                        content: "boardUpdate",
                        id: String(data?.seq) || null,
                        subdata: boardType
                    })}>수정</Button>
                    <Button onClick={UIStore.closeMdPg}>목록으로</Button>
                </div>
            </BottomWrap>
        </Wrap>
    )
}

export default BoardDetail