import React, {useEffect} from "react";
import styled from "styled-components";
import LoginForm from "../../Containers/LoginForm/LoginForm";
import {useNavigate, useRoutes} from "react-router-dom";
import {router} from "../../router";

const IntroWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginPageWrap = styled.div`
  width: 900px;
  height: 650px;
  display: flex;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.1);
`

const LoginPage = () => {

    return(
        <IntroWrap>
            <LoginForm/>
        </IntroWrap>
    )
}

export default LoginPage