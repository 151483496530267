import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Form, Input, message, Select, Table} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {SearchWrap} from "../../Components";
import {ListProps} from '../../Model/Common'

import {MessageListModel} from "../../Model/MessageModel";
import {LIST_CHANGE_ITEM} from "../../Constant/Common";
import {onConfirm, queryAdd, toLocale} from "../../Helper/Helper";
import {useQuery} from "../../Hooks/useQuery";
import {useStore} from "../../Hooks";
import {REPO} from "../../Repository/repo";

const ListWrap = styled.div`
  box-sizing: border-box;
`

const ListHeader = styled.div`
    text-align: right;
    margin-bottom: 10px;
  button {
    margin: 0 5px;
  }
`

const SearchRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  :first-child{
    margin-bottom: 10px;  
  }
`
const SearchBoxWrap = styled.div`
  display: flex;
  align-items: center;
`

type ListModel = ColumnsType<any>

interface Props extends ListProps {
    data?: MessageListModel[]
}

const MessageList: React.FC<Props> = ({data, limit, onChange, total, setLimit}) => {
    const location = useLocation()
    const [form] = Form.useForm()
    const { UIStore } = useStore()
    const query = useQuery()
    const navigate = useNavigate()
    const pathname = location.pathname.split('/')[3]

    let columns: ListModel = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            align: "center",
            render: (t) => (
                <a onClick={()=> UIStore.onMdPg({
                    active: true,
                    content: 'message',
                    id: t
                })}>{t}</a>
            )
        },
        {
            title: '제목',
            dataIndex: 'message_subject',
            key: 'message_subject',
            align: "center",
        },
        {
            title: '보낸사람',
            dataIndex: 'message_sender_name',
            key: 'message_sender_name',
            align: "center",
        },
        {
            title: '발송일',
            dataIndex: 'message_date',
            key: 'message_date',
            align: "center",
        },
        {
            title: '열람',
            dataIndex: 'message_confirm',
            key: 'message_confirm',
            align: "center",
            render: (t, r) => <>{t? 'O' : ''}</>
        },
    ];

    const onSearch = (values: any)=>{
        const params = values
        for (const [key, value] of Object.entries(params)) {
            query.set(key, value as string || "")
        }
        const newQuery = queryAdd(query)
        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }

    const systemDeleteAction = async () =>{
        onConfirm("확인","시스템 쪽지를 일괄 삭제하시겠습니까?", async () => {
            const params = 2 // 2:SYSTEM
            try {
                const result = await REPO.Message.DeleteSystemMessage(params)
                UIStore.closeMdPg()
                message.success("쪽지가 삭제되었습니다.")
            } catch (e) {
            }
        })
    }

    useEffect(() => {
        if (location.pathname === '/MyPage/Message') {
            navigate('/MyPage/Message/Reception', { replace: true });
        }
    }, [location.pathname, navigate]);

    useEffect(() => {
        form.setFieldsValue({
            search_query: ''
        })
    }, [pathname]);

    return(
      <ListWrap>
          <ListHeader>
              <Button
                  type={location.pathname === '/MyPage/Message/Reception' ? 'primary' : 'default'}
                  onClick={() => navigate('/MyPage/Message/Reception')}
              >
                  수신목록
              </Button>
              <Button
                  type={location.pathname === '/MyPage/Message/Outgoing' ? 'primary' : 'default'}
                  onClick={() => navigate('/MyPage/Message/Outgoing')}
              >
                  발송목록
              </Button>
              <Button
                  type={location.pathname === '/MyPage/Message/Write' ? 'primary' : 'default'}
                  onClick={()=>{
                      UIStore.onMdPg({
                      active: true,
                      content: 'sendMessage',
                      id: null,
                  })}}
              >
                  신규작성
              </Button>
          </ListHeader>
          <SearchWrap form={form} onFinish={onSearch}>
              <SearchRow>
                  {pathname === 'Reception' ?
                  <div>
                    <Button onClick={systemDeleteAction}>SYSTEM 쪽지 일괄삭제</Button>
                  </div>
                      :
                      <div></div>
                  }
                  <SearchBoxWrap>
                      <label style={{alignItems: 'center'}}>검색</label>
                          <Form.Item name={"search_type"} initialValue={"message_subject"}>
                              <Select>
                                  <Select.Option value={"message_subject"}>제목</Select.Option>
                                  <Select.Option value={"message_sender_name"}>보낸사람</Select.Option>
                                  <Select.Option value={"message_text"}>내용</Select.Option>
                              </Select>
                          </Form.Item>
                      <Form.Item name={"search_query"}>
                          <Input/>
                      </Form.Item>
                      <Button htmlType={"submit"} type={"primary"}>검색</Button>
                  </SearchBoxWrap>

              </SearchRow>
          </SearchWrap>

          <Table
              dataSource={data}
              columns={columns}
              pagination={{
                  pageSize: limit,
                  pageSizeOptions: LIST_CHANGE_ITEM,
                  showSizeChanger: true,
                  onShowSizeChange: (e) => setLimit && setLimit(e),
                  showTotal: (total) => `검색결과 ${toLocale(total)}건`,
                  total: total,
              }}
              onChange={onChange}
              onRow={(r)=> {
                  return{
                      onClick: ()=>{
                          UIStore.onMdPg({
                              active: true,
                              content: "message",
                              id: String(r.seq)
                          })
                      }
                  }
              }}
          />
      </ListWrap>
    )
}

export default MessageList;