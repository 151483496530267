import React, {useEffect, useRef, useState} from 'react'
import {Form, Input, InputRef, Modal, Select} from "antd";
import {GroupListModel} from "../../Model/BranchModel";
import {onError} from "../../Helper/Helper";
import {REPO} from "../../Repository/repo";

interface Props {
    open: boolean
    setOpen: (bool: boolean)=>void
    data: GroupListModel[]
    fetchMethod?: ()=>void
    initGroup?: number
}

const AddGroupModal: React.FC<Props> = ({open, setOpen, data, fetchMethod, initGroup}) => {
    const [form] = Form.useForm()
    const ref = useRef<InputRef>(null)

    useEffect(()=>{
        form.resetFields()
        form.setFieldsValue({
            parent_group_seq: initGroup
        })
    },[open])

    const onSubmit = async () => {
        const values = form.getFieldsValue()
        if(!values.group_name){
            onError("확인", "그룹명을 입력해 주세요", ()=> {
                setTimeout(()=>ref?.current?.focus(),300)
            })
            return
        }
        values.group_show = 1
        try {
            await REPO.Branch.GroupAdd(values)
            fetchMethod && fetchMethod()
            setOpen(false)
        } catch (e) {

        }

    }

    return(
        <Modal
            open={open}
            title={"그룹 추가"}
            onOk={onSubmit}
            onCancel={() => setOpen(false)}
        >
            <Form form={form}>
                <Form.Item label={"상위그룹"} style={{marginBottom: "10px"}} name={"parent_group_seq"}>
                    <Select placeholder={"그룹을 선택해 주세요"}>
                        {data.map((info, key) => (
                            <Select.Option value={info.seq} key={key} >{info.group_name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={"그룹명"} name={"group_name"}>
                    <Input ref={ref} placeholder={"그룹명을 입력해 주세요"} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddGroupModal