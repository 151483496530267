import React, {useEffect, useState} from 'react'
import {Button, Descriptions, Form, Input, message, Radio, Select, Spin} from "antd";
import {Link} from "react-router-dom";
import {EmployeeSearch, EmployeesList} from "../index";
import styled from "styled-components";
import {onConfirm, onError, onSubmit} from "../../Helper/Helper";
import {useStore} from "../../Hooks";
import {REPO} from "../../Repository/repo";
import {useQuery} from "../../Hooks/useQuery";
import {GroupDetailModel, GroupListModel, RequestGroupModel} from "../../Model/BranchModel";
import {GroupSelect, Uploader} from "../../Components";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import useUser from "../../Hooks/useUser";
import HtmlEditor from "../../Components/HtmlEditor/HtmlEditor";
import {BoardDetailModel} from "../../Model/BoardModel";
import {FileModel} from "../../Model/Common";

const { Item } = Descriptions

const Wrap = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BottomWrap = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
`

const BoardCreate:React.FC<{id?: number | string | null, boardType:string}> = ({id, boardType}) => {
    const [data, setData] = useState<BoardDetailModel>()
    const [isLoading, setIsLoading] = useState(false)

    const query = useQuery()
    const { userData } = useUser()
    const { UIStore } = useStore()

    const [form] = Form.useForm()

    const fetchMethod = async () => {
        const mdInfo = JSON.parse(query.get("mdPg") || "{}")
        if(!mdInfo.id){
            return
        }
        try {
            setIsLoading(true)
            const result = await REPO.Board.GetDetail(boardType, mdInfo.id)
            setData(result.data.board)

            form.setFieldsValue({
                ...result.data.board,
                board_extra_attach_file: result.data.board.board_extra_attach_file
            })
            setIsLoading(false)
        } catch (e) {

        }
    }

    useEffect(()=>{
        fetchMethod()
    },[])

    const submitAction =  (values: any) => {
        const params = values
        params.board_category = 1

        if(!params.board_subject){
            message.error("제목을 입력해 주세요.")
            return
        }

        if(!params.board_text){
            message.error("내용을 입력해 주세요.")
            return
        }

        if(params.board_hidden !== 0 && !params.board_hidden){
            message.error("공개여부를 선택해 주세요.")
            return
        }

        params.board_extra_attach_file = (params.board_extra_attach_file ?? []).map((info:FileModel) => {
            return info.url || ""
        })
        if(data?.seq){
            onConfirm("확인","게시글을 수정하시겠습니까?", async () => {
                try {
                    const result = await REPO.Board.Update(boardType, String(data.seq), params)
                    UIStore.onMdPg({
                        active: true,
                        content: "board",
                        id: String(data?.seq) || null,
                        subdata: boardType
                    })
                } catch (e) {

                }
            })
        } else {
            onConfirm("확인","게시글을 등록하시겠습니까?", async () => {
                try {
                    const result = await REPO.Board.Add(boardType, params)
                    UIStore.closeMdPg()
                } catch (e) {

                }
            })
        }


    }


    return(
        <Wrap form={form} onFinish={submitAction}>
            <Spin spinning={isLoading}>
                <Descriptions column={2} bordered>
                    <Item label={"작성자"} span={2} >
                        {userData.data?.employee_name}
                    </Item>
                    <Item label={"첨부파일"} span={2}>
                        <Form.Item name={"board_extra_attach_file"} >
                            <Uploader maxCount={5}/>
                        </Form.Item>
                    </Item>
                    {/*<Item label={"첨부파일2"} span={2}>*/}
                    {/*    <Form.Item name={["board_extra_attach_file", 1]} >*/}
                    {/*        <Uploader maxCount={1}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Item>*/}
                    <Item label={"제목"} span={2}>
                        <Form.Item name={"board_subject"} >
                            <Input/>
                        </Form.Item>
                    </Item>
                    <Item label={"내용"} span={2}>
                        <Form.Item name={"board_text"} >
                            <HtmlEditor/>
                        </Form.Item>
                    </Item>
                    <Item label={"공개여부"} span={2}>
                        <Form.Item name={"board_hidden"} >
                            <Radio.Group>
                                <Radio value={0}>공개</Radio>
                                <Radio value={1}>비공개</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Item>
                </Descriptions>
            </Spin>
            <BottomWrap>
                <div>
                </div>
                <div>
                    <Button type={"primary"} style={{marginRight: "5px"}} htmlType={"submit"} >{data?.seq ? "수정" : "등록"}</Button>
                    <Button onClick={UIStore.closeMdPg}>목록으로</Button>
                </div>
            </BottomWrap>
        </Wrap>
    )
}

export default BoardCreate