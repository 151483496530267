import React, {useEffect, useRef, useState} from 'react'
import {Button, DatePicker, Descriptions, Form, Input, InputRef, message, Select} from "antd";
import {Link} from "react-router-dom";
import {EmployeesList} from "../index";
import styled from "styled-components";
import {onConfirm, onError, onFocus, onSubmit} from "../../Helper/Helper";
import {useStore} from "../../Hooks";
import {BranchInput, Uploader} from "../../Components";
import AuthListTable from "./AuthListTable";
import BranchSelect from "../../Components/BranchSelect/BranchSelect";
import {do_branch, do_employee} from "../../Model/DBModel";
import {REPO} from "../../Repository/repo";
import dayjs from "dayjs";
import {EmployeeDetailModel} from "../../Model/EmployeesModel";
import {useQuery} from "../../Hooks/useQuery";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import {FileModel} from "../../Model/Common";
import LocaleInput from "../../Components/LocaleInput/LocaleInput";
import {DatePickRef} from "antd/es/date-picker/generatePicker/interface";

dayjs.extend(weekday)
dayjs.extend(localeData)

const { Item } = Descriptions

const Wrap = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  label{
    :first-child{
      margin-left: 0;
    }
    margin: 0 10px;
  }
`

const EmployeeDetail:React.FC<{id?: number | string | null}> = ({id}) => {
    const [mode, setMode] = useState<"modify" | "add">("add");
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<EmployeeDetailModel>()
    const query = useQuery()

    const { UIStore } = useStore()

    const [form] = Form.useForm()

    const inputRef1 = useRef<InputRef>(null)
    const inputRef2 = useRef<any>(null)
    const inputRef3 = useRef<InputRef>(null)
    const inputRef4 = useRef<InputRef>(null)
    const inputRef5 = useRef<InputRef>(null)
    const inputRef6 = useRef<InputRef>(null)
    const inputRef7 = useRef<InputRef>(null)
    const inputRef8 = useRef<any>(null)
    const inputRef9 = useRef<InputRef>(null)

    useEffect(()=>{
        if(id){
            setMode("modify")
            fetchMethod()
            // form.setFieldsValue(data)
        }
    },[])

    const deleteAction = () => {
        onConfirm("확인", "로그인 가능여부를 변경하시겠습니까?", async ()=>{
            const mdInfo = JSON.parse(query.get("mdPg") || "{}")
            const result = await REPO.Employee.EmployeeStatusChange(UIStore.getTarget(), !!data?.employee.employee_login ? 0 : 1 ,mdInfo.id)
            fetchMethod()
        })
    }

    const fetchMethod = async () => {
        try {
            setIsLoading(true)
            const mdInfo = JSON.parse(query.get("mdPg") || "{}")
            const result = await REPO.Employee.EmployeeDetail(UIStore.getTarget(),mdInfo.id)
            form.setFieldsValue({
                ...result?.data?.employee,
                employee_birthday: dayjs(result?.data?.employee.employee_birthday),
                employee_begin: dayjs(result?.data?.employee.employee_begin),
                employee_end: result?.data?.employee.employee_end ? dayjs(result?.data?.employee.employee_end) : null,
                login_password: ""
            })
            setData(result.data)
            setIsLoading(false)
        } catch (e) {

        }
    }

    const onSubmitAction = (valuse: any) => {
        const params = valuse as do_employee


        if(!params.employee_name){
            return onError("확인", "이름을 입력해주세요", ()=>onFocus(inputRef1))
        }

        if(!params.employee_birthday){
            return onError("확인", "생년월일을 입력해주세요", ()=>onFocus(inputRef2))
        }

        if(!params.employee_position){
            return onError("확인", "직급을 입력해주세요", ()=>onFocus(inputRef3))
        }

        if(!params.branch_seq){
            return onError("확인", "소속지점을 선택해주세요", ()=>onFocus(inputRef4))
        }

        if(!params.employee_phone){
            return onError("확인", "연락처를 입력해주세요", ()=>onFocus(inputRef5))
        }

        if(params.employee_monthly_pay !== 0 && !params.employee_monthly_pay){
            return onError("확인", "급여를 입력해주세요", ()=>onFocus(inputRef6))
        }

        if(params.employee_hourly_pay !== 0 && !params.employee_hourly_pay){
            return onError("확인", "시급을 입력해주세요", ()=>onFocus(inputRef7))
        }

        if(!params.employee_begin){
            return onError("확인", "입사일을 선택해주세요", ()=>onFocus(inputRef8))
        }

        if(params.login_password && params.login_password_check !== params.login_password){
            return onError("확인", "비밀번호를 확인해주세요", ()=>onFocus(inputRef9))
        }

        params.employee_extra_attach_file = params.employee_extra_attach_file?.map(info => {
            let temp = info as FileModel
            return temp.url || ""
        })
        params.employee_begin = params.employee_begin ? dayjs(params.employee_begin).format("YYYY-MM-DD") : ""
        params.employee_end = params.employee_end ? dayjs(params.employee_end).format("YYYY-MM-DD") : null
        params.employee_login = data?.employee?.employee_login || 0
        params.login_password = params.login_password ? params.login_password : ""
        params.employee_email = params.employee_email ? params.employee_email : ""
        if(!params.login_password){
            delete params?.login_password
        }
        let permission_group_seq = data?.permissions.filter(info => info.group_type === "그룹").map(info => info.group_seq)
        let permission_branch_seq = data?.permissions.filter(info => info.group_type === "지점").map(info => info.group_seq)
        if(mode === "modify") {
            onSubmit("확인", "직원정보를 수정하시겠습니까?", async ()=>{
                try{
                    const result = await REPO.Employee.EmployeeModify(UIStore.getTarget(),Number(id), {
                        employee: {
                            ...params,
                            // login_password: data?.employee.login_password || "",
                            employee_birthday: dayjs(params.employee_birthday).format("YYYY-MM-DD"),
                            employee_begin: dayjs(params.employee_begin).format("YYYY-MM-DD"),
                            employee_end: params.employee_end ? dayjs(params.employee_end).format("YYYY-MM-DD") : null,
                        },
                        permission_group_seq: permission_group_seq,
                        permission_branch_seq: permission_branch_seq
                    })
                    UIStore.closeMdPg()
                } catch (e) {

                }
            })
        } else {
            onSubmit("확인", "직원을 등록하시겠습니까?", async ()=>{
                try{
                    const result = await REPO.Employee.EmployeeAdd(UIStore.getTarget(), params)
                    UIStore.closeMdPg()
                } catch (e) {

                }

            })
        }
    }

    const newPasswordHandler = () => {
        if(!data?.employee.employee_login){
            onError("확인", "로그인 허용이 필요합니다.", ()=>{})
            return
        }

        if(!id){
            return
        }

        onConfirm("확인", <>신규 비밀번호 자동생성 후<br/> {data.employee.employee_phone}으로 문자 발송합니다.</>, async () => {
            try{
                const result = await REPO.Employee.EmployeeRenewPassword(UIStore.getTarget(), id)
                message.success(<>{data.employee.employee_name}님의 비밀번호가 발급되었습니다. </>)
            } catch (e) {

            }

        })

    }

    if(id){
        return(
            <Wrap form={form} onFinish={onSubmitAction}>
                <Descriptions column={2} bordered>
                    <Item label={"상태"}>
                        {data?.employee?.employee_end ? "퇴사" : "근무중"}
                    </Item>
                    <Item label={"로그인 가능 여부"}>
                        {data?.employee?.employee_login? "가능" : "불가"}
                    </Item>
                    <Item label={"마지막 로그인 IP"}>
                        {data?.employee?.employee_last_login_ip}
                    </Item>
                    <Item label={"마지막 로그인 시간"}>
                        {data?.employee?.employee_last_login_date}
                    </Item>
                </Descriptions>

                <Descriptions title={"기본정보"} column={2} bordered>
                    <Item label={"사원코드"}>
                        <Form.Item name={"employee_no"}>
                            <Input/>
                        </Form.Item>
                    </Item>
                    <Item label={"생년월일*"} >
                        <Form.Item name={"employee_birthday"}>
                            <DatePicker format={"YYYY-MM-DD"} ref={inputRef2}/>
                        </Form.Item>
                    </Item>
                    <Item label={"이름*"}>
                        <Form.Item name={"employee_name"}>
                            <Input ref={inputRef1}/>
                        </Form.Item>
                    </Item>
                    <Item label={"직급*"}>
                        <Form.Item name={"employee_position"}>
                            <Input ref={inputRef3}/>
                        </Form.Item>
                    </Item>
                    <Item label={"소속지점*"}>
                        <Form.Item name={"branch_seq"}>
                            <BranchSelect ref={inputRef4} allHide={true}/>
                        </Form.Item>
                    </Item>
                    <Item label={""}>

                    </Item>
                    <Item label={"연락처*"}>
                        <Form.Item name={"employee_phone"}>
                            <Input ref={inputRef5}/>
                        </Form.Item>
                    </Item>
                    <Item label={"이메일"}>
                        <Form.Item name={"employee_email"}>
                            <Input/>
                        </Form.Item>
                    </Item>
                    <Item label={"급여*"}>
                        <Form.Item name={"employee_monthly_pay"}>
                            <LocaleInput ref={inputRef6}/>
                        </Form.Item>
                    </Item>
                    <Item label={"시급*"}>
                        <Form.Item name={"employee_hourly_pay"}>
                            <LocaleInput ref={inputRef7}/>
                        </Form.Item>
                    </Item>
                    <Item label={"입사일*"}>
                        <Form.Item name={"employee_begin"}>
                            <DatePicker format={"YYYY-MM-DD"} ref={inputRef8}/>
                        </Form.Item>
                    </Item>
                    <Item label={"퇴사일"}>
                        <Form.Item name={"employee_end"}>
                            <DatePicker format={"YYYY-MM-DD"}/>
                        </Form.Item>
                    </Item>

                </Descriptions>
                <Descriptions title={"계정정보"} column={2} bordered>
                    <Item label={<>비밀번호<br/>(변경시 입력)</>}>
                        <Form.Item name={"login_password"}>
                            <Input type={"password"} ref={inputRef9} autoComplete="nope"/>
                        </Form.Item>
                    </Item>
                    <Item label={"비밀번호 확인"}>
                        <Form.Item name={"login_password_check"}>
                            <Input type={"password"} autoComplete="nope"/>
                        </Form.Item>
                    </Item>
                </Descriptions>

                <TitleWrap>
                    <h3>권한 부여된 지점/그룹</h3>
                    {/*<div>*/}
                    {/*    <BranchInput/>*/}
                    {/*    <Button>추가</Button>*/}
                    {/*</div>*/}
                </TitleWrap>
                <AuthListTable data={data?.permissions}/>
                <Descriptions title={"추가정보"}  column={1} bordered>
                    <Item label={"메모"}>
                        <Form.Item name={"employee_extra_memo"}>
                            <Input.TextArea rows={3} style={{resize: "none"}}/>
                        </Form.Item>
                    </Item>
                    <Item label={"첨부파일"}>
                        <Form.Item name={"employee_extra_attach_file"}>
                            <Uploader/>
                        </Form.Item>
                    </Item>
                </Descriptions>

                <BottomWrap>
                    <div>
                        <Button type={"primary"} onClick={deleteAction} danger={!!data?.employee.employee_login}>로그인 {!data?.employee.employee_login ? "허용" : "차단"}</Button>
                        <Button style={{marginLeft: "5px"}} onClick={newPasswordHandler}>신규 비밀번호 생성</Button>
                    </div>
                    <div>
                        <Button type={"primary"} style={{marginRight: "5px"}} htmlType={"submit"}>저장</Button>
                        <Button onClick={UIStore.closeMdPg}>목록으로</Button>
                    </div>
                </BottomWrap>
            </Wrap>
        )
    } else {
        return(
            <Wrap form={form} onFinish={onSubmitAction}>
                <Descriptions title={"기본정보"} column={2} bordered>
                    <Item label={"사원코드"}>
                        <Form.Item name={"employee_no"}>
                            <Input/>
                        </Form.Item>
                    </Item>
                    <Item label={"생년월일*"} >
                        <Form.Item name={"employee_birthday"}>
                            <DatePicker ref={inputRef2} format={"YYYY-MM-DD"}/>
                        </Form.Item>
                    </Item>
                    <Item label={"이름*"}>
                        <Form.Item name={"employee_name"}>
                            <Input ref={inputRef1}/>
                        </Form.Item>
                    </Item>
                    <Item label={"직급*"}>
                        <Form.Item name={"employee_position"}>
                            <Input ref={inputRef3}/>
                        </Form.Item>
                    </Item>
                    <Item label={"소속지점*"}>
                        <Form.Item name={"branch_seq"}>
                            <BranchSelect ref={inputRef4} allHide={true}/>
                        </Form.Item>
                    </Item>
                    <Item label={""}>

                    </Item>
                    <Item label={"연락처*"}>
                        <Form.Item name={"employee_phone"}>
                            <Input ref={inputRef5}/>
                        </Form.Item>
                    </Item>
                    <Item label={"이메일"}>
                        <Form.Item name={"employee_email"}>
                            <Input autoComplete="nope"/>
                        </Form.Item>
                    </Item>
                    <Item label={"급여*"}>
                        <Form.Item name={"employee_monthly_pay"}>
                            <LocaleInput ref={inputRef6}/>
                        </Form.Item>
                    </Item>
                    <Item label={"시급*"}>
                        <Form.Item name={"employee_hourly_pay"}>
                            <LocaleInput ref={inputRef7}/>
                        </Form.Item>
                    </Item>
                    <Item label={"입사일*"}>
                        <Form.Item name={"employee_begin"}>
                            <DatePicker ref={inputRef8} format={"YYYY-MM-DD"}/>
                        </Form.Item>
                    </Item>
                    <Item label={"퇴사일"}>
                        <Form.Item name={"employee_end"}>
                            <DatePicker format={"YYYY-MM-DD"}/>
                        </Form.Item>
                    </Item>
                </Descriptions>
                <Descriptions title={"계정정보"} column={2} bordered>
                    <Item label={<>비밀번호</>}>
                        <Form.Item name={"login_password"}>
                            <Input ref={inputRef9} type={"password"}/>
                        </Form.Item>
                    </Item>
                    <Item label={"비밀번호 확인"}>
                        <Form.Item name={"login_password_check"}>
                            <Input type={"password"}/>
                        </Form.Item>
                    </Item>
                </Descriptions>

                <BottomWrap>
                    <div>
                        {/*<Button type={"primary"} onClick={deleteAction} danger>지점삭제</Button>*/}
                    </div>
                    <div>
                        <Button type={"primary"} style={{marginRight: "5px"}} htmlType={"submit"}>저장</Button>
                        <Button onClick={UIStore.closeMdPg}>목록으로</Button>
                    </div>
                </BottomWrap>
            </Wrap>
        )
    }


}

export default EmployeeDetail