import React, {useEffect, useState} from "react";
import {OperateMonthTable} from "../../Containers/Operate/OperateCreateTables";
import dayjs from "dayjs";
import styled from "styled-components";
import {Button, message, Modal} from "antd";
import {MonthlyList, MonthStatus, RequestManagementModel} from "../../Model/OperateModel";
import {onConfirm, onSubmit} from "../../Helper/Helper";
import {REPO} from "../../Repository/repo";
import {useStore} from "../../Hooks";

const Wrap = styled.div`
  min-width: 500px;
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  button{
    margin-right: 10px;
    :last-child{
      margin-right: 0;
    }
  }
`

interface Props {
    open: boolean
    initData: MonthlyList[]
    monthStatus: MonthStatus
    complete: (data:MonthlyList[]) => void
    close: () => void
}



const OperateMonthModifyModal:React.FC<Props> = ({open, complete, close, initData, monthStatus}) => {
    const {UIStore} = useStore()
    const [data, setData] = useState<any>(initData || []);

    useEffect(()=>{
        if(initData){ setData(initData) }
    },[initData, open])

    const addItem = (initValue?:any) => {
        setData(data.concat(initValue))
    }

    const onInputChange = (_:string, targetValue: string, value: any, key: number) => {
        setData(data.map((info:any, k:number) => {
            if(k === key){
                info[targetValue] = value
            }
            return info
        }))
    }

    const removeItem = (target:string, key: number) => {
        setData(data.filter((_:any,k:number) => k !== key))
    }

    const setDefault = () => {

        const fetch = async () => {
            if (!UIStore.branch?.seq) {
                return
            }

            try {
                const result = await REPO.Operate.GetDefault(UIStore.getTarget(), UIStore.branch.seq)
                setData(result.data.default_management?.monthly_list)
            } catch (e) {

            }
        }

        if(data.length > 0){
            onConfirm("확인", "입력하신 내용을 기본값으로 덮어씁니다. 진행하시겠습니까?", () => {
                fetch()
            })
        } else {
            fetch()
        }


    }


    return (
        <Modal
            open={open}
            onCancel={close}
            title={"월간 매입/매출 수정"}
            width={"auto"}
            style={{
                maxWidth: "1000px"
            }}
            onOk={()=>{
                let error = false
                let error_message = ""

                let temp = data as MonthlyList[]

                const monthly_list = temp.map((info, key) => {
                    if(error){ return info }

                    if(!info.item_name){
                        error = true
                        error_message = "월간 매입/매출 항목을 입력해주세요"
                        info.error = 0
                    } else if (info.item_price !== 0 && !info.item_price){
                        error = true
                        error_message = "월간 매입/매출 금액을 입력해주세요"
                        info.error = 1
                    } else if (info.item_vat !== 0 && !info.item_vat){
                        error = true
                        error_message = "월간 매입/매출 VAT를 입력해주세요"
                        info.error = 2
                    } else {
                        info.error = false
                    }

                    return info
                })
                setData(monthly_list)

                if (error) {
                    return message.error(error_message)
                }

                complete(monthly_list)
            }
        }
        >
            <Wrap>
                <TitleWrap>
                    {/*<Title>5.월간 매입/매출</Title>*/}
                    <Button onClick={setDefault}>기본값 가져오기</Button>
                    <div>
                        <Button onClick={() => addItem({item_type: "매출"})}>매출 추가</Button>
                        <Button onClick={() => addItem({item_type: "매입"})}>매입 추가</Button>
                    </div>
                </TitleWrap>
                <OperateMonthTable onChange={onInputChange} data={data} removeItem={removeItem}/>
                <span>월간 항목은 {dayjs(monthStatus.closing_begin)?.format("MM/DD")} ~ {dayjs(monthStatus.closing_end)?.format("MM/DD")} 기간동안 일할 적용됩니다.</span>
            </Wrap>
        </Modal>
    )
}

export default OperateMonthModifyModal