import React, {useRef} from 'react'
import {ColumnsType} from "antd/es/table";
import {Button, Form, Input, Radio, Select, Table} from "antd";
import LocaleInput from "../../Components/LocaleInput/LocaleInput";
import styled from "styled-components";
import HorizontalityCalendar from "../../Components/HorizontalityCalendar/HorizontalityCalendar";
import dayjs from "dayjs";
import {DataInquiryModel, InquiryPurchaseModel, InquiryWorkModel} from "../../Model/OperateModel";
import {excelDownload, toLocale} from "../../Helper/Helper";
import {useQuery} from "../../Hooks/useQuery";
import RangeCalendar from "../../Components/HorizontalityCalendar/RangeCalendar";

interface Props {
    modify?: boolean
}

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  button{
    margin-right: 10px;
    :last-child{
      margin-right: 0;
    }
  }
`

const Title = styled.h3`
`

const FoodCountTable: React.FC<{branch?: string, data?: DataInquiryModel<any>}> = ({branch, data}) => {
    const query = useQuery()
    type ListModel = ColumnsType<any>
    const tableWrapRef = useRef<HTMLDivElement>(null)

    if(!data){
        return null
    }

    let columns: ListModel = [
        {
            title: '날짜',
            dataIndex: 'management_date',
            key: 'management_date',
            align: "center",
            width: "15%"
        },
        // @ts-ignore
        ...data?.columns.map((info, key) => ({
            title: info,
            dataIndex: info,
            key: key,
            align: 'center',
            render: (t: number)=>toLocale(t)
        })),

        {
            title: '합계',
            dataIndex: 'total',
            key: 'total',
            // @ts-ignore
            align: 'center',
            render: (t, r) => {
                let total = 0
                data?.columns?.forEach(info => {
                    total += Number(r[info] || 0)
                })
                return total
            }
        },

    ];
    let titleText = <>{branch} 식수표 ({dayjs(query.get("date_begin")).format("YYYY/MM/DD")} ~ {dayjs(query.get("date_end")).format("YYYY/MM/DD")})</>
    return (
        <>
            <TitleWrap>
                <Title>{titleText}</Title>
                <div>
                    <Button onClick={()=>excelDownload(tableWrapRef.current?.innerHTML, "식수표.xls")}>엑셀 다운로드</Button>
                </div>
            </TitleWrap>
            <div ref={tableWrapRef}>
                <Title style={{display: "none"}}>{titleText}</Title>
                <Table
                    columns={columns}
                    dataSource={data?.list}
                    bordered
                    pagination={false}
                />
            </div>
        </>
    )
}

const SalesTable: React.FC<{branch?: string, data?: DataInquiryModel<InquiryPurchaseModel>}> = ({branch, data}) => {
    const query = useQuery()
    type ListModel = ColumnsType<any>
    const tableWrapRef = useRef<HTMLDivElement>(null)


    let columns: ListModel = [
        {
            title: '날짜',
            dataIndex: 'management_date',
            key: 'management_date',
            align: "center",
        },
        {
            title: '매출',
            dataIndex: 'sale_price',
            key: 'sale_price',
            align: "center",
            render: (t: number)=>toLocale(t)
        },
        {
            title: '매출 VAT',
            dataIndex: 'sale_vat',
            key: 'sale_vat',
            align: "center",
            render: (t: number)=>toLocale(t)
        },
        {
            title: '매입',
            dataIndex: 'purchase_price',
            key: 'purchase_price',
            align: "center",
            render: (t: number)=>toLocale(t)
        },
        {
            title: '매입 VAT',
            dataIndex: 'purchase_vat',
            key: 'purchase_vat',
            align: "center",
            render: (t: number)=>toLocale(t)
        },
        {
            title: '재료비',
            dataIndex: 'foodcost',
            key: 'foodcost',
            align: "center",
            render: (t: number)=>toLocale(t)
        },
        {
            title: '구성비',
            dataIndex: 'ratio',
            key: 'ratio',
            align: "center",
            render: (t, r)=> {
                return ((r.foodcost ?? 0) / (r.sale_price ?? 1) * 100).toFixed(2) + '%'
            }
        },
    ];
    let titleText = <>{branch} 매입매출표 ({dayjs(query.get("date_begin")).format("YYYY/MM/DD")} ~ {dayjs(query.get("date_end")).format("YYYY/MM/DD")})</>
    return (
        <>
            <TitleWrap>
                <Title>{titleText}</Title>
                <div>
                    <Button onClick={()=>excelDownload(tableWrapRef.current?.innerHTML, "매입매출표.xls")}>엑셀 다운로드</Button>
                </div>
            </TitleWrap>
            <div ref={tableWrapRef}>
                <Title style={{display: "none"}}>{titleText}</Title>
                <Table
                    columns={columns}
                    dataSource={data?.list}
                    bordered
                    pagination={false}
                />
            </div>
        </>
    )
}

const AttendanceTable: React.FC<{branch?: string, data?: DataInquiryModel<InquiryWorkModel>}> = ({branch, data}) => {
    const query = useQuery()
    const tableWrapRef = useRef<HTMLDivElement>(null)
    if(!data){
        return null
    }
    let titleText = <>{branch} 근태내역 ({dayjs(query.get("date_begin")).format("YYYY/MM/DD")} ~ {dayjs(query.get("date_end")).format("YYYY/MM/DD")})</>
    return (
        <>
            <TitleWrap>
                <Title>{titleText}</Title>
                <div>
                    <Button onClick={()=>excelDownload(tableWrapRef.current?.innerHTML, "근태내역.xls")}>엑셀 다운로드</Button>
                </div>
            </TitleWrap>
            <div ref={tableWrapRef}>
                <Title style={{display: "none"}}>{titleText}</Title>
                {data.list?.map((info, key) => (
                    <div key={key} style={{marginBottom: "20px"}}>
                        <Title>{info.employee_name} (사원번호 {info.employee_seq})</Title>
                        <RangeCalendar workList={info.work_list} begin={query.get("date_begin") || ""} end={query.get("date_end") || ""}/>
                        <div style={{marginTop: "10px"}}>출근 {info.total.출근}일 / 결근 {info.total.결근}일 / 연차 {info.total.연차}일 / 연장근무 {info.total.연장근무} / 야간근무 {info.total.야간근무}</div>
                    </div>
                ))}
            </div>

        </>
    )
}


export {
    FoodCountTable,
    SalesTable,
    AttendanceTable,
}