import React, {CSSProperties, MutableRefObject, ReactNode, useEffect, useState} from "react";
import {Input, InputRef} from "antd";
import {phoneWithHyphen} from "../../Helper/Helper";

interface Props {
    value?: string
    onChange?: (value: string) => void
    placeholder?: string
    prefix?: ReactNode
    style?: CSSProperties
    ref?: MutableRefObject<InputRef | null>
}

const PhoneInput: React.FC<Props> = ({value, style, ref ,onChange, placeholder, prefix}) => {
    const [inputValue, setInputValue] = useState("")

    useEffect(() => {
        if(value){
            setInputValue(phoneWithHyphen(value))
        }
    },[])


    const inputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = phoneWithHyphen(e.target.value)
        setInputValue(value)
        if(onChange){
            onChange(e.target.value.replace(/[^0-9]/g, ""))
        }
    }

    return(
        <Input value={inputValue} style={{...style}} ref={ref} onChange={inputValueChange} placeholder={placeholder} prefix={prefix}/>
    )
}

export default PhoneInput