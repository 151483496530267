import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import {REPO} from "../../Repository/repo";
import {TablePaginationConfig} from "antd";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {BranchListModel} from "../../Model/BranchModel";
import {queryAdd} from "../../Helper/Helper";
import {DEFAULT_LIST_COUNT} from "../../Constant/Common";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../Hooks/useQuery";
import {useStore} from "../../Hooks";
import {DocumentListModel} from "../../Model/DocumentModel";
import DocumentsList from "../../Containers/Approval/DocumentsList";
import OperateList from "../../Containers/Operate/OperateList";
import MonthlyList from "../../Containers/Monthly/MonthlyList";

const MonthlyListPage = () => {
    const [data, setData] = useState<DocumentListModel[]>([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(DEFAULT_LIST_COUNT)
    const [offset, setOffset] = useState(0)
    const {UIStore} = useStore()
    const navigate = useNavigate()
    const location = useLocation()
    const query = useQuery()

    useEffect(()=>{
        fetchMethod()
    },[location])

    const fetchMethod = async () => {
        try{
            const result = await REPO.Closing.GetClosingList(UIStore.getTarget(),{
                offset: Number(query.get("offset")),
                limit: Number(query.get("limit") || limit),
                date_begin: query.get("date_begin") || "",
                date_end: query.get("date_end") || "",
                group_seq: Number(query.get("target")) || ""
            })
            setData(result.data.list.map(info => ({...info, key: info.seq})))
            setTotal(result.data.total)
            setLimit(result.data.limit)
            setOffset(result.data.offset)
        } catch (e) {

        }
    }

    const pageChangeHandler = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<BranchListModel>) => {
        if(pagination.current && pagination.pageSize){
            query.set("offset", String((pagination.current-1) * pagination?.pageSize))
            query.set("limit", String(pagination.pageSize))
            const newQuery = queryAdd(query)
            navigate({
                pathname: location.pathname,
                search: newQuery
            })
        }
    };

    return (
        <PageWrap>
            <h3>결산 확인</h3>
            <MonthlyList data={data} limit={limit} setLimit={setLimit} total={total} onChange={pageChangeHandler}/>
        </PageWrap>
    )
}

export default MonthlyListPage