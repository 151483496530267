import React, {useEffect, useState} from 'react'
import {Select} from "antd";
import dayjs from "dayjs";

const YearSelect: React.FC<{value: string, onChange: (value: string) => void}> = (props) => {
    const [nowYear, setNowYear] = useState<number>();


    useEffect(()=>{
        const now = dayjs()
        setNowYear(now.year())
    },[])

    return (
        <Select value={props.value} onChange={(v) => props.onChange(v)}>
            {Array(10).fill(0).map((_, key) => <Select.Option key={key} value={`${(nowYear||0) - key}`}>{(nowYear||0) - key}년</Select.Option>)}
        </Select>
    )
}

export default YearSelect