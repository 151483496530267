import React from 'react'
import {PageWrap} from "../../Components";
import AttendanceCreate from "../../Containers/Operate/AttendanceCreate";

const AttendanceCreatePage = () => {
    return (
        <PageWrap>
            <h3>근태 등록</h3>
            <AttendanceCreate/>
        </PageWrap>
    )
}

export default AttendanceCreatePage