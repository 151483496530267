import React, {CSSProperties} from 'react'

const Logo: React.FC<{style?: CSSProperties}> = ({style}) => {
    return (
        <span>
            <img src={"/images/logo.png"} style={{width: "140px", ...style}}/>
        </span>
    )
}

export default Logo