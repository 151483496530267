import React, {createContext, ReactNode, useEffect, useState} from "react";
import {useLocation, useMatches, useNavigate} from "react-router-dom";
import {getLocalStorage, mdPg, onError, queryAdd} from "../Helper/Helper";
import {ModalPage} from "../Model/UIModel";
import {useQuery} from "../Hooks/useQuery";
import {useLocalStorage} from "../Hooks/useLocalStorage";
import {ResponseTokenModel} from "../Model/AuthModel";
import {localStorageKeys} from "../Constant/LocalStorageKeys";
import {DepartmentItemModel} from "../Model/DepartmentModel";
import {useDepartment} from "../Hooks";
import useAuth from "../Hooks/useAuth";


type SetString = React.Dispatch<React.SetStateAction<string>>
type SetBoolean = React.Dispatch<React.SetStateAction<boolean>>

interface UIStore {
    routeID: string
    setRouteID: SetString
    loading: boolean
    setLoading: SetBoolean
    onMdPg: (params: ModalPage, pathName?:string) => void,
    closeMdPg: () => void,
    isSign: boolean,
    setIsSign: SetBoolean,
    branch?: DepartmentItemModel,
    setBranch: React.Dispatch<React.SetStateAction<DepartmentItemModel | undefined>>
    getTarget: ()=>string
}


interface GlobalAppConTextType {
    UIStore: UIStore
}

export const GlobalAppContext = createContext<GlobalAppConTextType>({
    UIStore: {
        routeID: "",
        setRouteID: () => {},
        loading: false,
        setLoading: () => {},
        onMdPg: () => {},
        closeMdPg: () => {},
        isSign: false,
        setIsSign: () => {},
        branch: undefined,
        setBranch: () => {},
        getTarget: () => ("")
    }
})

export const GlobalAppContextProvider:React.FC<{children?: ReactNode}> = (props) => {
    const [appInit, setAppInit] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [routeID, setRouteID] = useState("");
    const [isSign, setIsSign] = useState(false);
    const [token, setToken] = useLocalStorage<ResponseTokenModel>(localStorageKeys.tokenKey)
    const [branch, setBranch] = useState<DepartmentItemModel | undefined>()
    const [reload, setReload] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const { departmentData } = useDepartment()
    const { logOut } = useAuth()
    const query = useQuery()

    const init = async () => {
        const token = getLocalStorage(localStorageKeys.tokenKey)
        if(token){
            if(location.pathname === "/Login" || location.pathname === "/"){
                navigate("/Main", {replace: true})
            }
        } else {
            navigate("/Login", {replace: true})
        }


        const gbcode = query.get("gbcode")

        if(!departmentData.data){
            departmentData.refetch()
            return
        }

        if(!gbcode){
            query.set("gbcode", departmentData.data[0]?.gbcode)
            navigate({
                pathname: location.pathname,
                search: queryAdd(query)
            }, {replace: true})
            // @ts-ignore
            setBranch(departmentData.data[0] || {})
        } else {
            let temp = departmentData.data?.find(info => info.gbcode === gbcode)
            setBranch(temp)
        }

        if(departmentData.data.length === 0){
            onError("확인", "접근 가능한 지점이 없습니다.", ()=>{
                logOut()
            })
        }

        setAppInit(true)
    }

    useEffect(() => {
        init()
    },[departmentData])


    useEffect(()=>{
        if(branch){
            // query.set("gbcode", branch.gbcode)
            // navigate({
            //     pathname: location.pathname,
            //     search: queryAdd(query)
            // })
            setReload(true)
        }
    },[branch])

    useEffect(()=>{
        if(reload){
            setReload(false)
        }
    }, [reload])


    const onMdPg = (params: ModalPage, pathName?: string) => {
        query.delete("mdPg")

        let newQuery = "?"
        query.forEach((q, k) => newQuery = newQuery + `&${k || ""}=${q || ""}`)
        navigate({
            pathname: pathName || location.pathname,
            search: newQuery + mdPg(params)
        })
    }

    const closeMdPg = () => {
        query.delete("mdPg")
        let newQuery = "?"
        query.forEach((q, k) => newQuery = newQuery + `&${k || ""}=${q || ""}`)

        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }

    const getTarget: ()=>string = () => {
        // return "G1"
        let gbcode = branch?.gbcode

        if(!gbcode){
            return ""
        }

        return gbcode
    }

    let context:GlobalAppConTextType ={
        UIStore: {
            routeID,
            setRouteID,
            loading: isLoad,
            setLoading: setIsLoad,
            onMdPg: onMdPg,
            closeMdPg: closeMdPg,
            isSign: isSign,
            setIsSign: setIsSign,
            branch: branch,
            setBranch: setBranch,
            getTarget: getTarget
        }
    }

    if(!appInit || !branch){
        return null
    }
    return(
        <GlobalAppContext.Provider value={context}>
            {!reload && props.children}
        </GlobalAppContext.Provider>
    )
}

