import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import {ApprovalCreate} from "../../Containers";
import {Radio} from "antd";
import styled from "styled-components";
import {DocumentType} from "../../Model/DocumentModel";
import {useQuery} from "../../Hooks/useQuery";
import {useLocation, useNavigate} from "react-router-dom";
import {queryAdd} from "../../Helper/Helper";

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  h3{
    margin-bottom: 0;
    margin-right: 10px;
  }
`



const ApprovalCreatePage = () => {



    return (
        <PageWrap>
            <TitleWrap>
                <h3>전자기안 작성</h3>
            </TitleWrap>
            <ApprovalCreate id={null}/>
        </PageWrap>
    )
}

export default ApprovalCreatePage