import React, {useCallback, useEffect, useRef, useState} from "react";
import {Drawer} from "antd";
import {ModalPage} from "../../Model/UIModel";
import {useLocation, useMatches, useNavigate} from "react-router-dom";
import {useQuery} from "../../Hooks/useQuery";
import {ApprovalCreate, GroupDetail} from "../index";
import BranchDetail from "../Branch/BranchDetail";
import EmployeeDetail from "../Employees/EmployeeDetail";
import DocumentDetail from "../Approval/DocumentDetail";
import styled from "styled-components";
import MonthlyDetail from "../Monthly/MonthlyDetail";
import BoardDetail from "../Board/BoardDetail";
import BoardCreate from "../Board/BoardCreate";
import {useStore} from "../../Hooks";
import MessageDetail from "../MyPage/MessageDetail";
import SendMessage from "../MyPage/SendMessage";

const ContentWrap = styled.div`
  overflow: auto;
  min-width: 1000px;
`

const DrawerPage:React.FC<{}> = () => {
    const [mdPg, setMdPg] = useState<ModalPage>({
        active: false,
        content: null,
        id: null,
    });
    const [screen, setScreen] = useState<"pc" | "mobile">("pc");
    const match = useMatches() as Matches[]
    const query = useQuery()
    const location = useLocation()
    const navigate = useNavigate()
    const { UIStore } = useStore()
    const wrapRef = useRef<HTMLDivElement>(null)


    useEffect(()=>{
        const mdPgStirng = query.get("mdPg")
        if(mdPgStirng){
            const mdPg = JSON.parse(mdPgStirng) as ModalPage
            if(query.get("mdPg")){
                setMdPg({
                    ...mdPg
                })
            }
        } else {
            setMdPg({
                active: false,
                content: null,
                id: null
            })
        }
    }, [match])

    useEffect(()=>{
        if(mdPg.active){
            window.document.body.style.overflow = "hidden"
        } else {
            window.document.body.style.overflow = "auto"
        }
    },[mdPg.active])

    const screenChange = useCallback(()=>{
        const screenWidth = window?.innerWidth
        setScreen(767 < screenWidth ? "pc" : "mobile")
    }, [])

    useEffect(() => {
        screenChange()
        window.addEventListener("resize", screenChange)
        return () => {
            window.removeEventListener("resize", screenChange)
        }
    }, [screenChange])

    const drawerPageCloseAction = useCallback(()=>{
        UIStore.closeMdPg()
    },[query])


    let title, description

    switch (mdPg.content){
        case 'group':
            if(mdPg.id){
                title = "그룹상세"
                description = <GroupDetail id={mdPg.id}/>
            }
            break;
        
        case 'branch':
            title = mdPg.id ? "지점상세" : "지점등록"
            description = <BranchDetail id={mdPg.id}/>
            break;
            
        case 'employee':
            title = mdPg.id ? "직원상세" : "직원등록"
            description = <EmployeeDetail id={mdPg.id}/>
            break;

        case 'document':
            title = "전자결재 상세"
            description = <DocumentDetail id={mdPg.id} wrapRef={wrapRef}/>
            break;

        case 'documentUpdate':
            title = "전자결재 수정"
            description = <ApprovalCreate id={mdPg.id}/>
            break;

        case 'monthly':
            title = "월별결산 상세"
            description = <MonthlyDetail id={mdPg.id} date={mdPg.subdata}/>
            break;

        case 'board':
            title = "게시글상세"
            description = <BoardDetail id={mdPg.id} boardType={mdPg.subdata || ""}/>
            break;

        case 'boardUpdate':
            title = mdPg.id ? "게시글 수정" : "게시글 등록"
            description = <BoardCreate id={mdPg.id} boardType={mdPg.subdata || ""}/>
            break;

        case 'message':
            title = "쪽지 상세"
            description = <MessageDetail id={mdPg.id}/>
            break;

        case 'sendMessage':
            title = "쪽지 작성"
            description = <SendMessage id={mdPg.id} boardType={mdPg.subdata || ""}/>
            break;
    }

    return(
        <Drawer
            title={title}
            width={screen === "pc" ? "80%" : "100%"}
            onClose={drawerPageCloseAction}
            open={mdPg.active}
        >
            <ContentWrap ref={wrapRef}>
                {description}
            </ContentWrap>
        </Drawer>
    )
}

export default DrawerPage