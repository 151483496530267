import React from 'react'
import styled from "styled-components";
import {DatePickerProps} from "antd";
import dayjs from "dayjs";

interface Props {
    begin: string
    end: string
    workList: any[]
}

const CalenderTable = styled.table`
  text-align: center;
  border-collapse : collapse;
  border-spacing: 0;
  
  th,td{
    min-width: 35px;
    min-height: 35px;
    height: 35px;
    border : 1px solid #ced4da;
  }
  
  .blue{
    color: #4c6ef5;
  }
  
  .now{
   font-weight: bold;
  }

  .active{
    background-color: #28D583;
    color: #fff !important;
  }
  
  
  .red{ 
    color: #f03e3e;
  }
  
  th{
    background-color: #fafafa;
  }
  
  td{
    //cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.3s;
  }
`

const CalendarWrap = styled.div`
  overflow-x: auto;
  width: 100%;
`

const getRangeDate = (begin: string, end: string): any => {
    const dates = []
    let cursorDate = dayjs(begin), endDate = dayjs(end)

    if (!cursorDate || !endDate)
        return [];

    do {
        if (cursorDate > endDate) {
            return dates
        }

        dates.push(cursorDate.clone())
        cursorDate = cursorDate.add(1, 'day')
    } while (true);
};

interface CalendarItemModel {
    date: any
    week: string
    color: string
    active: boolean
    now: boolean
    dateString: string
}

function showTime(value:string) {
    if (value && value !== '00:00:00') {
        return value.substring(0,5)
    }

    return '';
}

const RangeCalendar: React.FC<Props> = (props) => {

    const workMap:any = {}
    props.workList.forEach((row:any) => workMap[row.work_date] = row)

    const generateDayTable = () => {
        let result: CalendarItemModel[] = []

        const CalendarDay = getRangeDate(props.begin, props.end) || [];
        const strWeek = ["일", "월", "화", "수", "목", "금", "토"];

        CalendarDay.forEach((date:any) => {
            const week = strWeek[date.day()]

            let color = "none"
            if(week === "토"){ color = "blue"}
            if(week === "일"){ color = "red"}

            result.push({
                date: date,
                week: week,
                color: color,
                active: false,
                now: date.valueOf() === dayjs().valueOf(),
                dateString: date.format('YYYY-MM-DD')
            })
        })

        return result
    }

    let timeTable = generateDayTable()

    return (
        <CalendarWrap>
            <CalenderTable>
                <tbody>
                <tr>
                    <th rowSpan={2}>날짜</th>
                    {timeTable.map((info,key) => (
                        <th
                            key={key}
                            // onClick={()=>dateChange && dateChange(now.clone().set('date', info.day))}
                            className={`
                                ${info.color}
                                ${info.now && 'now'}
                            `}>{info.date.format('M/D')}
                        </th>)
                    )}
                </tr>
                <tr>
                    {timeTable.map((info,key) => <th key={key} className={info.color}>{info.week}</th>)}
                </tr>
                <tr>
                    <th>근태</th>
                    {timeTable.map((info,key) => (
                        <td className={workMap[info.dateString]?.work_type == '출근' ? 'active' : ""}>{workMap[info.dateString]?.work_type}</td>
                    ))}
                </tr>
                <tr>
                    <th>연장</th>
                    {timeTable.map((info,key) => (
                        <td>{showTime(workMap[info.dateString]?.work_extended)}</td>
                    ))}
                </tr>
                <tr>
                    <th>야간</th>
                    {timeTable.map((info,key) => (
                        <td>{showTime(workMap[info.dateString]?.work_nightly)}</td>
                    ))}
                </tr>
                </tbody>
            </CalenderTable>
        </CalendarWrap>
    )

}

export default RangeCalendar