
import React, {useEffect} from 'react';
import GlobalStyle, {theme} from './styles/GlobalStyle';
import {ThemeProvider} from "styled-components";
import {RouterProvider, useHref, useLocation, useRoutes} from "react-router-dom";
import { router } from "./router";
import {ConfigProvider, message} from "antd";
import 'dayjs/locale/ko'
import dayjs from "dayjs";
import locale from 'antd/locale/ko_KR'
import {localStorageKeys} from "./Constant/LocalStorageKeys";
import {getLocalStorage} from "./Helper/Helper";

dayjs.locale("ko")

function App() {
    // const locale = ko_KR
    useEffect(()=>{
        message.config({
            maxCount:1
        })
    },[])

    return (
        <ThemeProvider theme={theme} >
            <GlobalStyle/>
            <ConfigProvider theme={{
                token: {
                    colorPrimary: "#28D583"
                }
            }} locale={locale}>
                <RouterProvider router={router}/>
            </ConfigProvider>
        </ThemeProvider>
    );
}

export default App;
