import React, {useState} from "react";
import {Button, Form, Input, Modal, Row, Select, Table} from "antd";
import {SearchRow, SearchWrap} from "../../Components";
import {REPO} from "../../Repository/repo";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {useStore} from "../../Hooks";

const EmployeeSearch: React.FC<{active: boolean, close: ()=>void, onSelect: (employee: EmployeesListModel)=>void}> = ({active, close, onSelect}) => {
    const [data, setData] = useState<EmployeesListModel[]>([]);
    const { UIStore } = useStore()
    const [form] = Form.useForm()

    const fetchMethod = async (values: any) => {
        try{
            const result = await REPO.Employee.GetEmployeeList(UIStore.getTarget(),{
                offset: 0,
                limit: 100,
                search_query: values.search_query,
                search_type: values.search_type,
            })
            setData(result.data.list.map(info => ({...info, key: info.seq})))
        } catch (e) {

        }
    }
    const columns = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
        },
        {
            title: '이름',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: '소속지점',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: '연락처',
            dataIndex: 'employee_phone',
            key: 'employee_phone',
        },
        {
            title: '선택',
            dataIndex: 'seq',
            key: 'seq',
            render: (t:any,r:EmployeesListModel) => <Button onClick={()=>onSelect(r)}>선택</Button>
        },
    ];

    return (
        <Modal
            open={active}
            onCancel={close}
            title={"직원선택"}
            width={"700px"}
            footer={[

            ]}
        >
            <SearchWrap form={form} onFinish={fetchMethod}>
                <SearchRow>
                    <SearchWrap.Item label={"검색유형"} name={"search_type"} initialValue={"employee_name"}>
                        <Select>
                            <Select.Option value={"employee_name"}>이름</Select.Option>
                            <Select.Option value={"employee_position"}>직책</Select.Option>
                            <Select.Option value={"branch_name"}>소속지점</Select.Option>
                        </Select>
                    </SearchWrap.Item>
                    <SearchWrap.Item label={"검색어"} name={"search_query"}>
                        <Input/>
                    </SearchWrap.Item>
                    <Button type={"primary"} htmlType={"submit"}>검색</Button>
                </SearchRow>
            </SearchWrap>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </Modal>
    )
}

export default EmployeeSearch