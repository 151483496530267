import React, {useCallback, useEffect, useRef, useState} from 'react'
import {ModalPage} from "../../Model/UIModel";
import {useLocation, useMatches, useNavigate} from "react-router-dom";
import {useQuery} from "../../Hooks/useQuery";
import {useStore} from "../../Hooks";
import {ApprovalCreate, GroupDetail} from "../../Containers";
import BranchDetail from "../../Containers/Branch/BranchDetail";
import EmployeeDetail from "../../Containers/Employees/EmployeeDetail";
import DocumentDetail from "../../Containers/Approval/DocumentDetail";
import MonthlyDetail from "../../Containers/Monthly/MonthlyDetail";
import BoardDetail from "../../Containers/Board/BoardDetail";
import BoardCreate from "../../Containers/Board/BoardCreate";
import {Button} from "antd";

interface Props {

}
//@todo 윈도우 open으로 열릴때 실제 페이지에 사용한 container을 사용하고 있어서 페이지에 있던 요청을 똑같이 한번식 더하고 있는데 개선이 필요한지
const PrintPage: React.FC<Props> = () => {

    const [mdPg, setMdPg] = useState<ModalPage>({
        active: false,
        content: null,
        id: null,
    });
    const match = useMatches() as Matches[]
    const query = useQuery()
    const { UIStore } = useStore()
    const wrapRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        let timeout = setTimeout(()=> {
            window.print()
        }, 500)

        return ()=>clearTimeout(timeout)
    }, [])

    useEffect(()=>{
        const mdPgStirng = query.get("mdPg")
        if(mdPgStirng){
            const mdPg = JSON.parse(mdPgStirng) as ModalPage
            if(query.get("mdPg")){
                setMdPg({
                    ...mdPg
                })
            }
        } else {
            setMdPg({
                active: false,
                content: null,
                id: null
            })
        }
    }, [match])

    let title, description

    switch (mdPg.content){
        // case 'group':
        //     if(mdPg.id){
        //         title = "그룹상세"
        //         description = <GroupDetail id={mdPg.id}/>
        //     }
        //     break;
        //
        // case 'branch':
        //     title = mdPg.id ? "지점상세" : "지점등록"
        //     description = <BranchDetail id={mdPg.id}/>
        //     break;
        //
        // case 'employee':
        //     title = mdPg.id ? "직원상세" : "직원등록"
        //     description = <EmployeeDetail id={mdPg.id}/>
        //     break;

        case 'document':
            title = "전자결재 상세"
            description = <DocumentDetail id={mdPg.id} wrapRef={wrapRef}/>
            break;

        // case 'documentUpdate':
        //     title = "전자결재 수정"
        //     description = <ApprovalCreate id={mdPg.id}/>
        //     break;
        //
        // // case 'monthly':
        // //     title = "월별결산 상세"
        // //     description = <MonthlyDetail id={mdPg.id} date={mdPg.subdata}/>
        // //     break;
        // //
        // // case 'board':
        // //     title = "게시글상세"
        // //     description = <BoardDetail id={mdPg.id} boardType={mdPg.subdata || ""}/>
        // //     break;
        // //
        // // case 'boardUpdate':
        // //     title = mdPg.id ? "게시글 수정" : "게시글 등록"
        // //     description = <BoardCreate id={mdPg.id} boardType={mdPg.subdata || ""}/>
        // //     break;
    }

    return (
        <div className={"print_container"}>
            {description}
            <div style={{textAlign: "center"}}>
                <Button onClick={window.close} style={{marginRight: "10px"}} className={"print_button"}>닫기</Button>
                <Button onClick={window.print} className={"print_button"} type={"primary"}>인쇄</Button>
            </div>
        </div>
    )
}

export default PrintPage