import React, {useEffect, useState} from "react";
import {Button, Calendar, DatePicker, Descriptions, Form, Input, message, Select} from "antd";
import {CustomCalendar, Uploader} from "../../Components";

import styled from "styled-components";
import {
    OperateInfoTable,
    OperateCreateSalesTable,
    OperatePurchaseTable,
    OperateInventoryTable, OperateIssueTable, OperateMonthTable
} from "./OperateCreateTables";
import {AttendanceDefaultTable} from "./AttendanceTables";
import {onConfirm, onSubmit} from "../../Helper/Helper";
import {REPO} from "../../Repository/repo";
import {
    DefaultManagementModel,
    ManagementModel,
    RequestDefaultManagementModel,
    RequestManagementModel
} from "../../Model/OperateModel";
import {AxiosError} from "axios";
import {DefaultApiResponse} from "../../Model/Common";
import {useStore} from "../../Hooks";

const { Item } = Descriptions

const CostWrap = styled.div`
    .ant-form-item{
      margin-bottom: 10px;
      label{
        width: 40px;
      }
      input{
        width: 200px;
      }
    }
`

const BottomWrap = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 0;
  margin: auto 0 0 0;
  
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  div:first-child{
    width: 100%;
    margin-right: 20px;
  }
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  button{
    margin-right: 10px;
    :last-child{
      margin-right: 0;
    }
  }
`

const Title = styled.h3`
`




const DefaultCreate: React.FC<{}> = () => {
    const [form] = Form.useForm()
    const [originData, setOriginData] = useState<DefaultManagementModel>();
    const { UIStore } = useStore()
    const [data, setData] = useState<DefaultManagementModel>({
        sale_list: [],
        purchase_list: [],
        monthly_list: [],
        employee_list: [],
        parttime_list: [],
    })

    useEffect(()=>{
        fetchMethod()
    },[])

    const fetchMethod = async () => {
        if (!UIStore.branch?.seq) {
            return
        }

        try {
            const result = await REPO.Operate.GetDefault(UIStore.getTarget(), UIStore.branch.seq)
            let reqData = {
                sale_list: result.data.default_management?.sale_list || [],
                purchase_list: result.data.default_management?.purchase_list || [],
                monthly_list: result.data.default_management?.monthly_list || [],
                employee_list: result.data.default_work?.employee_list || result.data?.employee_list || [],
                parttime_list: result.data.default_work?.parttime_list || [],
            }

            setOriginData(reqData)
            setData(reqData)
        } catch (e) {

        }
    }

    const submitAction = () => {
        let error = false
        let error_message = ""

        const sale_list = data.sale_list.map((info, key) => {
            if(error){ return info }
            if(!info.item_name){
                error = true
                error_message = "매출내역 항목을 입력해주세요"
                info.error = 0
                // areaRef1.current?.scrollIntoView()
            } else {
                info.error = false
            }
            return info
        })

        const purchase_list = data.purchase_list.map((info, key) => {
            if(error){ return info }


            if(!info.item_name){
                error = true
                error_message = "매입내역 항목을 입력해주세요"
                info.error = 0
            } else if (info.item_price !== 0 && !info.item_price) {
                error = true
                error_message = "매입내역 금액을 확인해주세요"
                info.error = 1
            } else {
                info.error = false
            }
            return info
        })

        const monthly_list = data.monthly_list.map((info, key) => {
            if(error){ return info }
            if(!info.item_name){
                error = true
                error_message = "월간 매입/매출 항목을 입력해주세요"
                info.error = 0
            } else if (info.item_price !== 0 && !info.item_price){
                error = true
                error_message = "월간 매입/매출 금액을 입력해주세요"
                info.error = 1
            } else {
                delete info.error
            }
            return info
        })

        const params: RequestDefaultManagementModel = {
            default_management: {
                sale_list: sale_list || [],
                monthly_list: monthly_list || [],
                purchase_list: purchase_list || [],
                inventory_list: [],
                report_list: []
            },
            default_work: {
                employee_list: data.employee_list || [],
                parttime_list: data.parttime_list || []
            }
        }

        setData({
            ...data,
            ...params.default_management
        })

        if (error) {
            return message.error(error_message)
        }

        onSubmit("확인", "기본값을 등록 하시겠습니까?", async ()=> {
            try {
                const result = await REPO.Operate.PostDefault(UIStore.getTarget(),  params, UIStore.branch?.seq || 0)
                message.success("요청이 성공적으로 완료되었습니다.")
            } catch (e) {

            }
        })
    }
    const onInputChange = (target: keyof DefaultManagementModel, targetValue: string, value: any, key: number) => {
        const newValue = [...data[target]].map((info, k) => {
            if(k === key){
                info[targetValue] = value
                info.error = false
                return info
            }
            return info
        })
        setData({
            ...data,
            [target]: newValue
        })
    }

    const saleListAuto = () => {
        const newValue = [...data["sale_list"]].map((info) => {
            info.item_price = (info.item_count || 0) * (info.item_unitcost || 0)
            info.item_vat = ((info.item_count || 0) * (info.item_unitcost || 0) * 0.1)
            return info
        })
        setData({
            ...data,
            sale_list: newValue
        })
    }

    const addItem = (target: keyof DefaultManagementModel, initValue?:any) => {
        const newValue = [...data[target]].concat(initValue ? initValue : {})
        setData({
            ...data,
            [target]: newValue
        })
    }

    const removeItem = (target: keyof DefaultManagementModel, key: number) => {
        const newValue = [...data[target]].filter((info, k) => k !== key)
        setData({
            ...data,
            [target]: newValue
        })
    }

    const onDelete = () => {
        onConfirm("확인", `기본값을 삭제하시겠습니까?`, async ()=> {
            try {
                const result = await REPO.Operate.DeleteDefault(UIStore.getTarget(), UIStore.branch?.seq || 0)
                message.info("삭제되었습니다.")
                fetchMethod()
            } catch (e) {

            }

        })
    }

    const onCancel = () => {
        onConfirm("확인", "변경하신 내용을 취소하시겠습니까?", ()=> {
            setData({
                sale_list: originData?.sale_list || [],
                monthly_list: originData?.monthly_list || [],
                purchase_list: originData?.purchase_list || [],
                employee_list: originData?.employee_list || [],
                parttime_list: originData?.parttime_list || [],
            })
            message.info("취소되었습니다.")
        })
    }

    return(
        <div>
            <Form form={form} onFinish={submitAction}>

                <TitleWrap>
                    <Title>1.매출내역</Title>
                    <div>
                        <Button onClick={saleListAuto}>식수 매출 자동입력</Button>
                        <Button onClick={()=>addItem("sale_list")}>추가</Button>
                    </div>
                </TitleWrap>
                <OperateCreateSalesTable onChange={onInputChange} data={data.sale_list} removeItem={removeItem}/>
                <TitleWrap>
                    <Title>2.매입내역</Title>
                    <Button onClick={()=>addItem("purchase_list")}>추가</Button>
                </TitleWrap>
                <OperatePurchaseTable onChange={onInputChange} data={data.purchase_list} removeItem={removeItem}/>
                <TitleWrap>
                    <Title>3.월간 매입/매출</Title>
                    <div>
                        <Button onClick={()=>addItem("monthly_list", {item_type: "매출"})}>매출 추가</Button>
                        <Button onClick={()=>addItem("monthly_list", {item_type: "매입"})}>매입 추가</Button>
                    </div>
                </TitleWrap>
                <OperateMonthTable onChange={onInputChange} data={data.monthly_list} removeItem={removeItem}/>
                <div style={{display: "none"}}>
                <TitleWrap>
                    <Title>4.직원 근무</Title>
                    <div>
                        {/*<Button>직원 등록</Button>*/}
                    </div>
                </TitleWrap>
                <AttendanceDefaultTable data={data.employee_list} onChange={onInputChange} removeItem={removeItem}/>
                </div>
                <BottomWrap>
                    <div>
                        <Button type={"primary"} onClick={onDelete} danger>기본값제거</Button>
                    </div>
                    <div>
                        <Button type={"primary"} style={{marginRight: "5px"}} htmlType={"submit"}>등록</Button>
                        <Button>취소</Button>
                    </div>
                </BottomWrap>
            </Form>

        </div>
    )
}

export default DefaultCreate