import React, {useEffect, useRef, useState} from 'react'
import {Link} from "react-router-dom";
import {mdPg, toLocale} from "../../Helper/Helper";
import dayjs from "dayjs";
import {ColumnsType} from "antd/es/table";
import {Button, Form, Input, InputRef, Table} from "antd";
import LocaleInput from "../../Components/LocaleInput/LocaleInput";
import {ManagementModel, RequestManagementModel} from "../../Model/OperateModel";
import StateInput from "../../Components/StateInput/StateInput";

interface Props {
    onChange: (target: any, targetValue: string, value: any, key: number) => void,
    data: any[]
    removeItem: (target: any, key: number) => void
    noModify?: boolean
}



const OperateCreateSalesTable: React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    useEffect(()=>{

    },[props.data])

    let columns: ListModel = [
        {
            title: '항목*',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "center",
            render: (t, r, i) => (
                <StateInput state={props.data[i]["error"] === 0} value={props.data[i]["item_name"]} onChange={(e)=>props?.onChange("sale_list", "item_name", e.target.value, i)}/>
            )
        },
        {
            title: '식수',
            dataIndex: 'item_count',
            key: 'item_count',
            align: "center",
            render: (t, r, i) => (
                <LocaleInput value={props.data[i]["item_count"]} onChange={(value)=>props?.onChange("sale_list", "item_count", value, i)}/>
            )
        },
        {
            title: '기준단가',
            dataIndex: 'item_unitcost',
            key: 'item_unitcost',
            align: "center",
            render: (t, r, i) => (
                <LocaleInput value={props.data[i]["item_unitcost"]} onChange={(value)=>props?.onChange("sale_list", "item_unitcost", value, i)}/>
            )
        },
        {
            title: '매출*',
            dataIndex: 'item_price',
            key: 'item_price',
            align: "center",
            render: (t, r, i) => (
                <LocaleInput state={props.data[i]["error"] === 3} value={props.data[i]["item_price"]} onChange={(value)=>props?.onChange("sale_list", "item_price", value, i)}/>
            )
        },
        {
            title: 'VAT*',
            dataIndex: 'item_vat',
            key: 'item_vat',
            align: "center",
            render: (t, r, i) => (
                <LocaleInput state={props.data[i]["error"] === 4} value={props.data[i]["item_vat"]} onChange={(value)=>props?.onChange("sale_list", "item_vat", value, i)}/>
            )
        },
        {
            title: '합계',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (t,r, i) => {
                const target = props.data[i]
                let totla = Number(target?.item_price || 0) + Number(target?.item_vat || 0)
                return <LocaleInput value={totla} disabled/>
                // JSON.stringify(r)
            }
        },
        {
            key: '',
            align: "center",
            render: (t,r, i) => {
                return <Button danger size={"small"} onClick={()=>props.removeItem('sale_list', i)}>삭제</Button>
            }
        }
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}

const OperatePurchaseTable: React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '항목*',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "center",
            render: (t, r, i) => ({
                children: <StateInput state={props.data[i]["error"] === 0} value={props.data[i]["item_name"]} onChange={(e) => props?.onChange("purchase_list", "item_name", e.target.value, i)}/>
            })
        },
        {
            title: '금액*',
            dataIndex: 'item_price',
            key: 'item_price',
            align: "center",
            render: (t, r, i) => ({
                children: <LocaleInput state={props.data[i]["error"] === 1} value={props.data[i]["item_price"]} onChange={(e)=>props?.onChange("purchase_list", "item_price", e, i)}/>
            })
        },
        {
            title: 'VAT*',
            dataIndex: 'item_vat',
            key: 'item_vat',
            align: "center",
            render: (t, r, i) => ({
                children: <LocaleInput state={props.data[i]["error"] === 2} value={props.data[i]["item_vat"]} onChange={(e)=>props?.onChange("purchase_list", "item_vat", e, i)}/>
            })
        },
        {
            title: '합계',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (t, r, i) => {
                const target = props.data[i]
                let totla = Number(target?.item_price || 0) + Number(target?.item_vat || 0)

                return {
                    children: <LocaleInput value={totla} disabled/>
                }
            }
        },
        {
            key: '',
            align: "center",
            render: (t, r, i) => ({
                children: <Button danger size={"small"} onClick={()=>props.removeItem('purchase_list', i)}>삭제</Button>
            })
        }
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}

const OperateInfoTable:React.FC<{name: string, data?: { sale?: number, stuff?: number, count?: number}}> = ({name, data}) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '지점',
            dataIndex: 'branch_name',
            key: 'branch_name',
            align: "center",
            render: () => name
        },
        {
            title: '매출',
            dataIndex: 'sale',
            key: 'sale',
            align: "center",
            render: t => toLocale(t)
        },
        {
            title: '매입',
            dataIndex: 'purchase',
            key: 'purchase',
            align: "center",
            render: t => toLocale(t)
        },
        {
            title: '재료비',
            dataIndex: 'food_cost',
            key: 'food_cost',
            align: "center",
            render: t => toLocale(t)
        },
        {
            title: '총 식수',
            dataIndex: 'count',
            key: 'count',
            align: "center",
            render: t => toLocale(t)
        },
    ];
    return(
        <>
            <Table
                columns={columns}
                dataSource={[{...data}]}
                bordered
                pagination={false}
            />
        </>
    )
}

const OperateInventoryTable:React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '구분',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "left",
            // render: (t) => (
            //     <Input/>
            // )
        },
        {
            title: '금액*',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            render: (t, r, i) => (
                <LocaleInput state={props.data[i]["error"] === 0} value={props.data[i]["item_price"]} onChange={(e)=>props?.onChange("inventory_list", "item_price", e, i)}/>
                // i === 0 ? t :
                // <LocaleInput value={props.data[i]["item_price"]} onChange={(e)=>props?.onChange("inventory_list", "item_price", e, i)}/>
            )
        },
    ];

    return(
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}

const OperateIssueTable:React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '항목',
            dataIndex: 'item_name',
            key: 'item_name',
            align: "center",
            width: "20%",
        },
        {
            title: '내용',
            dataIndex: 'item_text',
            key: 'item_text',
            align: "center",
            render: (t, r, i) => (
                <Input value={props.data[i]["item_text"]} onChange={(e)=>props?.onChange("report_list", "item_text", e.target.value, i)}/>
            )
        },
    ];

    return(
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}

const OperateMonthTable:React.FC<Props> = (props) => {
    type ListModel = ColumnsType<any>

    let columns: ListModel = [
        {
            title: '구분',
            dataIndex: 'item_type',
            key: 'item_type',
            align: "center",
        },
        {
            title: '항목*',
            dataIndex: 'management_day',
            key: 'management_day',
            align: "center",
            render: (t, r, i) => (
                props.noModify ? props.data[i]["item_name"] : <StateInput state={props.data[i]["error"] === 0} value={props.data[i]["item_name"]} onChange={(e)=>props?.onChange("monthly_list", "item_name", e.target.value, i)}/>
            )
        },
        {
            title: '금액*',
            dataIndex: 'item_price',
            key: 'item_price',
            align: "center",
            render: (t, r, i) => (
                props.noModify ? toLocale(props.data[i]["item_price"]) : <LocaleInput state={props.data[i]["error"] === 1} value={props.data[i]["item_price"]} onChange={(e)=>props?.onChange("monthly_list", "item_price", e, i)}/>
            )
        },
        {
            title: 'VAT*',
            dataIndex: 'item_vat',
            key: 'item_vat',
            align: "center",
            render: (t, r, i) => (
                props.noModify ? toLocale(props.data[i]["item_vat"]) : <LocaleInput state={props.data[i]["error"] === 2} value={props.data[i]["item_vat"]} onChange={(e)=>props?.onChange("monthly_list", "item_vat", e, i)}/>
            )
        },
        {
            title: '합계',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (t,r, i) => {
                const target = props.data[i]
                let totla = Number(target?.item_price || 0) + Number(target?.item_vat || 0)
                return props.noModify ? toLocale(totla) : <LocaleInput value={totla} disabled/>
            }
        },
    ];

    if(!props.noModify){
        columns.push({
            key: '',
            align: "center",
            render: (t,r, i) => {
                return <Button danger size={"small"} onClick={()=>props.removeItem('monthly_list', i)}>삭제</Button>
            }
        })
    }

    return(
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                bordered
                pagination={false}
            />
        </>
    )
}



export {
    OperatePurchaseTable,
    OperateCreateSalesTable,
    OperateInfoTable,
    OperateInventoryTable,
    OperateIssueTable,
    OperateMonthTable
}