import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import {Button, Descriptions, Input, message} from "antd";
import {REPO} from "../../Repository/repo";
import {EnvModel} from "../../Model/Common";
import {onConfirm} from "../../Helper/Helper";

const EnviromentPage = () => {
    const [data, setData] = useState<EnvModel[]>([])

    useEffect(()=>{
        fetchMethod()
    },[])

    const fetchMethod = async () => {
        try {
            const result = await REPO.System.Env()
            setData(result.data.env)
        } catch (e) {

        }
    }

    const onChangeHandler = (name:string, value:string) => {
        setData(data.map((info, k) => {
            if(info.env_name === name){
                info.env_value = value
            }

            return info
        }))
    }

    const submitHandler = () => {
        onConfirm("확인", "환경설정을 저장하시겠습니까?", async ()=>{
            try {
                const result = await REPO.System.EnvSave(data)
                message.success("저장되었습니다.")
            } catch (e) {

            }
        })
    }

    return (
        <PageWrap>
            <h3>환경설정</h3>
            <Descriptions column={1} bordered>
                {data.map((info,key) => (
                    <Descriptions.Item label={info.env_name} key={key}>
                        <Input onChange={(e)=>onChangeHandler(info.env_name, e.target.value)} value={info.env_value}/>
                    </Descriptions.Item>
                ))}
            </Descriptions>
            <div style={{textAlign: "right"}}>
                <Button type={"primary"} onClick={submitHandler}>저장</Button>
            </div>
        </PageWrap>
    )
}

export default EnviromentPage