import React, {ReactNode, useEffect} from 'react'
import styled from "styled-components";
import {Button, DatePicker, Form, Input, Select, Table} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {GroupListModel} from "../../Model/BranchModel";
import {ActiveBadge, BranchInput, ListHeader, SearchRow, SearchWrap} from "../../Components";
import {mdPg, onConfirm, queryAdd, toLocale} from "../../Helper/Helper";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {useDepartment, useStore} from "../../Hooks";
import {ListProps} from "../../Model/Common";
import BranchSelect from "../../Components/BranchSelect/BranchSelect";
import {useQuery} from "../../Hooks/useQuery";
import dayjs from "dayjs";
import {LIST_CHANGE_ITEM} from "../../Constant/Common";

const ListWrap = styled.div`
  box-sizing: border-box;
`

type ListModel = ColumnsType<EmployeesListModel>

interface Props extends ListProps {
    data?: any[]
    boardType?: string
}
const BoardList: React.FC<Props> = ({ data, boardType, limit, setLimit, total, onChange, }) => {
    const location = useLocation()
    const {UIStore} = useStore()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const query = useQuery()

    useEffect(()=>{
        form.resetFields()
        const data: any = {}
        query.forEach((q, k) => data[k] = String(q))
        data.search_type = data.search_type ? data.search_type : "board_subject"
        data.date_begin = data.date_begin ? dayjs(data.date_begin) : null
        data.date_end = data.date_end ? dayjs((data.date_end)) : null
        form.setFieldsValue(data)
    },[location])

    // useEffect(()=>{
    //     form.resetFields()
    //
    // },[location.pathname])

    const onSearch = (values: any) => {
        const params = values
        params.date_begin = params.date_begin ? params.date_begin.format("YYYY-MM-DD") : null
        params.date_end = params.date_end ? params.date_end.format("YYYY-MM-DD") : null
        params.offset = 0
        for (const [key, value] of Object.entries(params)) {
            query.set(key, value as string || "")
        }
        const newQuery = queryAdd(query)
        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }

    let columns = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            render: (t: string) => (<Link to={{
                pathname: location.pathname,
                search: location.search + mdPg({
                    active: true,
                    content: "board",
                    id: t || "1",
                    subdata: boardType
                }),
            }}>{t || 1}</Link>)
        },
        {
            title: '제목',
            dataIndex: 'board_subject',
            key: 'board_subject',
            render: (t: string, r: any) => {
                return (
                    <>{t}{!r.board_hidden && <ActiveBadge isActive={!r.board_hidden}/>}</>
                )
            }
        },
        {
            title: '작성자',
            dataIndex: 'board_writer_name',
            key: 'board_writer_name',
        },
        {
            title: '등록일',
            dataIndex: 'board_write_date',
            key: 'board_write_date',
        },
        {
            title: '조회수',
            dataIndex: 'board_count_view',
            key: 'board_count_view',
        },
    ];

    return (
        <ListWrap>
            <SearchWrap form={form} onFinish={onSearch}>
                <SearchRow>
                    <Form.Item name={"search_type"} >
                        <Select defaultValue={"board_subject"}>
                            <Select.Option value={"board_subject"}>제목</Select.Option>
                            <Select.Option value={"board_text"}>내용</Select.Option>
                            <Select.Option value={"board_writer_name"}>작성자</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={"search_query"}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"date_begin"} label={"등록일"}>
                        <DatePicker format={"YYYY-MM-DD"}/>
                    </Form.Item>
                    <label>~</label>
                    <Form.Item name={"date_end"}>
                        <DatePicker format={"YYYY-MM-DD"}/>
                    </Form.Item>
                    <Button htmlType={"submit"} type={"primary"}>검색</Button>
                </SearchRow>
            </SearchWrap>
            <ListHeader>
                <div></div>
                <div>
                    <Button type={"primary"} onClick={()=>UIStore.onMdPg({
                        active: true,
                        content: "boardUpdate",
                        subdata: boardType,
                        id: null
                    })}>등록</Button>
                </div>
            </ListHeader>
            <Table
                dataSource={data}
                columns={columns}
                pagination={{
                    pageSize: limit,
                    pageSizeOptions: LIST_CHANGE_ITEM,
                    showSizeChanger: true,
                    onShowSizeChange: (e) => setLimit && setLimit(e),
                    showTotal: (total) => `검색결과 ${toLocale(total)}건`,
                    total: total,
                }}
                onChange={onChange}
                onRow={(r) => {
                    return{
                        onClick: () => {
                            UIStore.onMdPg({
                                active: true,
                                content: "board",
                                id: String(r.seq),
                                subdata: boardType
                            })
                        }
                    }
                }}
            />
        </ListWrap>
    )
}

export default BoardList