import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import {REPO} from "../../Repository/repo";
import {TablePaginationConfig} from "antd";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {BranchListModel} from "../../Model/BranchModel";
import {queryAdd} from "../../Helper/Helper";
import {DEFAULT_LIST_COUNT} from "../../Constant/Common";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../Hooks/useQuery";
import {useStore} from "../../Hooks";
import {DocumentListModel} from "../../Model/DocumentModel";
import OperateList from "../../Containers/Operate/OperateList";

const OperateListPage = () => {
    const location = useLocation()

    useEffect(()=>{
        // fetchMethod()
    },[location])
    //
    // const fetchMethod = async () => {
    //     try{
    //         const result = await REPO.Document.GetDocumentList(UIStore.getTarget(),{
    //             offset: Number(query.get("offset")),
    //             limit: Number(query.get("limit") || limit),
    //             date_begin: query.get("date_begin") || "",
    //             date_end: query.get("date_end") || "",
    //             // target: query.get("target") || UIStore.branch
    //         })
    //         setData(result.data.list.map(info => ({...info, key: info.seq})))
    //         setTotal(result.data.total)
    //         setLimit(result.data.limit)
    //         setOffset(result.data.offset)
    //     } catch (e) {
    //
    //     }
    // }
    //
    // const pageChangeHandler = (
    //     pagination: TablePaginationConfig,
    //     filters: Record<string, FilterValue | null>,
    //     sorter: SorterResult<BranchListModel>) => {
    //     if(pagination.current && pagination.pageSize){
    //         query.set("offset", String((pagination.current-1) * pagination?.pageSize))
    //         query.set("limit", String(pagination.pageSize))
    //         const newQuery = queryAdd(query)
    //         navigate({
    //             pathname: location.pathname,
    //             search: newQuery
    //         })
    //     }
    // };

    return (
        <PageWrap>
            <h3>운영현황</h3>
            <OperateList/>
        </PageWrap>
    )
}

export default OperateListPage